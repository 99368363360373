import IncidentEventInfoModel from "../models/IncidentEventInfoModel";
import {IncidentsOnControlModel} from "../models/incidentsOnControlModel";
import {mediaType} from "../types";

export const wordDeclension = (number: number, txt: string[], cases = [2, 0, 1, 1, 1, 2]) => (
    txt[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]]
);

export const getMediaType = (incident: IncidentEventInfoModel|IncidentsOnControlModel|null): mediaType =>
    incident?.fixation === null ? "incident" : "fixation";

export const getLocalStorageValue = (value: string) => localStorage.getItem(value);

export const setLocalStorageValue = (key: string, value: string) => localStorage.setItem(key, value);

export const isPrimitive = <T>(data: T) => {
    return data !== Object(data);
};