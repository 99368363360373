// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tooltip_tooltip__D3VCJ {\n  --rs-tooltip-bg: var(--plate-nested-background-color);\n}\n.Tooltip_tooltip--light__-LzS0 {\n  color: var(--text-color) !important;\n  background-color: var(--bg-quinary) !important;\n  border: 1px solid var(--border-primary) !important;\n  font-size: 14px !important;\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2) !important;\n  line-height: 1.2 !important;\n}\n.Tooltip_tooltip--light__-LzS0::after {\n  border-color: var(--border-primary);\n  background-color: var(--bg-quinary);\n}", "",{"version":3,"sources":["webpack://./src/components/UI/Tooltip/Tooltip.module.scss"],"names":[],"mappings":"AAAA;EACE,qDAAA;AACF;AACE;EACE,mCAAA;EACA,8CAAA;EACA,kDAAA;EACA,0BAAA;EACA,mDAAA;EACA,2BAAA;AACJ;AACI;EACE,mCAAA;EACA,mCAAA;AACN","sourcesContent":[".tooltip {\r\n  --rs-tooltip-bg: var(--plate-nested-background-color);\r\n\r\n  &--light {\r\n    color: var(--text-color) !important;\r\n    background-color: var(--bg-quinary) !important;\r\n    border: 1px solid var(--border-primary) !important;\r\n    font-size: 14px !important;\r\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2) !important;\r\n    line-height: 1.2 !important;\r\n\r\n    &::after {\r\n      border-color: var(--border-primary);\r\n      background-color: var(--bg-quinary);\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "Tooltip_tooltip__D3VCJ",
	"tooltip--light": "Tooltip_tooltip--light__-LzS0"
};
export default ___CSS_LOADER_EXPORT___;
