import React, {FC} from "react";
import cn from "classnames";

interface IInputProps {
    type: string,
    name: string,
    className?: string,
    placeholder?: string,
    required?: boolean,
    onChange?: () => void,
    onInput?: () => void,
    onKeyUp?: () => void,
    onFocus?: () => void,
    onBlur?: () => void,
    onKeyPress?: () => void,
    pattern?: string,
    disabled?: boolean,
    defaultValue?: number | string,
    checked?: boolean,
    defaultChecked?: boolean,
}

const Input = React.forwardRef<HTMLInputElement, React.PropsWithChildren<IInputProps>>((props, ref) => {
    const Component: FC<IInputProps> = (
        {
            type,
            name,
            placeholder,
            required,
            onChange,
            onInput,
            onKeyUp,
            onKeyPress,
            onFocus,
            onBlur,
            pattern,
            disabled,
            defaultValue,
            className,
            checked,
            defaultChecked,
        }) => (
        <input
            ref={ref}
            type={type}
            name={name}
            className={cn("form-control input", className)}
            placeholder={placeholder}
            checked={checked}
            required={required || false}
            onChange={onChange}
            onInput={onInput}
            onKeyUp={onKeyUp}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyPress={onKeyPress}
            pattern={pattern || undefined}
            disabled={disabled}
            defaultValue={defaultValue}
            defaultChecked={defaultChecked}
        />
    );

    return <Component {...props} />;
});

Input.displayName = "Input";

export default React.memo(Input);