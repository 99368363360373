import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";

import {RootState} from "store";

import createHttpRequest from "../utils/http";
import {ApiUrls} from "../constants/urls";
import BusStopModel from "../models/BusStopModel";
import RouteModel from "../models/RouteModel";

interface IInitialState {
  busStops: BusStopModel[],
  routes: RouteModel[],
  isBusStopsLoading: boolean,
  isRoutesLoading: boolean,
}

const initialState: IInitialState = {
    busStops: [],
    routes: [],
    isBusStopsLoading: false,
    isRoutesLoading: false,
};

export const fetchBusStops =
  createAsyncThunk<BusStopModel[]>("publicTransport/fetchBusStops", async() => {
      const response = await createHttpRequest({
          method: "GET",
          path: ApiUrls.BUS_STOPS,
          errorMessage: "messages:fetch_bus_stops_error",
      });

      return response.data;
  });

export const fetchRoutes =
  createAsyncThunk<RouteModel[]>("publicTransport/fetchRoutes", async() => {
      const response = await createHttpRequest({
          method: "GET",
          path: ApiUrls.ROUTES,
          errorMessage: "messages:fetch_routes_error",
      });

      return response.data;
  });

const publicTransport = createSlice({
    reducers: {},
    name: "publicTransport",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchBusStops.pending, (state) => {
            state.busStops = [];
            state.isBusStopsLoading = true;
        });
        builder.addCase(fetchBusStops.fulfilled, (state, {payload}) => {
            state.busStops = payload;
            state.isBusStopsLoading = false;
        });
        builder.addCase(fetchBusStops.rejected, (state) => {
            state.busStops = [];
            state.isBusStopsLoading = false;
        });

        builder.addCase(fetchRoutes.pending, (state) => {
            state.routes = [];
            state.isRoutesLoading = true;
        });
        builder.addCase(fetchRoutes.fulfilled, (state, {payload}) => {
            state.routes = payload;
            state.isRoutesLoading = false;
        });
        builder.addCase(fetchRoutes.rejected, (state) => {
            state.routes = [];
            state.isRoutesLoading = false;
        });
    },
});


export default publicTransport.reducer;

//Селекторы
const slice = ({publicTransportReducer}: RootState) => publicTransportReducer;

export const busStopsSelector = createSelector(
    slice,
    ({busStops}) => busStops
);

export const isBusStopsLoadingSelector = createSelector(
    slice,
    ({isBusStopsLoading}) => isBusStopsLoading
);

export const routesSelector = createSelector(
    slice,
    ({routes}) => routes
);

export const isRoutesLoadingSelector = createSelector(
    slice,
    ({isRoutesLoading}) => isRoutesLoading
);

