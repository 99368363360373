import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";

import {RootState} from "store";

import createHttpRequest from "utils/http";
import {ApiUrls} from "constants/urls";

import IAuthForm from "models/form/IAuthForm";
import {setTokens} from "utils/auth";
import UserListItem from "../models/UserListItem";
import {Permission} from "../models/Permission";
import {Role} from "../models/Role";

interface IInitialState {
    loginLoading: boolean,
    isAuthenticated: boolean,
    user: UserListItem | null,
    isUserLoading: boolean,
}

const initialState: IInitialState = {
    loginLoading: false,
    isAuthenticated: false,
    user: null,
    isUserLoading: false,
};

export const loginUser = createAsyncThunk<void, IAuthForm>(
    "auth/loginUser",
    async(data) => {
        const response = await createHttpRequest({
            method: "POST",
            path: ApiUrls.TOKEN,
            data: data,
            isAnonymous: true,
            errorMessage: "messages:auth_error",
        });
        setTokens(response.data);
    }
);

export const logout = createAsyncThunk(
    "auth/logout",
    async() => {
        logout();
    }
);

export const fetchMe = createAsyncThunk<UserListItem>("auth/fetchMe", async() => {
    const response = await createHttpRequest({
        method: "GET",
        path: ApiUrls.USER_SETTINGS,
        errorMessage: "messages:fetch_me_error",
    });

    return response.data;
});

const auth = createSlice({
    name: "auth",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(loginUser.pending, (state) => {
            state.loginLoading = true;
        });
        builder.addCase(loginUser.fulfilled, (state) => {
            state.loginLoading = false;
            state.isAuthenticated = true;
        });
        builder.addCase(loginUser.rejected, (state) => {
            state.loginLoading = false;
        });

        builder.addCase(fetchMe.pending, (state) => {
            state.user = null;
            state.isUserLoading = true;
        });
        builder.addCase(fetchMe.fulfilled, (state, {payload}) => {
            state.user = payload || null;
            state.isUserLoading = false;
        });
        builder.addCase(fetchMe.rejected, (state) => {
            state.isUserLoading = false;
        });

        builder.addCase(logout.fulfilled, (state) => {
            state.isAuthenticated = false;
            state.user = null;
        });
    },
});

export default auth.reducer;

//Селекторы
const slice = ({authReducer}: RootState) => authReducer;

export const authLoadingSelector = createSelector(
    slice,
    ({loginLoading}) => loginLoading
);

export const isAuthenticatedSelector = createSelector(
    slice,
    ({isAuthenticated}) => isAuthenticated
);

export const userSelector = createSelector(
    slice,
    ({user}) => user
);

export const userPermissionsSelector = createSelector(
    userSelector,
    (user) => {
        const permissions: Permission[] = [];
        if (!user) {
            return permissions;
        }

        // Админ
        if (user.Roles?.some(r => r === Role.SYSADMIN)) {
            permissions.push(Permission.ADMIN);
            return permissions;
        }

        // Служба реагирования
        if (user.Roles?.some(r => r === Role.INCIDENT_RESPONSE_SERVICE)) {
            permissions.push(Permission.SERVICE);
            return permissions;
        }

        // Старший сменны
        if (user.Roles?.some(r => r === Role.INCIDENT_MANAGER)) {
            permissions.push(Permission.MANAGER);
            return permissions;
        }

        // Оператор
        if (user.Roles?.some(r => r === Role.INCIDENT_OPERATOR)) {
            if (user.UserIncidentsModuleSettings?.FixationsEnabled) {
                permissions.push(Permission.FIXATION);
            }

            if (user.UserIncidentsModuleSettings?.EventsEnabled) {
                permissions.push(Permission.EVENT);
            }
        }

        return permissions;
    }
);

export const isUserLoadingSelector = createSelector(
    slice,
    ({isUserLoading}) => isUserLoading
);