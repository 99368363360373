import React, {useEffect, useRef, useState} from "react";
import cn from "classnames";
import {usePopper} from "react-popper";
import SimpleBar from "simplebar-react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {TypedDispatch} from "../../../types";
import {fetchEfficiencyTickets, ticketsSelector} from "../../../redux/efficiencyJournalSlice";

import HeaderTicket from "./HeaderTicket";
import ArrowTriangularDownIcon from "../../Icons/ArrowTriangularDownIcon";
import RoadWorksIcon from "../../Icons/RoadWorksIcon";

import styles from "./HeaderTickets.module.scss";

const HeaderTickets = () => {
    const dispatch = useDispatch<TypedDispatch>();
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const referenceElement = useRef<HTMLDivElement>(null);
    const popperElement = useRef<HTMLDivElement>(null);
    // eslint-disable-next-line max-len
    const {styles: popperStyles, attributes: popperAttributes} = usePopper(referenceElement.current, popperElement.current, {
        placement: "bottom-start",
        strategy: "fixed",
        modifiers: [
            {
                name: "flip",
                options: {
                    allowedAutoPlacements: ["bottom-start"], // by default, all the placements are allowed
                },
            },
        ],
    });
    const tickets = useSelector(ticketsSelector);

    const handleClickOutside = ({target}: Event) => {
        if ((referenceElement.current && !referenceElement.current.contains(target as Node)) &&
            (popperElement.current && !popperElement.current.contains(target as Node))) {
            setIsPopoverOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, []);

    useEffect(() => {
        if (isEmpty(tickets)) dispatch(fetchEfficiencyTickets());
    }, []);

    const haveTickets = !!tickets.length;

    return (
        <div className={styles["tickets"]}>
            <div
                className={styles["tickets-toggle"]}
                ref={referenceElement}
                onClick={() => setIsPopoverOpen(!isPopoverOpen)}
            >
                <div className={styles["tickets-toggle__icon"]}>
                    <RoadWorksIcon />
                </div>

                <div className={styles["tickets-toggle__count"]}>
                    {tickets?.length}
                </div>

                <ArrowTriangularDownIcon
                    className={cn(
                        styles["tickets-toggle__arrow"],
                        isPopoverOpen && styles["tickets-toggle__arrow--opened"]
                    )}
                />
            </div>

            <div
                className={cn(styles["tickets-dropdown"],
                    {[styles["tickets-dropdown--opened"]]: isPopoverOpen})}
                ref={popperElement}
                style={popperStyles.popper}
                {...popperAttributes.popper}
            >
                <div className={styles["tickets-dropdown__inner"]}>
                    <SimpleBar style={{height: haveTickets ? 300 : "auto"}}>
                        <div className={styles["tickets-dropdown__body"]}>
                            {haveTickets ?
                                tickets.map((ticket) => (
                                    <HeaderTicket key={ticket.Id} ticket={ticket}  />
                                )).reverse()
                                : "Нет уведомлений"
                            }
                        </div>
                    </SimpleBar>
                </div>
            </div>
        </div>
    );
};

export default React.memo(HeaderTickets);