import React, {FC, ReactNode} from "react";
import cn from "classnames";

interface IBaseButtonProps {
    tag?: "button" | "a",
    type?: "button" | "submit" | "reset" | undefined
    href?: string,
    className?: string,
    disabled?: boolean
    style?: object,
    onClick?: () => void,
    children: ReactNode,
}

const BaseButton: FC<IBaseButtonProps> =
    React.forwardRef<HTMLButtonElement, React.PropsWithChildren<IBaseButtonProps>>((props, ref) => {

        const {type, href, className, style, disabled, onClick, tag, children} = props;

        return (
            tag === "button" ? (
                <button
                    type={type}
                    className={cn("btn", className)}
                    style={style}
                    disabled={disabled || false}
                    onClick={onClick}
                    ref={ref}
                >
                    {children}
                </button>
            ) : (
                <a
                    href={href}
                    className={cn("btn", className)}
                    style={style}
                    onClick={onClick}
                >
                    {children}
                </a>
            )
        );
    });


BaseButton.displayName = "BaseButton";

export default React.memo(BaseButton);