import React, {FC, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import cn from "classnames";

import {TypedDispatch} from "../../../../types";

import {ITag} from "../SettingsTags";
import TagsIcon from "../../../Icons/TagsIcon";
import QuoteIcon from "../../../Icons/QuoteIcon";
import EditIcon from "../../../Icons/EditIcon";
import TrashIcon from "../../../Icons/TrashIcon";
import AddInput from "../AddInput";
import SettingsDeleteModal from "../../SettingsDeleteModal";

import styles from "./Tag.module.scss";

interface ITagProps {
    data: ITag,
    editTag: any,
    inputRef: any,
    setEditableTag: any,
    editableTag: any,
    placeholder: string,
    type?: any,
    deleteTag: any,
    resetSuccess: any,
    requestSuccess: any,
    className?: string
}

export const Tag:FC<ITagProps> = ({data, deleteTag, editableTag, editTag, setEditableTag, 
    placeholder, inputRef, type, resetSuccess, requestSuccess, className}) => {
    const dispatch = useDispatch<TypedDispatch>();

    const [hover, setHover] = useState(false);
    const [isModalShow, setIsModalShow] = useState(false);

    const handleClose = () => {
        setIsModalShow(false);
    };

    useEffect(() => {
        if (requestSuccess) {
            dispatch(resetSuccess());
        }
    }, [requestSuccess]);

    const deleteHandler = () => {
        dispatch(deleteTag(data.id));
    };

    const editHandler = () => {
        setEditableTag(data.id);
    };

    useEffect(() => {
        if (editableTag === data.id && hover) setHover(false);
    }, [editableTag]);

    return (
        editableTag === data.id
            ? <div className={styles["input"]}>
                <AddInput
                    defaultValue={data?.name}
                    type={type ?? "tag"}
                    id={data?.id}
                    groupId={data?.groupId}
                    placeholder={placeholder}
                    ref={inputRef}
                    editData={editTag}
                    hideInput={setEditableTag}
                    requestSuccess={requestSuccess}
                    resetSuccess={resetSuccess}
                    requestGroupSuccess={requestSuccess}
                />
            </div>
            : <div
                className={cn(styles["tag"], className)}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <div className={styles["tag__text"]}>
                    {type === "comment" ? <QuoteIcon/> : <TagsIcon/>}
                    <span>{data.name}</span>
                </div>
                <div className={cn(styles["icons"], styles[hover ? "icons--visible" : "icons--hidden"])}>
                    <div onClick={editHandler}>
                        <EditIcon/>
                    </div>
                    <div onClick={() => {
                        setIsModalShow(true);
                    }}>
                        <TrashIcon
                            width={16}
                            height={16}
                        />
                    </div>
                </div>
                {isModalShow && (
                    <SettingsDeleteModal
                        handleClose={handleClose}
                        title={`Удаление ${type === "comment" ? "комментария" : "тега"} из системы`}
                        text={`Вы уверены, что хотите удалить ${type === "comment" ? "комментарий" : "тег"}?`}
                        action={deleteHandler}
                    />
                )}
            </div>
    );
};