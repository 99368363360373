// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddInput_input-group__gaPcD {\n  display: flex;\n  align-items: center;\n  padding: 2px 12px;\n  border: 2px solid var(--color-primary);\n  border-radius: 6px;\n}\n.AddInput_input-group__gaPcD input {\n  width: 100%;\n  background: transparent;\n  border: none;\n}\n.AddInput_input-group__gaPcD input::placeholder {\n  color: var(--color-secondary-tint);\n  font-size: 14px;\n}\n.AddInput_input-group__gaPcD span {\n  color: var(--color-secondary-tint);\n}", "",{"version":3,"sources":["webpack://./src/components/Settings/SettingsTags/AddInput/AddInput.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,sCAAA;EACA,kBAAA;AACF;AACE;EACE,WAAA;EACA,uBAAA;EACA,YAAA;AACJ;AACI;EACE,kCAAA;EACA,eAAA;AACN;AAGE;EACE,kCAAA;AADJ","sourcesContent":[".input-group {\r\n  display: flex;\r\n  align-items: center;\r\n  padding: 2px 12px;\r\n  border: 2px solid var(--color-primary);\r\n  border-radius: 6px;\r\n\r\n  input {\r\n    width: 100%;\r\n    background: transparent;\r\n    border: none;\r\n\r\n    &::placeholder {\r\n      color: var(--color-secondary-tint);\r\n      font-size: 14px;\r\n    }\r\n  }\r\n\r\n  span {\r\n    color: var(--color-secondary-tint);\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-group": "AddInput_input-group__gaPcD"
};
export default ___CSS_LOADER_EXPORT___;
