import {
    HttpTransportType,
    HubConnection,
    HubConnectionBuilder,
    IHttpConnectionOptions,
    LogLevel,
} from "@microsoft/signalr";

import {isDev} from "../evironment";
import {getActualAccessToken} from "../auth";

export class SignalRService{
    private static instance: SignalRService;
    private isActive = false;
    private isDisconnected = true;
    private isStarting = false;
    private subscribedMethods: string[] = [];

    public connection: HubConnection|null = null;

    private constructor(
        // eslint-disable-next-line no-unused-vars
        private readonly onSuccessCallback?: () => void,
    ) {
        this.init();
    }

    public static getInstance(
        onSuccessCallback?: () => void,
    ): SignalRService{
        if (!this.instance) {
            this.instance = new SignalRService(onSuccessCallback);
        }

        return this.instance;
    }

    public get id(): string {
        return <string>this.connection?.connectionId;
    }

    private init(): void {
        const connectionHub = process.env.REACT_APP_SIGNALR_HOST + "/roStatusHub";
        const transport = HttpTransportType.WebSockets | HttpTransportType.LongPolling;

        const options: IHttpConnectionOptions = {
            transport,
            logger: isDev() ? LogLevel.Debug : LogLevel.Error,
            accessTokenFactory: () => getActualAccessToken(),
            withCredentials: true,
        };

        // create the connection instance
        this.connection = new HubConnectionBuilder()
            .withUrl(connectionHub, options)
            .build();

        this.connection.onclose(() => {
            this.isActive = false;
            this.isStarting = false;
            this.startConnect();
        });

        this.connection.serverTimeoutInMilliseconds = 10000;
        this.connection.keepAliveIntervalInMilliseconds = 5000;
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.connection.on("broadcastMessage", () => {});
    }

    public runAfterConnect(func: () => void): void {
        if (this.id) {
            func();
            this.onSuccessCallback?.();
        } else {
            setTimeout(() => {
                this.runAfterConnect(func);
            }, 1000);
        }
    }

    public startConnect(): void {
        this.isDisconnected = false;

        if (!this.isActive && !this.isStarting) {
            this.isStarting = true;
            this.connection
                ?.start()
                .then(() => {
                    // eslint-disable-next-line no-console
                    console.log("Connection Started...");
                    this.isActive = true;
                })
                .catch(err => {
                    // eslint-disable-next-line no-console
                    console.log("Error while starting connection: " + err);
                    this.isActive = false;

                    if (!this.isDisconnected) {
                        // eslint-disable-next-line no-console
                        console.log("reconnecting");
                        setTimeout(() => {
                            this.startConnect();
                        }, 3000);
                    }
                }).finally(() => {
                    this.isStarting = false;
                });
        }
    }

    // eslint-disable-next-line no-unused-vars
    public addSubscriber(methodName: string, newMethod: (...args: any[]) => void){
        if (!this.subscribedMethods.includes(methodName)) {
            this.subscribedMethods.push(methodName);
            this.connection?.on(methodName, newMethod);
        }
    }

    public send(methodName: string, ...args: any[]): void {
        this.runAfterConnect(() => {
            this.connection?.invoke(methodName, ...args).then(v => {
                // eslint-disable-next-line no-console
                console.log("------invoke------", v);
            });
        });
    }
}