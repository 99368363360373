// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SettingsComments_comment__bnr8M > div:first-child {\n  width: 100%;\n  align-items: flex-start;\n}\n.SettingsComments_comment__bnr8M > div:first-child > svg {\n  margin-top: 5px;\n}\n.SettingsComments_comment__bnr8M > div:first-child span {\n  white-space: normal;\n}", "",{"version":3,"sources":["webpack://./src/components/Settings/SettingsComments/SettingsComments.module.scss"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,uBAAA;AAAJ;AAEI;EACE,eAAA;AAAN;AAGI;EACE,mBAAA;AADN","sourcesContent":[".comment {\r\n  & > div:first-child {\r\n    width: 100%;\r\n    align-items: flex-start;\r\n\r\n    & > svg {\r\n      margin-top: 5px;\r\n    }\r\n\r\n    span {\r\n      white-space: normal;\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comment": "SettingsComments_comment__bnr8M"
};
export default ___CSS_LOADER_EXPORT___;
