import React, {FC, useEffect, useState} from "react";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {setSettingsBackUrl} from "../../../redux/userSettingsSlice";
import {TypedDispatch} from "../../../types";
import {adminResponsibilityZonesSelector, fetchAdminResponsibilityZones} from "../../../redux/responsibilitySlice";

import BaseButton from "../../UI/Button/BaseButton";
import ViewSettingsBlock from "../ViewSettingsBlock";
import {ICard} from "../SettingsViewPage/SettingsViewPage";
import ArrowRightSmallIcon from "../../Icons/ArrowRightSmallIcon";
import CrossIcon from "../../Icons/CrossIcon";
import TagsIcon from "../../Icons/TagsIcon";
import TabIcon1 from "../../Icons/Settings/TabIcon1";
import SettingsTags from "../SettingsTags";
import HeadUserIcon from "../../Icons/HeadUserIcon";
import SettingsOperators from "../SettingsOperators";
import SettingsComments from "../SettingsComments";
import CommentsIcon from "../../Icons/CommentsIcon";
import PrioritiesIcon from "../../Icons/PrioritiesIcon";
import SettingsPriorities from "../SettingsPriorities";

import styles from "./QuickSettingsTabs.module.scss";

interface IQuickSettingsTabsProps {
    // eslint-disable-next-line no-unused-vars
    setCardList: (_: ICard[]) => void,
    cardList: ICard[],
    isAdmin?: boolean,
    // eslint-disable-next-line no-unused-vars
    onClose?: (_?: number) => NodeJS.Timeout | void,
}

const QuickSettingsTabs:FC<IQuickSettingsTabsProps> = ({setCardList, cardList, isAdmin, onClose}) => {
    const dispatch = useDispatch<TypedDispatch>();
    const responsibilityZones = useSelector(adminResponsibilityZonesSelector);

    const [tabIndex, setTabIndex] = useState(0);

    const setSettingsBackUrlHandler = () => {
        dispatch(setSettingsBackUrl(window.location.pathname));
    };

    useEffect(() => {
        if (!responsibilityZones?.length) {
            dispatch(fetchAdminResponsibilityZones());
        }
    }, []);

    return (
        <div className={styles["tabs"]}>
            <div className={styles["tabs-header"]}>
                <div className={styles["tabs-header--left"]}>
                    <div className={styles["tabs-header__title"]}>
                        Быстрые настройки
                    </div>
                    <Link to={"/settings/view"}>
                        <BaseButton
                            onClick={setSettingsBackUrlHandler}
                            className={styles["tabs-header__link"]}
                            tag="button"
                        >
                            Перейти во все
                            <ArrowRightSmallIcon/>
                        </BaseButton>
                    </Link>
                </div>
                <CrossIcon
                    className={styles["close"]}
                    onClick={onClose}
                    width={20}
                    height={20}
                />
            </div>

            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                <TabList>
                    <Tab>
                        <TagsIcon />
                        Теги
                    </Tab>
                    <Tab>
                        <HeadUserIcon width={16} height={16}/>
                        Операторы
                    </Tab>
                    <Tab>
                        <TabIcon1 width={16} height={16}/>
                        Интерфейс
                    </Tab>
                    <Tab>
                        <CommentsIcon/>
                        Комментарии
                    </Tab>
                    <Tab>
                        <PrioritiesIcon />
                        Приоритеты
                    </Tab>
                </TabList>

                <TabPanel>
                    <SettingsTags/>
                </TabPanel>
                <TabPanel>
                    <SettingsOperators/>
                </TabPanel>
                <TabPanel>
                    <ViewSettingsBlock
                        previewMode={true}
                        setCardList={setCardList}
                        cardList={cardList}
                        isAdmin={isAdmin}
                    />
                </TabPanel>
                <TabPanel>
                    <SettingsComments/>
                </TabPanel>
                <TabPanel>
                    <SettingsPriorities/>
                </TabPanel>
            </Tabs>
        </div>
    );
};

export default React.memo(QuickSettingsTabs);