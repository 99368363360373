import React, {forwardRef, PropsWithChildren, useEffect} from "react";
import {useDispatch} from "react-redux";

import {TypedDispatch} from "../../../../types";

import styles from "./AddInput.module.scss";

interface IAddInputProps {
    placeholder: string,
    addData?: any,
    editData?: any,
    defaultValue?: string,
    count?: number,
    id?: number | "default",
    type: "tag" | "comment" | "group",
    groupData?: any,
    hideInput: any,
    requestSuccess: any,
    requestGroupSuccess: any,
    resetSuccess: any,
    groupId?: any,
}

const AddInput = forwardRef<HTMLInputElement, PropsWithChildren<IAddInputProps>>(({
    placeholder, addData, editData, defaultValue, count, id, type, groupData, 
    hideInput, requestSuccess, requestGroupSuccess, resetSuccess, groupId,
}, ref) => {
    const dispatch = useDispatch<TypedDispatch>();

    const handleKeyDown = (e: any) => {
        if (e.key === "Enter" && e.target.value.length > 0) {
            if (id) {
                editData(id, e.target.value, groupData ?? groupId);
            } else {
                addData(e.target.value);
            }
        }
    };

    useEffect(() => {
        if (ref) {
            // @ts-ignore
            (ref)?.current?.focus();
        }
    }, [ref]);

    useEffect(() => {
        if (requestSuccess || requestGroupSuccess) {
            hideInput(false);
            dispatch(resetSuccess());
        }
    }, [requestSuccess, requestGroupSuccess]);

    const onBlurHandler = () => hideInput(false);

    return (
        <div className={styles["input-group"]}>
            <input
                defaultValue={defaultValue}
                ref={ref}
                autoFocus={true}
                type="text"
                placeholder={placeholder}
                onKeyDown={handleKeyDown}
                onBlur={onBlurHandler}
            />
            {type === "group" && count && (
                <span>{count}</span>
            )}
        </div>
    );
});

AddInput.displayName = "AddInput";

export default AddInput;