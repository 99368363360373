/* eslint-disable max-len */
import React from "react";

import {SvgProps} from "../../models/SvgProps";

//import icon from "images/icons/plus.svg";

interface IPlusIconProps extends SvgProps {
    className?: string,
}

const PlusIcon: React.FC<IPlusIconProps> = ({className, width = 20, height = 20}) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.8337 9.16699H18.3337V10.8337H10.8337V18.3337H9.16699V10.8337H1.66699V9.16699H9.16699V1.66699H10.8337V9.16699Z" fill="currentColor"/>
        </svg>
    );
};

export default PlusIcon;