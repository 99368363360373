import {useEffect, useMemo, useRef, useState, useCallback} from "react";
import {debounce} from "lodash";
import {useDispatch, useSelector} from "react-redux";


import LastIncidentEventModel from "../models/LastIncidentEventModel";
import {ITableSort} from "../components/UI/Table/Table";
import {ScreenDetails} from "../models/ScreenDetails";
import {secondScreenSelector, setSecondScreenParams} from "../redux/commonSlice";
import {AppUrls} from "../constants/urls";
import {TypedDispatch} from "../types";

// Хук для исключения первого рендера, например, в useEffect с [deps]
export const useIsMount = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
        isMountRef.current = false;
    }, []);
    return isMountRef.current;
};

interface ISortConfig extends ITableSort {
    field: keyof LastIncidentEventModel,
}

/**
 * Кастомный хук для сортировки инцидентов на фронте
 * @param {Object[]} items - Сортируемые данные
 * @param {Object} sortConfigInit - Параметры сортировки
 */
export const useSortingData = (items: LastIncidentEventModel[] = [], sortConfigInit: ISortConfig | null = null) => {
    const [sortConfig, setSortConfig] = useState(sortConfigInit);

    const sortedItems = useMemo(() => {
        const sortedData = [...items];

        if (sortConfig !== null) {
            sortedData?.sort((a, b) => {
                // @ts-ignore
                if (typeof a[sortConfig.field] !== "object") {
                    // @ts-ignore
                    if (a[sortConfig.field] < b[sortConfig.field ?? "beginDateTime"]) {
                        return sortConfig.direction === "asc" ? -1 : 1;
                    }
                    // @ts-ignore
                    if (a[sortConfig?.field] > b[sortConfig?.field]) {
                        return sortConfig.direction === "asc" ? 1 : -1;
                    }
                } else {
                    // Например, для типа инцидента {code: "loitering", name: "Длительное нахождение в зоне контроля"}
                    // @ts-ignore
                    const firstKey = Object.keys(a[sortConfig.field])[0];
                    // @ts-ignore
                    if (a[sortConfig.field][firstKey] < b[sortConfig.field][firstKey]) {
                        return sortConfig.direction === "asc" ? -1 : 1;
                    }
                    // @ts-ignore
                    if (a[sortConfig.field][firstKey] > b[sortConfig.field][firstKey]) {
                        return sortConfig.direction === "asc" ? 1 : -1;
                    }
                }
                return 0;
            });
        }

        return sortedData;
    }, [items, sortConfig]);


    const sortRequest = (field: string) => {
        let direction = "asc";
        if (sortConfig && sortConfig.field === field && sortConfig.direction === "asc") {
            direction = "desc";
        }

        // @ts-ignore
        setSortConfig({direction, field});
    };

    return {sortedItems, sortConfig, sortRequest};
};

/**
 * Кастомный хук для отслеживания клика вне портального элемента
 * @param {string} triggerClassName - Класс кнопки триггера
 * @param {string} portalClassName - Класс портального элемента
 * @param {callback} handler - Колбэк который выполнится при клике вне портального элемента
 */
export const usePortalOutsideClick = (
    triggerClassName: string,
    portalClassName: string,
    handler: () => void
) => {
    useEffect(() => {
        const listener = ({target}: Event) => {
            const portals = Array.from(document.querySelectorAll(portalClassName));
            const triggers = Array.from(document.querySelectorAll(triggerClassName));

            const isOutside = [...portals, ...triggers]?.every(portalElem => !portalElem.contains(target as Node));

            isOutside && handler();
        };

        document.addEventListener("click", listener, true);

        return () => document.removeEventListener("click", listener, true);

    }, [triggerClassName, portalClassName, handler]);
};


// Хук для получения ширины или брейкпоинта в зависимости от ширины экрана
export const useResize = () => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = ({target}: Event) => setWidth((target as Window)?.innerWidth);

        window.addEventListener("resize", debounce(handleResize, 300));
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return {
        width,
        screenMobileExtraMini: width <= 375,
        screenMobileMini: width <= 480,
        screenMobile: width <= 767,
        screenTablet: width <= 991,
        screenTabletHorizontal: width <= 1133,
        screenDesktop: width <= 1340,
    };
};

/**
 * Хук для управления квери параметрами в зависимости от стейт
 * @param stateItems - Данные для обновления updateStateField (фильтры, пагинация)
 * @param stateItemsLength
 */
export const useSearchParamsFromState = <T extends Record<string, any> | undefined>(
    stateItems: T,
    stateItemsLength?: { [key: string]: number | undefined },
) => {
    /**
     * Добавление квери параметров в url
     * @param {string} paramName - Имя параметра
     * @param {string | null} paramValue - Значение параметра
     * @param {string} method - Метод который хотим использовать
     * @param {URLSearchParams} searchParams
     */
    const searchParamsHandler = (
        searchParams: URLSearchParams,
        paramName: string,
        paramValue: string | null,
        method = "set"
    ) => {
        if (method === "delete") {
            searchParams[method](paramName);
        } else {
            // @ts-ignore
            searchParams[method](paramName, paramValue || paramValue === "false" && paramValue);
        }
        const newUrl = `${window.location.pathname}?${decodeURIComponent(searchParams.toString())}`;

        window.history.replaceState(null, "", newUrl);
    };

    useEffect(() => {
        // Квери параметры в url
        const searchParams = new URLSearchParams(window.location.search);

        if (stateItems) {
            // Проходим по всем значениям в стейте и достаем ключ/значение
            Object.entries(stateItems).forEach(([stateItemKey, stateItemValue]) => {
                const hasStateItemValue = Array.isArray(stateItemValue) ? stateItemValue?.length : stateItemValue;

                // eslint-disable-next-line max-len
                const shouldDeleteQueryValue = !hasStateItemValue && hasStateItemValue !== false && searchParams.get(stateItemKey) ||
                    // eslint-disable-next-line max-len
                    stateItemsLength && stateItemsLength?.[stateItemKey as keyof typeof stateItemsLength] === stateItemValue?.length;

                // При наличии данных в стейте - записываем в квери
                if (hasStateItemValue || hasStateItemValue === false) {
                    searchParamsHandler(searchParams, stateItemKey, stateItemValue);
                }

                // Удаляем из квери если значение в стейте пустое или выбраны все фильтры
                if (shouldDeleteQueryValue) {
                    searchParamsHandler(searchParams, stateItemKey, null, "delete");
                }
            });
        }
    }, [stateItems]);
};

/**
 * Хук для троттлинга, чтобы сократить число выполнения функции за определенный промежуток времени,
 * @param callback - Функуия, которую нужно выполнить с задержкой
 * @param delay - Число задержки (в мс)
 */
export const useThrottle = (
    // eslint-disable-next-line no-unused-vars
    callback: (...args: any[]) => void,
    delay: number
) => {
    const throttleRef = useRef(false);

    return useCallback(
        (...args: any[]) => {
            if (!throttleRef.current) {
                callback(...args);
                throttleRef.current = true;

                setTimeout(() => {
                    throttleRef.current = false;
                }, delay);
            }
        },
        [callback, delay],
    );
};


export const useScreenDetails = () => {
    const [screenDetails, setScreenDetails] = useState<ScreenDetails>({} as ScreenDetails);

    useEffect(() => {
        (async() => {
            //@ts-ignore
            const screenDetails = await getScreenDetails();

            setScreenDetails(screenDetails);
        })();
    });

    return screenDetails;
};

export const useSecondScreen = () => {
    const {screens} = useScreenDetails();
    const isMount = useIsMount();
    const dispatch = useDispatch<TypedDispatch>();
    const {displayMode} = useSelector(secondScreenSelector);

    const isOutsideWindow = window.name === "outsideWindow";
    const [closed, setClosed] = useState(false);

    const handleCloseNewWindow = () => {
        setTimeout(() => {
            if (window.document?.visibilityState === "hidden") {
                setClosed(true);
            }
        });
    };

    const openNewWindow = () => {
        const secondScreen = screens?.find(({isPrimary}) => !isPrimary);

        if (secondScreen && !isOutsideWindow) {
            const features = `
                    width=${secondScreen.width},
                    height=${secondScreen.height},
                    left=${secondScreen.left},
                    top=${secondScreen.top}
                `;
            // Открыть карту на втором мониторе
            window.open(
                `${process.env.PUBLIC_URL}${AppUrls.DASHBOARD}?displayMode=two&fullMap=true`,
                "outsideWindow",
                features
            );
        }
    };

    const closeNewWindow = () => {
        if (isOutsideWindow && (!isMount || window.closed)) {
            window?.close();
        }
    };

    useEffect(() => {
        if (closed && isOutsideWindow) {
            dispatch(setSecondScreenParams({displayMode: "one"}));
        }
    }, [closed]);

    useEffect(() => {
        if (isOutsideWindow) {
            window.addEventListener("pagehide", handleCloseNewWindow);

            return () => {
                window.removeEventListener("pagehide", handleCloseNewWindow);
            };
        }
    }, []);

    useEffect(() => {
        const displaysConfig = {
            one: closeNewWindow,
            two: openNewWindow,
        }[displayMode];

        displaysConfig?.();

    }, [displayMode]);
};