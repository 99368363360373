/* eslint-disable max-len */
import React, {FC} from "react";

interface IEditIconProps {
    onClick?: any,
}

const EditIcon:FC<IEditIconProps> = ({onClick}) => {
    return (
        <svg onClick={onClick} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.908 5.06411L12.6511 4.32094L11.0131 2.6829L10.2699 3.42607L11.908 5.06411ZM10.9701 6.00196L9.33207 4.36392L3.20442 10.4916L2.82741 12.5066L4.84246 12.1296L10.9701 6.00196ZM13.6286 3.4227C14.1247 3.91878 14.1247 4.72309 13.6286 5.21918L5.63514 13.2126C5.54058 13.3072 5.41963 13.371 5.28818 13.3955L2.11942 13.9884C1.66121 14.0741 1.25988 13.6728 1.34561 13.2146L1.93848 10.0458C1.96307 9.91439 2.02684 9.79345 2.1214 9.69888L10.1148 1.70544C10.6109 1.20935 11.4152 1.20935 11.9113 1.70544L13.6286 3.4227ZM13.3406 14H7.99405C7.11396 14 7.11396 12.6667 7.99405 12.6667H13.3406C14.2207 12.6667 14.2207 14 13.3406 14Z" fill="currentColor"/>
        </svg>
    );
};

export default EditIcon;