import React, {FC} from "react";
import {useDispatch} from "react-redux";
import {Modal} from "rsuite";
import cn from "classnames";

import {TypedDispatch} from "../../../../types";
import {setHasChanged} from "../../../../redux/userSettingsSlice";

import BaseButton from "../../../UI/Button/BaseButton";
import AlertFillCircleIcon from "../../../Icons/Notifications/AlertFillCircleIcon";

// eslint-disable-next-line max-len
import styles from "../../../../components/ArchiveIncidentPage/IncidentInfo/IncidentInfoModals/IncidentInfoModals.module.scss";

interface ISettingsViewModalProps {
    open: boolean,
    handleClose: () => void,
}

const SettingsViewModal: FC<ISettingsViewModalProps> = ({
    open,
    handleClose,
}) => {
    const dispatch = useDispatch<TypedDispatch>();

    return (
        <Modal
            open={open}
            onClose={handleClose}
            className={cn(styles["modal"], styles["modal--danger"])}
            backdropClassName={"custom-backdrop"}
        >
            <span className={styles["modal__alert"]}>
                <AlertFillCircleIcon />
                Внесенные изменения не сохранены
            </span>
            <Modal.Header/>
            <Modal.Body className={styles["modal__body"]}>
                <div className={styles["text"]}>
                    Вы уверены, что хотите закрыть настройки?
                </div>

                <div className={styles["actions"]}>
                    <BaseButton
                        tag={"button"}
                        onClick={handleClose}
                        className={cn(styles["actions__btn"], "btn--secondary", "btn--outline", "btn--md")}
                    >
                        Нет, остаться
                    </BaseButton>
                    <BaseButton
                        tag={"button"}
                        onClick={() => {
                            handleClose();
                            dispatch(setHasChanged(false));
                            window.history.back();
                        }}
                        className={cn(styles["actions__btn"], "btn--danger", "btn--md")}
                    >
                        Да, выйти
                    </BaseButton>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default React.memo(SettingsViewModal);