/* eslint-disable max-len */
import React from "react";

const LinkIcon = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                d="M5 7.5V8.5H3C1.61929 8.5 0.5 7.38071 0.5 6C0.5 4.61929 1.61929 3.5 3 3.5H5V4.5H3C2.17157 4.5 1.5 5.17157 1.5 6C1.5 6.82843 2.17157 7.5 3 7.5H5ZM7 4.5V3.5H9C10.3807 3.5 11.5 4.61929 11.5 6C11.5 7.38071 10.3807 8.5 9 8.5H7V7.5H9C9.82843 7.5 10.5 6.82843 10.5 6C10.5 5.17157 9.82843 4.5 9 4.5H7ZM8.5 5.5H3.5V6.5H8.5V5.5Z"
                fill="currentColor"/>
        </svg>
    );
};

export default LinkIcon;