import React, {FC} from "react";
import cn from "classnames";

import LinkIcon from "../../../Icons/LinkIcon";
import AssignedIncidentTypes from "../AssignedIncidentTypes";
import EditIconFilled from "../../../Icons/EditIconFilled";

import styles from "./AssignButton.module.scss";

interface IAssignButtonProps {
    onClick: any,
    className?: string,
    data: any,
}
const AssignButton:FC<IAssignButtonProps> = ({onClick, className, data}) => {
    const incidentTypes = data?.data?.view?.incidentTypes;
    return (
        incidentTypes?.length 
            ? <div className={cn(styles["assign-block"], className)}>
                <AssignedIncidentTypes data={incidentTypes}/>
                <div
                    className={styles["assign-button"]}
                    onClick={onClick}
                >
                    <EditIconFilled/>
                </div>
            </div> 
            : <div
                className={cn(styles["assign-button"], className)}
                onClick={onClick}
            >
                <LinkIcon/> Присвоить инцидентам
            </div>
    );
};

export default AssignButton;