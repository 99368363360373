import React, {useState} from "react";
import cn from "classnames";
import {Link, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";

import {hasChangedSelector, isSettingsLoadingSelector, saveLoadingSelector} from "../../../redux/userSettingsSlice";

import CrossIcon from "../../Icons/CrossIcon";
import TabIcon1 from "../../Icons/Settings/TabIcon1";
import TabIcon2 from "../../Icons/Settings/TabIcon2";
import TabIcon3 from "../../Icons/Settings/TabIcon3";
import TabIcon4 from "../../Icons/Settings/TabIcon4";
import SettingsViewModal from "../SettingsViewPage/SettingsViewModal/SettingsViewModal";
import SpinnerWithOverlay from "../../UI/SpinnerWithOverlay";

import styles from "./SettingsHeader.module.scss";
import useHasPermission from "../../../hook/useHasPermission";
import {Permission} from "../../../models/Permission";


const SettingsHeader = () => {
    const {pathname} = useLocation();
    const hasChanged = useSelector(hasChangedSelector);
    const isSettingsLoading = useSelector(isSettingsLoadingSelector);
    const saveLoading = useSelector(saveLoadingSelector);

    const [isModalShow, setIsModalShow] = useState(false);
    const isAdmin = useHasPermission(Permission.ADMIN);
    const isManager = useHasPermission(Permission.MANAGER);

    const handleClose = () => {
        setIsModalShow(false);
    };

    const tabs = [
        {id: 1, name: "view", displayName: "Вид главного экрана", icon: <TabIcon1/>},
    ];

    if (isAdmin || isManager) {
        tabs.push({id: 2, name: "accounts", displayName: "Учетные записи", icon: <TabIcon2/>});
        tabs.push({id: 3, name: "zones", displayName: "Зоны контроля и камеры", icon: <TabIcon3/>});
        tabs.push({id: 4, name: "system", displayName: "Система", icon: <TabIcon4/>});
    }

    return (
        <div className={styles["wrapper"]}>
            <div className={styles["tabs"]}>
                {tabs.map(tab => (
                    <Link
                        to={`/settings/${tab.name}`}
                        replace={true}
                        key={tab.id}
                        className={cn(styles["tab"],
                            {[styles["tab--active"]]: pathname?.includes(tab.name)})}
                    >
                        {tab.icon}
                        <span>{tab.displayName}</span>
                    </Link>
                ))}
            </div>
            <div className={styles["close"]} onClick={() => {
                if (hasChanged) {
                    setIsModalShow(true);
                } else {
                    window.history.back();
                }
            }}>
                <CrossIcon />
            </div>
            <SettingsViewModal
                open={isModalShow}
                handleClose={handleClose}
            />
            {(isSettingsLoading || saveLoading) && <SpinnerWithOverlay/>}
        </div>
    );
};

export default SettingsHeader;