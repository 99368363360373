import React from "react";
import cn from "classnames";

import {ApiUrls} from "../../constants/urls";
import StringObjectInfoModel from "models/StringObjectInfoModel";

import ImageWithToken from "./ImageWithToken";
import Unknown from "images/icons/type/unknown.svg";
import LightTooltip from "../UI/Tooltip/LightTooltip";

import styles from "../ArchivePage/ArchiveTable/ArchiveTable.module.scss";

interface ITypeIconProps {
    type?: StringObjectInfoModel,
    withTooltip?: boolean,
    classname?: string,
    priority?: number,
    bigImg?: boolean,
    width?: number,
    height?: number,
}

const TypeIcon: React.FC<ITypeIconProps> = ({
    type,
    withTooltip = true,
    classname,
    priority,
    bigImg,
    width,
    height,
}) => {
    const icon = type?.code ? ApiUrls.INCIDENT_TYPE_ICON(type?.code) : "";
    const title = type?.name ?? "N/A";
    const isLocalType = type?.code === "local";
    // eslint-disable-next-line max-len
    const image = <div className={cn(styles["event-icon"], styles["event-icon--circle"], "icon-filter", classname, {[styles[`priority--${priority}`]]: String(priority)}, {[styles["event-icon--big"]]: bigImg})}>
        {!isLocalType && (
            <ImageWithToken
                src={icon}
                width={width || 22}
                height={height || 22}
                alt={title}
                fallbackSrc={Unknown}
                caching={true}
            />
        )
        }
    </div>;
    return (
        (withTooltip && !isLocalType)
            ? (
                <LightTooltip
                    title={title}
                >
                    {image}
                </LightTooltip>
            ) : image
    );
};

export default React.memo(TypeIcon);