import React, {FC, useState} from "react";
import cn from "classnames";
import Popover from "@mui/material/Popover";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {logout, userSelector} from "../../redux/authSlice";
import {TypedDispatch} from "../../types";
import {ApiUrls, AppUrls} from "../../constants/urls";
import {clearTokens} from "../../utils/auth";
import {toggleTheme} from "../../redux/commonSlice";
import useHasPermission from "../../hook/useHasPermission";
import {Permission} from "../../models/Permission";
import {IncidentEventStatus} from "../../models/IncidentEventStatus";
import {eventSelector} from "../../redux/eventSlice";

import Logo from "../../images/logo.svg";
import LogoSmall from "../../images/logo-small.svg";
import UserIcon from "../../images/icons/user.svg";
import ImageWithToken from "../Icons/ImageWithToken";
import Image from "../Icons/Image";
import HeaderBurger from "./HeaderBurger/HeaderBurger";
import HeaderIncidents from "./HeaderIncidents";
import QuickSettings from "./QuickSettings";
import ThemeToggle from "./ThemeToggle";
import SettingsHeader from "../Settings/SettingsHeader";
import HeaderTickets from "./HeaderTickets/HeaderTickets";
import ChevronBottomIcon from "../Icons/ChevronBottomIcon";
import HeaderTimer from "./HeaderTimer/HeaderTimer";

import styles from "./Header.module.scss";

interface IHeaderProps {
    theme: string,
    filters?: React.ReactNode,
}

const Header: FC<IHeaderProps> = ({theme, filters}) => {
    const dispatch = useDispatch<TypedDispatch>();
    const navigate = useNavigate();
    const {t} = useTranslation("common");
    const {pathname} = useLocation();
    const settingsMode = pathname.includes("settings");
    const isService = useHasPermission(Permission.SERVICE);
    const event = useSelector(eventSelector);
    const user = useSelector(userSelector);

    // @ts-ignore
    const incidentMode = pathname.includes(event?.id);

    // User dropdown menu
    const [anchorUserDropdownEl, setAnchorUserDropdownEl] = useState<null | HTMLElement>(null);
    const isUserDropdownOpen = Boolean(anchorUserDropdownEl);

    const isIncidentProcessing = event?.status === IncidentEventStatus.Processing; // принят (в обработке)
    const isIncidentResolved = event?.status === IncidentEventStatus.Resolved; // закрыт (обработан)

    const handleUserDropdownClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorUserDropdownEl(event.currentTarget);
    };

    const handleUserDropdownClose = () => {
        setAnchorUserDropdownEl(null);
    };

    // Переключение темы
    const handleThemeChange = () => {
        const newThemeValue = theme === "light" ? "dark" : "light";
        dispatch(toggleTheme(newThemeValue));

        // Записываем значение темы в localStorage
        localStorage.setItem("theme", newThemeValue);
    };

    const handleLogout = async(event: React.MouseEvent) => {
        event.preventDefault();
        clearTokens();
        await dispatch(logout());
        navigate(AppUrls.AUTH);
    };

    return (
        <header className={styles["header"]}>
            {settingsMode
                ? <div className={styles["header__title"]}>Настройки</div>
                : <>
                    <HeaderBurger/>
                    <picture className={styles["header__logo"]}>
                        <source srcSet={Logo} media="(min-width: 768px)"/>
                        <Image
                            src={LogoSmall}
                            alt="Logo"
                        />
                    </picture>
                </>
            }

            <HeaderIncidents/>

            {/*<HeaderNotifications/>*/}

            {!isService && <HeaderTickets/>}

            {incidentMode && (isIncidentResolved || isIncidentProcessing) && (
                <HeaderTimer/>
            )}

            {settingsMode
                ? <SettingsHeader/>
                : <div className="d-flex align-items-center w-100">
                    {filters}

                    <ThemeToggle theme={theme} handleThemeChange={handleThemeChange}/>

                    {!isService && <QuickSettings/>}

                    <div className={cn(styles["header__user"], styles["header-user"])}>
                        <div
                            className={styles["header-user__btn"]}
                            onClick={handleUserDropdownClick}
                        >
                            <div className={styles["header-user__icon"]}>
                                {
                                    user?.UserId ?
                                        <ImageWithToken
                                            src={ApiUrls.USER_AVATAR(user?.UserId)}
                                            width={24}
                                            height={24}
                                            alt="User"
                                            fallbackSrc={UserIcon}
                                        />
                                        : <></>
                                }
                            </div>

                            <ChevronBottomIcon className={styles["header-user__chevron-btn"]} width={16} height={16}/>
                        </div>

                        <Popover
                            className={styles["header-user__popover"]}
                            open={isUserDropdownOpen}
                            anchorEl={anchorUserDropdownEl}
                            onClose={handleUserDropdownClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                        >
                            <div className={styles["header-user__dropdown"]}>
                                {user && (
                                    <div className={styles["header-user__dropdown-item"]}>
                                        {user.FullName}
                                    </div>
                                )}
                                <div
                                    className={styles["header-user__dropdown-item"]}
                                    onClick={handleLogout}
                                >
                                    {t("exit")}
                                </div>
                            </div>
                        </Popover>
                    </div>
                </div>
            }
        </header>
    );
};

export default React.memo(Header);
