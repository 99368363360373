export const DEFAULT_LOCALE = "ru";
export const ACCESS_TOKEN_NAME = "accessToken";
export const REFRESH_TOKEN_NAME = "refreshToken";
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_EVENTS_SORT = "registrationDateTime,desc";
export const DEFAULT_USERS_SORT = "userId,asc";

export const DEFAULT_EFFICIENCY_JOURNAL_SORT = "roadObjectId,asc";

export const MONTH_NAMES: {[index: string]: string} = {
    0: "Январь",
    1: "Февраль",
    2: "Март",
    3: "Апрель",
    4: "Май",
    5: "Июнь",
    6: "Июль",
    7: "Август",
    8: "Сентябрь",
    9: "Октябрь",
    10: "Ноябрь",
    11: "Декабрь",
};