import i18n from "i18next";
import {initReactI18next} from "react-i18next";

import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import LanguageDetector from "i18next-browser-languagedetector";
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
    .use(ChainedBackend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: "ru",
        supportedLngs: ["ru", "en"],
        backend: {
            backends: typeof window !== "undefined" ? [LocalStorageBackend, HttpBackend]: [],
            backendOptions: [{
                expirationTime: 60 * 60 * 1000, // 1 hour
            }, {
                loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
            }],
        },

        ns: ["dashboard"],
        defaultNS: "dashboard",

        debug: true,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        i18nFormat: {
            localeData: "ru",
        },
    });


export default i18n;