import {createAsyncThunk, createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "store";
import {isEmpty, isObject, isArray} from "lodash";
import proj4 from "proj4";

import createHttpRequest, {cacheSettings, http} from "../utils/http";
import {ApiUrls} from "../constants/urls";
import FeatureCollection from "../models/FeatureCollection";
import Feature from "../models/Feature";
import HeatmapCollection from "../models/HeatmapCollection";
import {getFormattedDate} from "../utils/datetime";
import {buildQuery} from "../utils/query";
import {IEventsFilters} from "./eventSlice";
import {
    CameraMoveConfig,
    RoadObjectCameraCapabilities,
    RoadObjectInfo,
    RoadObjectsCameraCapabilities, SelectedRoadObjectsForStream,
} from "../models/VideoStream";
import Geometry from "../models/Geometry";

interface IMoveCameraPtzRequest {
    roadObjectId: number,
    data: {
        command?: string;
        cameraID?: number;
        timeout?: number;
        panSpeed: number;
        tiltSpeed: number;
        zoomSpeed: number;
        continuous?: boolean;
    }
}

interface ISelectedRoadObjectsStream {
    selectedObject: Feature | null,
    roadObjectKey: keyof SelectedRoadObjectsForStream,
    message?: string | null,
    delete?: boolean,
    clearAll?: boolean,
    maxCount?: number,
}

interface RoadObjectCameraCapabilitiesProps {
    roadObjectId: number,
    isMultiplyStreams?: boolean,
    capabilityKey: keyof RoadObjectsCameraCapabilities,
}

interface IFetchRoadObjectListForIncidents {
    name?: string,
    mode?: string,
}

export interface IRoadObjectsCoordinates {
    [key: string]: string,
}

export interface IRoadObjectsMap {
    [key: string]: Feature[],
}

/*interface IRoadObjectFeatureHashMap {
    roadObjectsCoordinates: IRoadObjectsCoordinates,
}*/

export interface ISelectedRoadObjectStream {
    selectedRoadObject: Feature,
    message?: string | null,
}

export interface IHeatmapFilters {
    sinceDay: string | null, //дата от
    untilDay: string | null, //дата до
    dateKind: string | null,
    onlyWithNested: boolean | null,
}

export interface IHeatmapRequest {
    mode?: string,
}

export interface ITimeIndicatorItems {
    hour: number,
    incidentCount: number
}

interface ITimeIndicatorDays {
    day: string,
    hours: ITimeIndicatorItems[]
}

interface ITimeIndicators {
    byHour: ITimeIndicatorDays[]
}

interface ITimeIndicatorsFilters {
    "date.days": string | null,
    "date.time": string | null,
    onlyWithNested: boolean
}

export interface IRoadObjectsFilters {
    virtualSources: "incCamera" | "videoCamera" | string | null,
    typeIds?: "100" | "7" | "10" | "",
    sourceIds?: string,
    mode?: "OR" | "AND",
    zoneIds?: number[],
}

type DemonstrationRoadObjectsFiltersType = Pick<IRoadObjectsFilters, "virtualSources" | "typeIds" | "mode" | "zoneIds"
    | "sourceIds">;
type FetchRoadObjectListForIncidentsType = "roadObjectsFilters" | "demonstrationRoadObjectsFilters"
    | "secondScreenRoadObjectsFilters" | "archiveIncidentRoadObjectsFilters" | "settingsRoadObjectsFilters";

interface IFetchRoadObjectListForIncidentsPayload {
    currentFilters?: FetchRoadObjectListForIncidentsType,
    filters?: {
        mode?: string,
    }
    signal?: AbortSignal,
    isCached?: boolean,
}

interface IToggleZoneFilterRoadObjectPayload {
    zoneId: number,
    currentFilters: "roadObjectsFilters" | "demonstrationRoadObjectsFilters" | "secondScreenRoadObjectsFilters" |
        "settingsRoadObjectsFilters"
}

interface IRoadObjectAddPayload {
    name: string,
    roadObjectTypeId: number,
}

interface IRoadObjectDeletePayload {
    id: number,
    objectSourceId: number,
}

export interface IRoadObjectAddResponse {
    Data: {
        Id: number,
    },
    Message: string,
    IsSuccess: boolean
}

export interface IRoadObjectDeleteResponse {
    Message: string,
    IsSuccess: boolean
}

interface IRoadObjectUpdatePayload {
    id: string | number,
    type?: string,
    geometry?: {
        coordinates: [[[number, number]]],
        type: Geometry["type"],
    };
    properties?: {
        roadObject: {
            id: string,
            name: string,
            map: boolean,
        },
        stateColor: string,
    }
    showOnTheMap?: boolean,
}

interface IRoadObjectAdminUpdatePayload {
    id: number,
    name: string,
    showOnTheMap: boolean,
}

interface IRoadObjectAdminUpdatePropertiesPayload {
    roadObjectId: number,
    viewJson: string,
}

interface IInitialState {
    foundRoadObjects: FeatureCollection,
    foundRoadObjectsLoading: boolean,
    roadObjectFeatureCollection: FeatureCollection | null,
    roadObjectFeatureCollectionFiltered: FeatureCollection | null,
    roadObjectVehicleFeatureCollection: FeatureCollection | null,
    roadObjectFeatureCollectionProTV: FeatureCollection | null,
    roadObjectFeatureCoordsHashMap: IRoadObjectsMap | null,
    radObjectVehicleFeaturesIndexes: {[key: string]: number} | null,
    roadObjectFeaturesHashMap: {[key: string]: Feature},
    roadObjectsFeatureIndexes: {[key: string]: number} | null, // Таблица индексов дорожных объектов по их id
    selectedRoadObjectsForStream: SelectedRoadObjectsForStream, // Камеры для стримов
    // roadObjectCameraCapabilities: RoadObjectCameraCapabilities | null, // Один стрим в DashboardTarget
    roadObjectsCameraCapabilities: RoadObjectsCameraCapabilities,
    dashboardModalSelectedStream: Feature | null,
    isAddingStreamMode: boolean,
    isCameraPtzProcessing: boolean,
    isRoadObjectFeatureCollectionLoading: boolean,
    isRoadObjectFeatureCollectionFilteredLoading: boolean,
    isRoadObjectCameraCapabilitiesLoading: boolean,
    heatmapRoadObjects: HeatmapCollection[] | null,
    heatmapLoading: boolean,
    filters: IHeatmapFilters,
    timeIndicators: ITimeIndicators,
    timeIndicatorsFilters: ITimeIndicatorsFilters,
    selectedCameraInfo: RoadObjectInfo | null,
    selectedCameraInfoLoading: boolean,
    roadObjectsFilters: IRoadObjectsFilters,
    settingsRoadObjectsFilters: IRoadObjectsFilters,
    archiveIncidentRoadObjectsFilters: IRoadObjectsFilters,
    demonstrationRoadObjectsFilters: DemonstrationRoadObjectsFiltersType,
    secondScreenRoadObjectsFilters: DemonstrationRoadObjectsFiltersType,
}

const initialState: IInitialState = {
    foundRoadObjects: {}, // Список дорожных объектов при поиске
    foundRoadObjectsLoading: false,
    roadObjectFeatureCollection: null,
    roadObjectFeatureCollectionFiltered: null,
    roadObjectVehicleFeatureCollection: null,
    radObjectVehicleFeaturesIndexes: null,
    roadObjectFeatureCollectionProTV: null,
    roadObjectsFeatureIndexes: null, // Таблица индексов дорожных объектов
    roadObjectFeatureCoordsHashMap: null,
    roadObjectFeaturesHashMap: {},
    // roadObjectCameraCapabilities: null, // Данные по камере

    selectedRoadObjectsForStream: { // Выбранные дорожные объекты для стримов
        dashboardTarget: null, // Главная - dashboardTarget
        archiveIncidentPage: null, // Страница инцидента
        roadObjectInfoBlock: null, // Блок инф-ии о камера на карте
        dashboardModal: [], // Модальное окно на Главной
        dashboardStreams: [], // Стримы в блоке ключевых показателей на Главной
        demoIncidentsOnControl: [], // Дашборд рукодства - стримы с инцидентов на контроле + с карты
        demoFavoriteIncidents: null, // Дашборд рукодства. Стрим в виджете ТОП-10 инцидентов
        secondScreenStreams: [], // Стримы на втором экране оператора
    },

    roadObjectsCameraCapabilities: { // Данные по камерам
        dashboardStreams: {}, // Блок стримов на Главной
        dashboardTarget: {}, // DashboardTarget
        dashboardModal: {}, // Модальное окно на Главной
        archiveIncidentPage: {}, // Страница инцидента
        demoIncidentsOnControl: {}, // Даш руковосдвта - Инцидентов на контроле
        demoFavoriteIncidents: {}, // Даш руковосдвта - Виджет ТОП-10
        roadObjectInfoBlock: {}, // Блок информации о дорожном объекте на главной
        secondScreenStreams: {}, // Второй экран оператора
    },
    dashboardModalSelectedStream: null, // Выбранный (клик) стрим в модальном окне на Главной (для центрирования карты)
    isAddingStreamMode: false,
    isCameraPtzProcessing: false,
    isRoadObjectFeatureCollectionLoading: false,
    isRoadObjectFeatureCollectionFilteredLoading: false,
    isRoadObjectCameraCapabilitiesLoading: false,
    heatmapRoadObjects: [],
    heatmapLoading: false,
    filters: {
        sinceDay: getFormattedDate(new Date(), {format: "YYYY-MM-DD"}) || null,
        untilDay: getFormattedDate(new Date(), {format: "YYYY-MM-DD"}) || null,
        dateKind: "interval",
        onlyWithNested: true,
    },
    timeIndicators: {byHour: []},
    timeIndicatorsFilters: {
        "date.days": getFormattedDate(new Date(), {format: "YYYY-MM-DD"}) || null,
        "date.time": "",
        onlyWithNested: true,
    },
    selectedCameraInfo: null,
    selectedCameraInfoLoading: false,
    roadObjectsFilters: {
        virtualSources: "incCamera",
        mode: "OR",
    },
    archiveIncidentRoadObjectsFilters: {
        virtualSources: "incCamera,videoCamera",
        mode: "OR",
    },
    settingsRoadObjectsFilters: {
        virtualSources: "incCamera",
        mode: "OR",
    },
    demonstrationRoadObjectsFilters: {
        virtualSources: "incCamera,videoCamera",
        mode: "OR",
    },
    secondScreenRoadObjectsFilters: {
        virtualSources: "incCamera",
        mode: "OR",
    },
};

function getQueryParams(rootState: RootState): IHeatmapFilters {
    const state = rootState.roadObjectReducer;
    return {...state.filters};
}

// Дорожные объекты (FeatureCollection)
export const fetchRoadObjectListForIncidents =
    createAsyncThunk<FeatureCollection, Partial<IFetchRoadObjectListForIncidentsPayload>>(
        "roadObject/fetchRoadObjectListForIncidents", async({isCached}) => {
            // @ts-ignore Включаем кэширование
            http.defaults.cache = isCached ?? cacheSettings;
            const response = await createHttpRequest({
                method: "GET",
                path: ApiUrls.ROAD_OBJECT_LIST_FOR_INCIDENTS,
                errorMessage: "messages:fetch_road_object_list_for_incidents_error",
            });
            // @ts-ignore Снова выключаем кэширование
            http.defaults.cache = false;

            return response.data;
        });

export const fetchRoadObjectListForIncidentsFiltered =
    createAsyncThunk<FeatureCollection, IFetchRoadObjectListForIncidentsPayload | undefined>(
        "roadObject/fetchRoadObjectListForIncidentsFiltered",
        async(args, {getState}) => {
            const filters: any = {
                //@ts-ignore
                ...(getState() as RootState).roadObjectReducer[args?.currentFilters as keyof IInitialState],
                ...args?.filters,
            };

            const path = buildQuery(
                ApiUrls.ROAD_OBJECT_LIST_FOR_INCIDENTS,
                filters as object,
                ["zoneIds"]
            );

            // Включаем кэширование
            http.defaults.cache = cacheSettings;
            const response = await createHttpRequest({
                method: "GET",
                path,
                signal: args?.signal,
                errorMessage: "messages:fetch_road_object_list_for_incidents_error",
            });
            // @ts-ignore Снова выключаем кэширование
            http.defaults.cache = false;

            return response.data;
        });

// Транспортные средства (FeatureCollection)
export const fetchRoadObjectListVehicle =
    createAsyncThunk<FeatureCollection, FetchRoadObjectListForIncidentsType | undefined>(
        "roadObject/fetchRoadObjectListVehicle", async() => {
            const response = await createHttpRequest({
                method: "GET",
                path: `${ApiUrls.ROAD_OBJECT_LIST_FOR_INCIDENTS}?typeIds=10`,
                errorMessage: "messages:fetch_road_object_list_for_incidents_error",
            });

            return response.data;
        });

export const fetchRoadObjectListForIncidentsProTV =
    createAsyncThunk<FeatureCollection, FetchRoadObjectListForIncidentsType | undefined>(
        "roadObject/fetchRoadObjectListForIncidentsProTV",
        async() => {
            const filters = {virtualSources: "incCamera", mode: "OR"};

            const path = buildQuery(
                ApiUrls.ROAD_OBJECT_LIST_FOR_INCIDENTS,
                filters as object,
                ["zoneIds"]
            );

            const response = await createHttpRequest({
                method: "GET",
                path,
                errorMessage: "messages:fetch_road_object_list_for_incidents_error",
            });

            return response.data;
        });

// Поиск дорожных объектов
export const searchRoadObjectListForIncidents =
    createAsyncThunk<FeatureCollection, IFetchRoadObjectListForIncidents>(
        "roadObject/searchRoadObjectListForIncidents",
        async(args, {getState}) => {
            const filters = (getState() as RootState).roadObjectReducer.roadObjectsFilters;
            const path = buildQuery(ApiUrls.ROAD_OBJECT_LIST_FOR_INCIDENTS, {...filters, ...args});

            const response = await createHttpRequest({
                method: "GET",
                path,
                errorMessage: "messages:search_road_object_error",
            });

            return response.data;
        });

// Информация о камере
export const fetchRoadObjectCameraCapabilities =
    // eslint-disable-next-line max-len
    createAsyncThunk<RoadObjectCameraCapabilities, RoadObjectCameraCapabilitiesProps>("roadObject/fetchRoadObjectCameraCapabilities", async({roadObjectId}) => {
        const response = await createHttpRequest({
            method: "GET",
            path: ApiUrls.GET_ROAD_OBJECT_CAMERA_CAPABILITIES(roadObjectId),
            errorMessage: "messages:fetch_road_object_camera_capabilities_error",
        });

        return response.data;
    });

// Управление ptz камерой
export const moveRoadObjectsCameraPtz =
    // eslint-disable-next-line max-len
    createAsyncThunk<CameraMoveConfig, IMoveCameraPtzRequest>("roadObject/actionRoadObjectCameraPtzStart", async(
        {roadObjectId, data}) => {
        const response = await createHttpRequest({
            method: "POST",
            path: ApiUrls.ROAD_OBJECTS_CAMERA_PTZ(roadObjectId),
            data: data,
            errorMessage: "messages:road_objects_camera_ptz_error",
        });

        return response.data;
    });

// Подробная информация о выбранном дорожном объекте
export const fetchRoadObjectInfo =
    // eslint-disable-next-line max-len
    createAsyncThunk<RoadObjectInfo, number>("roadObject/fetchRoadObjectInfo", async(roadObjectId) => {
        const response = await createHttpRequest({
            method: "GET",
            path: ApiUrls.ROAD_OBJECT_INFO(roadObjectId),
            errorMessage: "messages:fetch_road_object_info_error",
        });

        return response.data;
    });

// Дорожные объекты на тепловой карте
export const fetchHeatmapRoadObjects =
    createAsyncThunk<HeatmapCollection, IHeatmapRequest>("roadObject/fetchHeatmapRoadObjects", async(
        args, {getState}) => {
        const path = buildQuery(ApiUrls.HEATMAP_ROAD_OBJECTS, getQueryParams(getState() as RootState));
        const response = await createHttpRequest({
            method: "GET",
            path,
            errorMessage: "messages:fetch_road_object_list_for_incidents_error",
        });

        return response.data;
    });

// Индикаторы для фильтра по времени на тепловой карте
export const fetchTimeIndicators =
    createAsyncThunk<ITimeIndicators>("roadObject/fetchTimeIndicators", async(args, {getState}) => {
        const {roadObjectReducer: {timeIndicatorsFilters}} = getState() as RootState;
        const response = await createHttpRequest({
            method: "GET",
            path: ApiUrls.TIME_INDICATORS(
                timeIndicatorsFilters["date.days"],
                timeIndicatorsFilters["date.time"] || "",
                timeIndicatorsFilters.onlyWithNested
            ),
            errorMessage: "messages:time_indicators_error",
        });

        return response.data;
    });

// Создание дорожного объекта
export const roadObjectAdd =
    createAsyncThunk<IRoadObjectAddResponse, IRoadObjectAddPayload>("roadObject/roadObjectAdd",
        async({name, roadObjectTypeId}) => {
            const response = await createHttpRequest({
                method: "POST",
                path: ApiUrls.ROAD_OBJECT_ADD(name, roadObjectTypeId),
                errorMessage: "messages:road_object_add_error",
            });

            return response.data;
        });

// Обновление дорожного объекта
export const roadObjectUpdate =
    createAsyncThunk<null, IRoadObjectUpdatePayload>("roadObject/roadObjectUpdate",
        async(data) => {
            const response = await createHttpRequest({
                method: "POST",
                path: ApiUrls.ROAD_OBJECT_UPDATE,
                data: data,
                errorMessage: "messages:update_road_object_error",
            });

            return response.data;
        });

// Обновление дорожного объекта через панель админа
export const roadObjectAdminUpdate =
    createAsyncThunk<null, IRoadObjectAdminUpdatePayload>("roadObject/roadObjectAdminUpdate",
        async(data) => {
            const response = await createHttpRequest({
                method: "POST",
                path: ApiUrls.ROAD_OBJECT_ADMIN_UPDATE,
                data: data,
                errorMessage: "messages:road_object_update_error",
            });

            return response.data;
        });

// Обновление свойств дорожного объекта через панель админа (P.S: Временное решение)
export const roadObjectAdminUpdateProperties =
    createAsyncThunk<null, IRoadObjectAdminUpdatePropertiesPayload>("roadObject/roadObjectAdminUpdate",
        async(data) => {
            const response = await createHttpRequest({
                method: "POST",
                path: ApiUrls.ROAD_OBJECT_ADMIN_UPDATE_PROPERTIES,
                data: data,
                errorMessage: "messages:road_object_update_error",
            });

            return response.data;
        });

// Удаление дорожного объекта (в админке)
export const roadObjectAdminDelete =
    createAsyncThunk<IRoadObjectDeleteResponse, IRoadObjectDeletePayload>("roadObject/roadObjectAdd",
        async({id, objectSourceId}) => {
            const response = await createHttpRequest({
                method: "POST",
                path: ApiUrls.ROAD_OBJECT_ADMIN_DELETE(id, objectSourceId),
                errorMessage: "messages:road_object_add_error",
            });

            return response.data;
        });

// Назначение дорожных объектов зоне контроля (ответственности)
export const responsibilityZonesRoadObjectsAttaching =
    createAsyncThunk<null, {id: number, data: number[]}>("roadObject/responsibilityZonesRoadObjectsAttaching",
        async({id, data}) => {
            const response = await createHttpRequest({
                method: "POST",
                path: ApiUrls.RESPONSIBILITY_ZONES_ROAD_OBJECTS_ATTACHING(id),
                data: data,
                errorMessage: "messages:responsibility_zones_road_objects_attaching_error",
            });

            return response.data;
        });

const roadObject = createSlice({
    reducers: {
        // Выбранные камеры для мультистриминга
        setSelectedRoadObjectsForStream(state, {payload}:PayloadAction<ISelectedRoadObjectsStream>) {
            const roadObjectKey = payload.roadObjectKey;
            const prevState = state.selectedRoadObjectsForStream[roadObjectKey];
            const roadObjectId = payload.selectedObject?.id;
            const prevCount = isArray(prevState) ? prevState.length : 0;
            const maxCount = payload.maxCount || 999;

            if (roadObjectKey === "demoIncidentsOnControl") {
                const selectedStreamExist = isArray(prevState)
                    // @ts-ignore
                    ? prevState?.find((item: ISelectedRoadObjectStream) => {
                        if (item?.selectedRoadObject?.id) {
                            return String(payload.selectedObject?.id) === String(item.selectedRoadObject.id);
                        }
                    })
                    // @ts-ignore
                    // eslint-disable-next-line max-len
                    :  String(payload.selectedObject?.id) === state.selectedRoadObjectsForStream[roadObjectKey]?.selectedRoadObject?.id;

                if (!selectedStreamExist && payload.selectedObject && !payload.delete) {
                    if (prevCount < maxCount) {
                        if (isArray(prevState)) {
                            // @ts-ignore
                            state.selectedRoadObjectsForStream[roadObjectKey]?.push({
                                selectedRoadObject: payload.selectedObject,
                                message: payload.message,
                            });
                        } else {
                            state.selectedRoadObjectsForStream[roadObjectKey] = {
                                // @ts-ignore
                                selectedRoadObject: payload.selectedObject,
                                message: payload.message,
                            };
                        }
                    }
                    return;
                }

                if (roadObjectId && payload.delete) {
                    if (isArray(state.selectedRoadObjectsForStream[roadObjectKey])) {
                        state.selectedRoadObjectsForStream[roadObjectKey] =
                            state.selectedRoadObjectsForStream[roadObjectKey]
                                // @ts-ignore
                                ?.filter((roadObject: any) => {
                                    return String(roadObject.selectedRoadObject.id) !== String(roadObjectId);
                                });
                    } else {
                        // @ts-ignore
                        state.selectedRoadObjectsForStream[roadObjectKey] = null;
                    }
                }
            } else {
                const selectedStreamExist = isArray(prevState)
                    // @ts-ignore
                    ? prevState?.find((item: Feature) => {
                        if (item?.id) {
                            return String(payload.selectedObject?.id) === String(item.id);
                        }
                    })
                    // @ts-ignore
                    :  String(payload.selectedObject?.id) === state.selectedRoadObjectsForStream[roadObjectKey]?.id;

                if (!selectedStreamExist && payload.selectedObject && !payload.delete) {
                    if (prevCount < maxCount) {
                        if (isArray(prevState)) {
                            // @ts-ignore
                            state.selectedRoadObjectsForStream[roadObjectKey]?.push(payload.selectedObject);
                        } else {
                            // @ts-ignore
                            state.selectedRoadObjectsForStream[roadObjectKey] = payload.selectedObject;
                        }
                    }
                }

                if (payload.delete) {
                    if (isArray(state.selectedRoadObjectsForStream[roadObjectKey])) {
                        if (roadObjectId) {
                            state.selectedRoadObjectsForStream[roadObjectKey] =
                                state.selectedRoadObjectsForStream[roadObjectKey]
                                    // @ts-ignore
                                    ?.filter((roadObject: any) => {
                                        return String(roadObject.id) !== String(roadObjectId);
                                    });
                        }
                    } else {
                        // @ts-ignore
                        state.selectedRoadObjectsForStream[roadObjectKey] = null;
                    }
                }
            }

            if (payload.clearAll) {
                // @ts-ignore
                state.selectedRoadObjectsForStream[roadObjectKey] = isArray(prevState) ? [] : null;
            }
        },
        // Формирование текущего списка индексов дорожных объектов
        setRoadObjectsFeatureIndexes(state, {payload}) {
            const featureIndexesHashMap: any = {};

            for (let i = 0; i < payload?.length; i++) {
                const feature = payload?.[i];
                featureIndexesHashMap[feature.id] = i;
            }

            state.roadObjectsFeatureIndexes = featureIndexesHashMap;
        },
        setRoadObjectFeatureCoordsHashMap(state, {payload}) {
            const FeatureCoordsHashMap: IRoadObjectsMap = {};

            if (!state.roadObjectFeatureCoordsHashMap) {
                for (let i = 0; i < payload?.features.length; i++) {
                    if (payload.features[i].geometry?.coordinates && payload.features[i].geometry.type === "Point") {
                        const coordinates: any = payload.features[i].geometry?.coordinates
                            // Убираем последнюю цифру из-за погрешности в округлении
                            .map((coordinate: number) => Math.trunc(coordinate / 10))
                            .join("");
                        if (FeatureCoordsHashMap[coordinates]) {
                            FeatureCoordsHashMap[coordinates] = [
                                ...FeatureCoordsHashMap[coordinates],
                                payload.features[i],
                            ];
                            continue;
                        }

                        FeatureCoordsHashMap[coordinates] = [payload.features[i]];
                    }
                }

                state.roadObjectFeatureCoordsHashMap = FeatureCoordsHashMap;
            }
        },
        // Хэш таблица дорожных объектов {id: {feature}}
        setRoadObjectFeaturesHashMap(state, {payload}) {
            const roadObjects = payload;
            const acc: {[key: string]: Feature} = {};

            if (isEmpty(state.roadObjectFeaturesHashMap)) {
                for (let i = 0; i < roadObjects?.length; i++) {
                    acc[`${roadObjects[i]?.id}`] = {
                        id: roadObjects[i]?.id,
                        properties: roadObjects[i]?.properties,
                        geometry: roadObjects[i]?.geometry,
                    };
                }

                state.roadObjectFeaturesHashMap = acc;
            }
        },
        // Таблица индексов дорожных объектов транспортных средств
        setRoadObjectVehicleFeaturesIndexes(state, {payload}) {
            const featureIndexesHashMap: any = {};

            for (let i = 0; i < payload?.length; i++) {
                const feature = payload?.[i];
                featureIndexesHashMap[feature.id] = i;
            }

            state.radObjectVehicleFeaturesIndexes = featureIndexesHashMap;
        },
        // Обновление дорожных объектов транспортных средств
        updateRoadObjectVehicleFeaturesCollections(state, {payload}) {
            if (state.radObjectVehicleFeaturesIndexes) {
                const indexTable = state.radObjectVehicleFeaturesIndexes;
                if (payload) {
                    payload.forEach((feature: any) => {
                        const featureIndex = indexTable[feature.roadObjectId || 0];
                        const azimuth = JSON.parse(feature.viewJson)?.RnisTmatic?.Bearing;
                        if (featureIndex && state.roadObjectVehicleFeatureCollection
                            //@ts-ignore
                            && feature.spatialSphere && state.roadObjectVehicleFeatureCollection.features
                            && state.roadObjectVehicleFeatureCollection.features[featureIndex].geometry) {
                            // @ts-ignore
                            state.roadObjectVehicleFeatureCollection.features[featureIndex].geometry.coordinates =
                            [feature.spatialSphere.Longitude, feature.spatialSphere.Latitude];

                            if (azimuth) {
                                // @ts-ignore
                                state.roadObjectVehicleFeatureCollection.features[featureIndex].properties.a = azimuth;
                            }
                        }
                    });
                }
            }
        },
        setRoadObjectsCameraCapabilities(state, {payload}) {
            // eslint-disable-next-line max-len
            const prevState = state.roadObjectsCameraCapabilities[payload.capabilityKey as keyof RoadObjectsCameraCapabilities];
            const roadObjectId = payload.selectedObject.id;
            const capabilityKey = payload.capabilityKey;

            if (!payload.delete) {
                switch (capabilityKey) {
                    case capabilityKey === "dashboardTarget":
                        prevState[capabilityKey] = {[roadObjectId]: payload.selectedObject};
                        break;
                    case capabilityKey === "demoFavoriteIncidents":
                        prevState[capabilityKey] = {[roadObjectId]: payload.selectedObject};
                        break;
                    case capabilityKey === "archiveIncidentPage":
                        prevState[capabilityKey] = {[roadObjectId]: payload.selectedObject};
                        break;
                    default:
                        if (prevState && roadObjectId) {
                            // @ts-ignore
                            state.roadObjectsCameraCapabilities[capabilityKey] = {
                                ...prevState,
                                [roadObjectId]: payload.selectedObject,
                            };
                        }
                }
            }

            if (roadObjectId && payload.delete) {
                // @ts-ignore
                delete state.roadObjectsCameraCapabilities[capabilityKey][roadObjectId];
            }
        },
        // Фильтры для тепловой карты
        setFilters(state, {payload}) {
            const filtersItem = state.filters?.[payload.filterType as keyof typeof state.filters];
            const nextValue = typeof payload.selectedFilters === "string"
                ? payload.selectedFilters
                : JSON.stringify(payload.selectedFilters);
            const currentValue = typeof filtersItem === "string" ? filtersItem : JSON.stringify(filtersItem);

            if (nextValue !== currentValue) {
                state.filters = {
                    ...state.filters,
                    [payload.filterType]: payload.selectedFilters,
                };
            }
        },
        // Фильтры дорожных объектов
        setRoadObjectFilters(state, {payload}) {
            const {data, currentFilters} = payload;
            const newData = Object.keys(data);

            const setFilterValue = (filter: any) => {
                const values: string[] = [];
                if (isObject(data[filter])) {
                    for (const key in data[filter]) {
                        if (data[filter][key]) {
                            values.push(key);
                        }
                    }
                }

                if (typeof data[filter] === "string") {
                    if (data[filter] !== "") values.push(data[filter]);
                }
                // @ts-ignore
                state[currentFilters][`${filter}`] = values?.length ? values.join(",") : null;
            };

            for (let i = 0; i < newData.length; i++) {
                setFilterValue(newData[i]);
            }
        },
        // Фильтр зон дорожных объектов
        toggleZoneFilterRoadObject(state, {payload}) {
            const {zoneId, currentFilters}: IToggleZoneFilterRoadObjectPayload = payload;
            if (!state[currentFilters].zoneIds) {
                state[currentFilters].zoneIds=[];
            }
            const index = state[currentFilters].zoneIds?.indexOf(zoneId);
            if (typeof index !== "undefined" &&  index > -1){
                state[currentFilters].zoneIds?.splice(index, 1);
            } else {
                state[currentFilters].zoneIds?.push(zoneId);
            }
        },

        setInitQueryParams(state, {payload: {stateUpdateFields, params}}) {
            stateUpdateFields?.forEach((field: keyof typeof state) => {
                const stateField = state[field] as keyof IInitialState;
                const newState: keyof IInitialState | {[key: string]: any} = {};
                // Перебираем переданные квери параметры и заменяем значения в существующих полях стейт
                Object.entries(params).forEach((([paramKey, paramValue]) => {
                    const key = paramKey as keyof IEventsFilters;
                    const value = paramValue as string;
                    if (Object.prototype.hasOwnProperty.call(stateField, key)) {
                        // @ts-ignore
                        stateField[key] && Array.isArray(stateField[key])
                            ? newState[key] = value.split(",")
                            : newState[key] = value;
                    }
                }));

                if (Object.keys(newState).length) {
                    (state[field] as IInitialState) = {
                        ...state[field] as IInitialState,
                        ...newState,
                    };
                }
            });
        },
        // Фильтры для индикаторов времени тепловой карты
        setIndicatorsFilters(state, {payload}) {
            state.timeIndicatorsFilters["date.days"] = payload.days;
            state.timeIndicatorsFilters["date.time"] = payload.time;
            state.timeIndicatorsFilters.onlyWithNested = payload.onlyWithNested;
        },
        updateHeatmapFilter(state, {payload}) {
            state.filters.sinceDay = state?.filters?.sinceDay?.substring(0, 10) + `-${payload.min}00`;
            state.filters.untilDay = state?.filters?.untilDay?.substring(0, 10) + `-${payload.max === "24" 
                ? "2359" : payload.max + "00"}`;
        },
        resetRoadObjectCameraInfo(state, {payload}) {
            state.selectedCameraInfo = payload;
        },
        setRoadObjectFeatureCollectionFiltered(state, {payload}) {
            state.roadObjectFeatureCollectionFiltered = payload;
        },
        updateRoadObjectsFiltered(state, {payload}) {
            if (state.roadObjectFeatureCollectionFiltered?.features) {
                const prevProperties = state.roadObjectFeatureCollectionFiltered?.features[payload.index]?.properties;
                if (prevProperties) {
                    state.roadObjectFeatureCollectionFiltered.features[payload.index].properties = {
                        ...prevProperties,
                        ...payload.properties,
                    };
                }
            }
        },
        setRoadObjectVehicleFeatureCollection(state, {payload}) {
            state.roadObjectVehicleFeatureCollection = payload;
        },
        setDashboardModalSelectedStream(state, {payload}) {
            state.dashboardModalSelectedStream = payload;
        },
    },
    name: "roadObject",
    initialState,
    extraReducers: (builder) => {
        // Дорожные объекты
        builder.addCase(fetchRoadObjectListForIncidents.pending, (state) => {
            // @ts-ignore Снова выключаем кэширование
            http.defaults.cache = false;
            state.roadObjectFeatureCollection = null;
            state.isRoadObjectFeatureCollectionLoading = true;
        });
        builder.addCase(fetchRoadObjectListForIncidents.fulfilled, (state, {payload}) => {
            state.roadObjectFeatureCollection = payload;
            state.isRoadObjectFeatureCollectionLoading = false;
        });
        builder.addCase(fetchRoadObjectListForIncidents.rejected, (state) => {
            state.roadObjectFeatureCollection = null;
            state.isRoadObjectFeatureCollectionLoading = false;
        });
        // Фильтрованные дорожные объекты
        builder.addCase(fetchRoadObjectListForIncidentsFiltered.pending, (state) => {
            // @ts-ignore Снова выключаем кэширование
            http.defaults.cache = false;
            state.roadObjectFeatureCollectionFiltered = null;
            state.isRoadObjectFeatureCollectionFilteredLoading = true;
        });
        builder.addCase(fetchRoadObjectListForIncidentsFiltered.fulfilled, (state, {payload}) => {
            state.roadObjectFeatureCollectionFiltered = payload;
            state.isRoadObjectFeatureCollectionFilteredLoading = false;
        });
        builder.addCase(fetchRoadObjectListForIncidentsFiltered.rejected, (state) => {
            state.roadObjectFeatureCollectionFiltered = null;
            state.isRoadObjectFeatureCollectionFilteredLoading = false;
        });
        // Дорожные объекты транспортных средств
        builder.addCase(fetchRoadObjectListVehicle.pending, (state) => {
            state.roadObjectVehicleFeatureCollection = null;
            state.isRoadObjectFeatureCollectionFilteredLoading = true;
        });
        builder.addCase(fetchRoadObjectListVehicle.fulfilled, (state, {payload}) => {
            state.roadObjectVehicleFeatureCollection = payload;
            state.isRoadObjectFeatureCollectionFilteredLoading = false;
        });
        builder.addCase(fetchRoadObjectListVehicle.rejected, (state) => {
            state.roadObjectVehicleFeatureCollection = null;
            state.isRoadObjectFeatureCollectionFilteredLoading = false;
        });
        // Фильтрованные дорожные объекты для проТВ
        builder.addCase(fetchRoadObjectListForIncidentsProTV.pending, (state) => {
            state.roadObjectFeatureCollectionProTV = null;
            state.isRoadObjectFeatureCollectionFilteredLoading = true;
        });
        builder.addCase(fetchRoadObjectListForIncidentsProTV.fulfilled, (state, {payload}) => {
            state.roadObjectFeatureCollectionProTV = payload;
            state.isRoadObjectFeatureCollectionFilteredLoading = false;
        });
        builder.addCase(fetchRoadObjectListForIncidentsProTV.rejected, (state) => {
            state.roadObjectFeatureCollectionProTV = null;
            state.isRoadObjectFeatureCollectionFilteredLoading = false;
        });
        // Поиск дорожных объектов
        builder.addCase(searchRoadObjectListForIncidents.pending, (state) => {
            state.foundRoadObjects = {};
            state.foundRoadObjectsLoading = true;
        });
        builder.addCase(searchRoadObjectListForIncidents.fulfilled, (state, {payload}) => {
            state.foundRoadObjects = payload;
            state.foundRoadObjectsLoading = false;
        });
        builder.addCase(searchRoadObjectListForIncidents.rejected, (state) => {
            state.foundRoadObjectsLoading = false;
        });

        // Информация о камере
        builder.addCase(fetchRoadObjectCameraCapabilities.fulfilled, (state, action) => {
            const roadObjectId = action.payload.roadObject?.Id || action.meta.arg.roadObjectId;
            if (action.payload) {
                if (action.meta.arg.capabilityKey === "demoFavoriteIncidents") {
                    state.roadObjectsCameraCapabilities[action.meta.arg.capabilityKey] = {
                        [action.payload.roadObject?.Id as string]: action.payload,
                    };
                }
                state.roadObjectsCameraCapabilities[action.meta.arg.capabilityKey] = {
                    ...state.roadObjectsCameraCapabilities[action.meta.arg.capabilityKey],
                    [roadObjectId]: action.payload,
                };
            }
        });

        // Подробная информация о выбранной камере
        builder.addCase(fetchRoadObjectInfo.pending, (state) => {
            state.selectedCameraInfo = null;
            state.selectedCameraInfoLoading = true;
        });
        builder.addCase(fetchRoadObjectInfo.fulfilled, (state, {payload}) => {
            state.selectedCameraInfo = payload;
            state.selectedCameraInfoLoading = false;
        });
        builder.addCase(fetchRoadObjectInfo.rejected, (state) => {
            state.selectedCameraInfo = null;
            state.selectedCameraInfoLoading = false;
        });

        // Управление ptz камерой
        builder.addCase(moveRoadObjectsCameraPtz.pending, (state) => {
            state.isCameraPtzProcessing = true;
        });
        builder.addCase(moveRoadObjectsCameraPtz.fulfilled, (state) => {
            state.isCameraPtzProcessing = false;
        });
        builder.addCase(moveRoadObjectsCameraPtz.rejected, (state) => {
            state.isCameraPtzProcessing = false;
        });

        // Дорожные объекты на тепловой карте
        builder.addCase(fetchHeatmapRoadObjects.pending, (state, action) => {
            if (action.meta.arg.mode !== "auto") state.heatmapLoading = true;
        });
        builder.addCase(fetchHeatmapRoadObjects.fulfilled, (state, {payload}) => {
            // @ts-ignore
            state.heatmapRoadObjects = payload;
            state.heatmapLoading = false;
        });
        builder.addCase(fetchHeatmapRoadObjects.rejected, (state) => {
            state.heatmapLoading = false;
        });

        // Индикаторы для фильтра по времени на тепловой карте
        builder.addCase(fetchTimeIndicators.pending, (state) => {
            state.heatmapLoading = true;
        });
        builder.addCase(fetchTimeIndicators.fulfilled, (state, {payload}) => {
            // @ts-ignore
            state.timeIndicators = payload;
            state.heatmapLoading = false;
        });
        builder.addCase(fetchTimeIndicators.rejected, (state) => {
            state.heatmapLoading = false;
        });
    },
});

export default roadObject.reducer;

// Экшены
export const {
    setSelectedRoadObjectsForStream,
    setRoadObjectsCameraCapabilities,
    resetRoadObjectCameraInfo,
    setRoadObjectFeatureCoordsHashMap,
    setRoadObjectFeaturesHashMap,
    setRoadObjectsFeatureIndexes,
    setFilters,
    setIndicatorsFilters,
    updateHeatmapFilter,
    setInitQueryParams,
    setRoadObjectFilters,
    toggleZoneFilterRoadObject,
    updateRoadObjectsFiltered,
    setDashboardModalSelectedStream,
    setRoadObjectFeatureCollectionFiltered,
    setRoadObjectVehicleFeatureCollection,
    setRoadObjectVehicleFeaturesIndexes,
    updateRoadObjectVehicleFeaturesCollections,
} = roadObject.actions;

//Селекторы
const slice = ({roadObjectReducer}: RootState) => roadObjectReducer;

// Дорожные объекты (FeatureCollection)
export const roadObjectFeatureCollectionSelector = createSelector(
    slice,
    ({roadObjectFeatureCollection}) => roadObjectFeatureCollection
);

// Дорожные объекты с учетом фильтров (FeatureCollection)
export const roadObjectFeatureCollectionFilteredSelector = createSelector(
    slice,
    ({roadObjectFeatureCollectionFiltered}) => roadObjectFeatureCollectionFiltered
);

// Дорожные объекты с учетом фильтров (FeatureCollection) для проТВ
export const roadObjectFeatureCollectionProTVSelector = createSelector(
    slice,
    ({roadObjectFeatureCollectionProTV}) => roadObjectFeatureCollectionProTV
);

// Дорожные объекты (FeatureCollection) - линии
export const roadObjectLinesSelector = createSelector(
    roadObjectFeatureCollectionFilteredSelector,
    items => {
        const collection: any = {
            type: items?.type || "FeatureCollection",
            features: [],
        };

        if (items?.features) {
            for (let i = 0; i < items.features.length; i++) {
                if (items.features[i].geometry?.type === "LineString") collection.features.push({
                    ...items.features[i],
                    geometry: {
                        ...items.features[i].geometry,
                        coordinates: items.features[i].geometry?.coordinates.map((coordinates) => {
                            return proj4(
                                "EPSG:3857", "EPSG:4326",
                                coordinates as [number, number]
                            );
                        }),

                    },
                });
            }
        }

        return collection;
    }
);

// Дорожные объекты (Features)
export const roadObjectFeaturesSelector = createSelector(
    slice,
    ({roadObjectFeatureCollection}) => roadObjectFeatureCollection?.features || []
);

// Асоциативный массив со свойствами дорожных объектов
export const roadObjectFeaturesHashMapSelector = createSelector(
    slice,
    ({roadObjectFeaturesHashMap}) => roadObjectFeaturesHashMap
);

// Асоциативный массив координат дорожных объектов {coords: [feature, feature]}
// Features с одинаковыми координатами
export const roadObjectFeatureCoordsHashMapSelector = createSelector(
    slice,
    ({roadObjectFeatureCoordsHashMap}) => roadObjectFeatureCoordsHashMap
);

export const isRoadObjectFeatureCollectionLoadingSelector = createSelector(
    slice,
    ({isRoadObjectFeatureCollectionLoading}) => isRoadObjectFeatureCollectionLoading
);

export const isRoadObjectFeatureCollectionFilteredLoadingSelector = createSelector(
    slice,
    ({isRoadObjectFeatureCollectionFilteredLoading}) => isRoadObjectFeatureCollectionFilteredLoading
);

// Выбранные камеры для стримов
export const selectedRoadObjectsForStreamSelector = createSelector(
    slice,
    ({selectedRoadObjectsForStream}) => selectedRoadObjectsForStream
);

export const dashboardModalSelectedStreamSelector = createSelector(
    slice,
    ({dashboardModalSelectedStream}) => dashboardModalSelectedStream
);

// Дорожные объекты (FeatureCollection) - точки
export const roadObjectPointsSelector = createSelector(
    roadObjectFeatureCollectionFilteredSelector,
    items => {
        const collection: any = {
            type: items?.type || "FeatureCollection",
            features: [],
        };

        if (items?.features) {
            for (let i = 0; i < items.features.length; i++) {
                if (items.features[i].geometry?.type === "Point") collection.features.push({
                    ...items.features[i],
                    geometry: {
                        ...items.features[i].geometry,
                        coordinates: proj4(
                            "EPSG:3857", "EPSG:4326",
                            items.features[i].geometry?.coordinates as [number, number]
                        ),
                    },
                });
            }
        }

        return collection;
    }
);

// Дорожные объекты транспортных средств
export const roadObjectVehicleSelector = createSelector(
    slice,
    ({roadObjectVehicleFeatureCollection}) => {
        const collection: any = {
            type: roadObjectVehicleFeatureCollection?.type || "FeatureCollection",
            features: [],
        };

        if (roadObjectVehicleFeatureCollection?.features) {
            for (let i = 0; i < roadObjectVehicleFeatureCollection.features.length; i++) {
                // eslint-disable-next-line max-len
                if (roadObjectVehicleFeatureCollection.features[i].geometry?.type === "Point") collection.features.push({
                    ...roadObjectVehicleFeatureCollection.features[i],
                    geometry: {
                        ...roadObjectVehicleFeatureCollection.features[i].geometry,
                        coordinates: proj4(
                            "EPSG:3857", "EPSG:4326",
                            roadObjectVehicleFeatureCollection.features[i].geometry?.coordinates as [number, number]
                        ),
                    },
                });
            }
        }

        return collection;
    }
);

// Дорожные объекты (FeatureCollection) - точки для проТВ
export const roadObjectPointsProTVSelector = createSelector(
    roadObjectFeatureCollectionProTVSelector,
    items => {
        const collection: any = {
            type: items?.type || "FeatureCollection",
            features: [],
        };

        if (items?.features) {
            for (let i = 0; i < items.features.length; i++) {
                if (items.features[i].geometry?.type === "Point") collection.features.push({
                    ...items.features[i],
                    geometry: {
                        ...items.features[i].geometry,
                        coordinates: proj4(
                            "EPSG:3857", "EPSG:4326",
                            items.features[i].geometry?.coordinates as [number, number]
                        ),
                    },
                });
            }
        }

        return collection;
    }
);

// Дорожные объекты (FeatureCollection) - полигоны
export const roadObjectPolygonsSelector = createSelector(
    roadObjectFeatureCollectionSelector,
    items => {
        const collection: any = {
            type: items?.type || "FeatureCollection",
            features: [],
        };

        if (items?.features) {
            for (let i = 0; i < items.features.length; i++) {
                const feature = items.features[i];
                if (feature.geometry?.type === "Polygon") {
                    //@ts-ignore
                    const coordinates = feature.geometry?.coordinates[0]?.map((coords: [number, number]) => {
                        return proj4(
                            "EPSG:3857", "EPSG:4326",
                            coords
                        );
                    });

                    collection.features.push({
                        ...items.features[i],
                        geometry: {
                            ...items.features[i].geometry,
                            coordinates: [coordinates],
                        },
                    });
                }
            }
        }

        return collection;
    }
);

// Таблица индексов дорожных объектов по их id
export const roadObjectsFeatureIndexesSelector = createSelector(
    slice,
    ({roadObjectsFeatureIndexes}) => roadObjectsFeatureIndexes
);

export const isCameraPtzProcessingSelector = createSelector(
    slice,
    ({isCameraPtzProcessing}) => isCameraPtzProcessing
);

export const isAddingStreamModeSelector = createSelector(
    slice,
    ({isAddingStreamMode}) => isAddingStreamMode
);

export const roadObjectsCameraCapabilitiesSelector = createSelector(
    slice,
    ({roadObjectsCameraCapabilities}) => roadObjectsCameraCapabilities
);

/*export const roadObjectFeatureCoordsHashMapSelector = createSelector(
    slice,
    ({roadObjectFeatureCoordsHashMap}) => roadObjectFeatureCoordsHashMap
);*/

export const heatmapRoadObjectsSelector = createSelector(
    slice,
    ({heatmapRoadObjects}) => heatmapRoadObjects
);

export const heatmapLoadingSelector = createSelector(
    slice,
    ({heatmapLoading}) => heatmapLoading
);

// Фильтры на странице дашборда руководителя
export const filtersSelector = createSelector(
    slice,
    ({filters}) => filters,
);

export const timeIndicatorsSelector = createSelector(
    slice,
    ({timeIndicators}) => timeIndicators,
);

export const timeIndicatorsFiltersSelector = createSelector(
    slice,
    ({timeIndicatorsFilters}) => timeIndicatorsFilters,
);
export const selectedCameraInfoSelector = createSelector(
    slice,
    ({selectedCameraInfo}) => selectedCameraInfo
);

export const selectedCameraInfoLoadingSelector = createSelector(
    slice,
    ({selectedCameraInfoLoading}) => selectedCameraInfoLoading
);

export const foundRoadObjectsSelector = createSelector(
    slice,
    ({foundRoadObjects}) => foundRoadObjects
);

export const foundRoadObjectsLoadingSelector = createSelector(
    slice,
    ({foundRoadObjectsLoading}) => foundRoadObjectsLoading
);

// Фильтры карты на Главной
export const roadObjectsFiltersSelector = createSelector(
    slice,
    ({roadObjectsFilters}) => roadObjectsFilters
);

// Фильтры карты в карточке инцидента
export const archiveIncidentRoadObjectsFiltersSelector = createSelector(
    slice,
    ({archiveIncidentRoadObjectsFilters}) => archiveIncidentRoadObjectsFilters
);

// Фильтры карты в настройках
export const settingsRoadObjectsFiltersSelector = createSelector(
    slice,
    ({settingsRoadObjectsFilters}) => settingsRoadObjectsFilters
);

// Фильтры карты на Дашборде рук-ва
export const demonstrationRoadObjectsFiltersSelector = createSelector(
    slice,
    ({demonstrationRoadObjectsFilters}) => demonstrationRoadObjectsFilters
);

// Фильтры карты на Втором экране оператора
export const secondScreenRoadObjectsFiltersSelector = createSelector(
    slice,
    ({secondScreenRoadObjectsFilters}) => secondScreenRoadObjectsFilters
);
