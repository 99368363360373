import React, {FC} from "react";

import Image from "components/Icons/Image";

import Logo from "../../images/logo.svg";

import styles from "./Header.module.scss";

const HeaderAuthorization: FC = () => {
    return (
        <header className={styles["header"]}>
            <div className={styles["header__logo"]}>
                <Image
                    src={Logo}
                    alt="Logo"
                />
            </div>
        </header>
    );
};

export default React.memo(HeaderAuthorization);