import React, {FC, useEffect, useMemo, useState} from "react";
import {useForm} from "react-hook-form";
import cn from "classnames";
import {useDispatch} from "react-redux";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

import StringObjectInfoModel from "../../../../models/StringObjectInfoModel";
import {TypedDispatch} from "../../../../types";

import TypeIcon from "../../../Icons/TypeIcon";
import BaseButton from "../../../UI/Button/BaseButton";
import Checkbox from "../../../UI/Checkbox";
import ArrowRightSmallIcon from "../../../Icons/ArrowRightSmallIcon";
import TrashIconFilled from "../../../Icons/TrashIconFilled";

import modalStyles from "../../../ArchiveIncidentPage/IncidentInfo/IncidentInfoModals/IncidentInfoModals.module.scss";
import styles from "./AssignTagGroupForm.module.scss";

interface IAssignGroupFormProps {
    incidentTypes: StringObjectInfoModel[],
    setAssignGroupId: any,
    groups: any,
    activeGroup: any,
    editGroup: any,
}

function splitArrayInHalf(array: StringObjectInfoModel[]) {
    const midPoint = Math.ceil(array.length / 2);
    const firstHalf = array.slice(0, midPoint);
    const secondHalf = array.slice(midPoint);
    return [firstHalf, secondHalf];
}

function arraysEqual(arr1: string[], arr2: string[]) {
    if (arr1.length !== arr2.length) return false;

    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false;
    }

    return true;
}

function arraysEqualUnordered(arr1: string[] = [], arr2: string[] = []) {
    const sortedArr1 = [...arr1].sort();
    const sortedArr2 = [...arr2].sort();
    return arraysEqual(sortedArr1, sortedArr2);
}

const AssignGroupForm:FC<IAssignGroupFormProps> = ({incidentTypes, setAssignGroupId, groups, 
    activeGroup, editGroup}) => {
    const dispatch = useDispatch<TypedDispatch>();

    const currentGroup = groups.find((group: any) => group.Id === activeGroup);
    const defaultValues = currentGroup?.Data?.view?.incidentTypes?.map((item: any) => item.code);
    const [clearVisible, setClearVisible] = useState<boolean>(!!defaultValues?.length);
    const [defaultState, setDefaultState] = useState(false);
    // Деление массива на две части для корректного отображения типов инцидентов по 2 столбцам
    const [firstHalf, secondHalf] = splitArrayInHalf(incidentTypes);

    const {handleSubmit, reset, formState: {isDirty}, register, watch, getValues, setValue} = useForm({mode: "onChange",
        defaultValues: useMemo(() => {
            const values: any = {};
            defaultValues?.forEach((value: any) => {
                values[`${value}`] = true;
            });
            return values;
        }, [defaultValues])});

    React.useEffect(() => {
        const subscription = watch((value) => {
            setClearVisible(Object.values(value).includes(true));
            const trueKeys = Object.keys(value).filter(key => value[key] === true);
            if (arraysEqualUnordered(trueKeys, defaultValues)) {
                setDefaultState(true);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        if (defaultState) {
            const values: any = {};
            defaultValues?.forEach((value: any) => {
                values[`${value}`] = true;
            });
            reset({ ...values });
            setDefaultState(false);
        }
    }, [defaultState]);

    const handleClose = () => {
        setAssignGroupId(null);
    };

    const getTruthyKeys = (obj: any) => {
        const keys = Object.keys(obj);
        return keys.filter(key => obj[key]);
    };

    const filterObjectsByValues = (objectsArray: any, filterValues: string[], key: string) => {
        return objectsArray.filter((object: any) => filterValues.includes(object[key]));
    };

    const onSubmit = (data: any) => {
        // получаем массив выбранных значений
        const truthyKeys = getTruthyKeys(data);
        // получаем выбранные типы
        const filteredObjects = filterObjectsByValues(incidentTypes, truthyKeys, "code");

        const preparedData = {
            data: {...currentGroup.data, view: {...currentGroup.data?.view, incidentTypes: filteredObjects}},
            name: currentGroup.Name,
            typeId: currentGroup.Type,
        };
        dispatch(editGroup({id: currentGroup.Id, data: preparedData}));
    };

    return (
        <form className={styles["form"]} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles["actions"]}>
                <div
                    onClick={handleClose}
                    className={styles["actions__cancel"]}
                ><ArrowRightSmallIcon width={22} height={22}/> Выберите тип инцидентов для привязки</div>
                <BaseButton
                    disabled={!isDirty}
                    tag={"button"}
                    className={cn(modalStyles["actions__btn"], styles["actions__submit"], "btn--primary", "btn--md")}
                    type={"submit"}
                >
                    Сохранить
                </BaseButton>
            </div>
            <div className={styles["wrapper"]}>
                <div className={styles["form__title"]}>{currentGroup?.Name}</div>
                {clearVisible && <div
                    onClick={() => {
                        reset({}, {keepDirty: true});
                        if (defaultValues) {
                            setValue(`${defaultValues[0]}`, getValues(`${defaultValues[0]}`), { shouldDirty: true });
                        }
                    }}
                    className={styles["actions__clear"]}
                > <TrashIconFilled width={12} height={12}/> Очистить все</div>}
            </div>
            <SimpleBar style={{maxHeight: "475px", height: "475px", paddingRight: "20px"}}>
                <div className={styles["types"]}>
                    <div>
                        {firstHalf.map(type => (
                            <Checkbox
                                {...register(`${type.code}`)}
                                name={`${type.code}`}
                                key={type.code}
                                bgColor="#464963"
                                bgCheckedColor="var(--color-primary)"
                                className="mb-2"
                            >
                                <div className={styles["types__item"]}>
                                    <TypeIcon classname={styles["types__item-icon"]} type={type} />
                                    <span className={styles["types__item-name"]}>{type.name}</span>
                                </div>
                            </Checkbox>
                        ))}
                    </div>
                    <div>
                        {secondHalf.map(type => (
                            <Checkbox
                                {...register(`${type.code}`)}
                                name={`${type.code}`}
                                key={type.code}
                                bgColor="#464963"
                                bgCheckedColor="var(--color-primary)"
                                className="mb-2"
                            >
                                <div className={styles["types__item"]}>
                                    <TypeIcon classname={styles["types__item-icon"]} type={type}/>
                                    <span className={styles["types__item-name"]}>{type.name}</span>
                                </div>
                            </Checkbox>
                        ))}
                    </div>
                </div>
            </SimpleBar>
        </form>
    );
};

export default AssignGroupForm;