import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";

import {RootState} from "../store";
import createHttpRequest from "../utils/http";
import {ApiUrls} from "../constants/urls";

export interface ICreateRepairRequest {
    "Subject"?: string,
    "Status": "New" | "Processing" | "Resolved" | "Rejected",
    "Description"?: string,
    "OrganizationId": number,
    "TicketTypeId": number,
    "RoadObjectId": number
}

export interface IInitialState {
    heatmapVisible: boolean,
    isNotifyService: boolean,
    isCreateRepairLoading: boolean,
    isCreateRepairSuccess: boolean,
    isCreateRepairError: boolean,
}

const initialState: IInitialState = {
    heatmapVisible: false,
    isNotifyService: false,
    isCreateRepairLoading: false,
    isCreateRepairSuccess: false,
    isCreateRepairError: false,
};

// Создать заявку на ремонт
export const createRepairRequest = createAsyncThunk<void, ICreateRepairRequest>("event/createRepairRequest",
    async(data) => {
        const response = await createHttpRequest({
            method: "POST",
            path: ApiUrls.SERVICE_TICKET(),
            data,
            errorMessage: "messages:create_repair_request",
        });

        return response.data;
    });


const dashboard = createSlice({
    reducers: {
        toggleHeatmap(state, {payload}) {
            state.heatmapVisible = payload;
        },

        setIsNotifyService(state, {payload}) {
            state.isNotifyService = payload;
        },
    },
    name: "dashboard",
    initialState,
    extraReducers: (builder) => {
        // Заявка на ремонт
        builder.addCase(createRepairRequest.pending, (state) => {
            state.isCreateRepairError = false;
            state.isCreateRepairSuccess = false;
            state.isCreateRepairLoading = true;
        });
        builder.addCase(createRepairRequest.fulfilled, (state) => {
            state.isCreateRepairSuccess = true;
            state.isCreateRepairLoading = false;
        });
        builder.addCase(createRepairRequest.rejected, (state) => {
            state.isCreateRepairError = true;
            state.isCreateRepairLoading = false;
        });
    },
});


export default dashboard.reducer;

// Экшены
export const {
    toggleHeatmap,
    setIsNotifyService,
} = dashboard.actions;

// Селекторы
const slice = ({dashboardReducer}: RootState) => dashboardReducer;

export const heatmapVisibleSelector = createSelector(
    slice,
    ({heatmapVisible}) =>  heatmapVisible
);

export const isNotifyServiceSelector = createSelector(
    slice,
    ({isNotifyService}) =>  isNotifyService
);
export const isCreateRepairLoadingSelector = createSelector(
    slice,
    ({isCreateRepairLoading}) =>  isCreateRepairLoading
);

export const isCreateRepairSuccessSelector = createSelector(
    slice,
    ({isCreateRepairSuccess}) =>  isCreateRepairSuccess
);