import {mediaType} from "../types";

/* eslint-disable max-len */
export const ApiUrls = {
    TOKEN: "/Account/Token",
    REFRESH_TOKEN: "/Account/RefreshToken",
    LOGOUT: "/Account/Logout",
    USER_SETTINGS: "/user-settings",
    SETTINGS: "/settings",
    USER_SETTINGS_VIEW: "/user-settings/frontend-properties",
    INCIDENT_TYPES: "/incidents/incident-types",
    ADMIN_INCIDENT_TYPES: "/Admin/incident-types",
    ADMIN_INCIDENT_TYPE: (code: number) => `/Admin/incident-types/${code}`,
    VIOLATIONS: "/incidents/violation",
    RESPONSIBILITY_TYPES: "/responsibility-types",
    RESPONSIBILITY_ZONES: "/responsibility-zones",
    RESPONSE_SERVICES: "/response-services",
    BUS_STOPS: "/publicTransport/stop",
    ROUTES: "/publicTransport/route",
    LAST_EVENTS: "/incidents/events/last",
    SUMMARY_EVENTS: "/incidents/events/summary",
    EVENTS: "/incidents/events",
    INDICATORS: (zoneIds?: string[]) => `/incidents/events/indicators${zoneIds?.length ? "?ZoneIds=" + zoneIds : ""}`,
    INDENTS_ON_CONTROL: "/Incidents/events/tracked",
    INDENTS_FAVORITE: "/Incidents/events/favorite",
    EVENT: (id: string, includeHiddenEvents?:boolean) =>
        (`/incidents/events/${id}${includeHiddenEvents ? "?includeHiddenEvents=true" : ""}`),
    UPDATE_ADDITIONAL_INFO: (incidentId:string) => `/Incidents/events/${incidentId}/data`,
    EVENT_REOPEN: (id: string) => `/Incidents/events/${id}/reopen`,
    EVENT_UPDATE_INCIDENT_NAME: (id: string) =>  `/incidents/events/${id}/name`,
    EVENT_UPDATE_INCIDENT_LOCATION: (id: string) =>  `/Incidents/events/${id}/location`,
    EVENT_ASSIGNMENT: (id: string) => `/incidents/events/${id}/assignment`,
    EVENT_REJECTION: (id: string) => `/incidents/events/${id}/rejection`,
    EVENT_FINALIZATION: (id: string) => `/Incidents/events/${id}/finalization`,
    REMOVE_FAVORITE: (id: string) => `/Incidents/events/${id}/commands/remove-favorite`,
    SET_FAVORITE: (id: string) => `/Incidents/events/${id}/commands/set-favorite`,
    EVENT_HIDE: (id: string) => `/Incidents/events/${id}/commands/hide`,
    EVENT_UNHIDE: (id: string) => `/Incidents/events/${id}/commands/unhide`,
    EVENT_ADD_CAMERA: (id: string) => `/Incidents/events/${id}/commands/add-camera`,
    EVENT_REMOVE_CAMERA: (id: string) => `/Incidents/events/${id}/commands/remove-camera`,
    EVENT_COMMENTS: (id: string, showDeleted?: boolean) => `/incidents/events/${id}/comments` + (showDeleted ? "?ShowDeleted=true" : ""),
    EVENT_COMMENTS_ADD: (id: string,  typeComments: string, showDeleted?: boolean) => `/incidents/events/${id}/comments/${typeComments}` + (showDeleted ? "?ShowDeleted=true" : ""),
    EVENT_COMMENTS_MEDIA: (incidentId: string) => `/Incidents/events/${incidentId}/comments/withmediatype`,
    EVENT_COMMENT_MEDIA_FILE: (incidentId: string, fileId: string) => `/Incidents/events/${incidentId}/attachments/${fileId}`,
    EVENT_NEW_COMMENT_ATTACHMENTS: (incidentId: string) => `/Incidents/events/${incidentId}/attachments`,
    EVENT_COMMENT_ATTACHMENTS: (incidentId: string, commentId: string) => `/Incidents/events/${incidentId}/comments/${commentId}/attachments`,
    COMMENT_ATTACHMENTS_TO_MEDIA: (incidentId: string) => `/Incidents/events/${incidentId}/attachments/copy-to-media`,
    EVENT_RESTORE_COMMENT: (incidentId: string, commentId: string) =>`Incidents/events/${incidentId}/comments/operator/${commentId}/restore`,
    EVENT_DELETE_COMMENT: (commentId: string, incidentId: string, typeComments: string, showDeleted?: boolean) => (
        `/Incidents/events/${incidentId}/comments/${typeComments}/${commentId}` + (showDeleted ? "?returnDeleted=true" : "")
    ),
    EVENT_UPDATE_COMMENT: (incidentId: string, typeComments: string, commentId: string) => `/Incidents/events/${incidentId}/comments/${typeComments}/${commentId}`,
    EVENT_COMMENT_NOTIFY_RESPONSE_SERVICE: (incidentId: string, commentId: string,) => `/Incidents/events/${incidentId}/comments/${commentId}/notify-response-service`,
    LAST_FIXATIONS: "/incidents/fixations/last",
    FIXATIONS: "/incidents/fixations",
    FIXATIONS_DOWNLOAD: "/incidents/fixations/download",
    EVENTS_DOWNLOAD: "/incidents/events/download",
    FIXATION: (id: string) => `/incidents/fixations/${id}`,
    ROAD_OBJECT_LIST_FOR_INCIDENTS: "/RoadObject-List-ForIncidents",
    HEATMAP_ROAD_OBJECTS: "/Incidents/events/heatMap",
    INCIDENTS_MEDIA_THUMB: (type: mediaType, incidentId?: string|null, mediaId?: string|null) => type === "incident"
        ? `/incidents/media/${incidentId}/thumb/${mediaId}`
        : `/incidents/${type}-media-thumb?${type}Id=${incidentId}&mediaId=${mediaId}`,
    INCIDENT_MEDIA: (type: mediaType, incidentId?: string|null, mediaId?: string|null, fullPath = false) => type === "incident"
        ? `/Incidents/media/${incidentId}/full/${mediaId}`
        : `${fullPath ? process.env.REACT_APP_BASE_API_URL : ""}/Incidents/${type}-media?${type}Id=${incidentId}&mediaId=${mediaId}`,
    USER_AVATAR: (id: number) => `/User/${id}/AvatarImage`,
    INCIDENT_TYPE_ICON: (type: string) => `/incidents/incident-types/${type}/icon`,
    SET_AGGREGATOR: (incidentId: string) => `/Incidents/events/${incidentId}/commands/set-aggregator`,
    REMOVE_AGGREGATOR: (incidentId: string) => `/Incidents/events/${incidentId}/commands/remove-aggregator`,
    TO_INCIDENT: (incidentId: string) => `/Incidents/events/${incidentId}/commands/to-incident`,
    SET_TRACKED: (incidentId: string) => `/Incidents/events/${incidentId}/commands/set-tracked`,
    REMOVE_TRACKED: (incidentId: string) => `/Incidents/events/${incidentId}/commands/remove-tracked`,
    ADD_MEDIA: (incidentId: string) => `/Incidents/events/${incidentId}/add-media`,
    REMOVE_MEDIA: (incidentId: string) => `/Incidents/events/${incidentId}/remove-media`,
    SET_MASTER_REPRESENTATION_EVENT: (incidentId?: string|null, repIncidentId?: string|null) => {
        if (repIncidentId) {
            return `Incidents/events/${incidentId}/commands/set-master-representation-event?representationIncidentId=${repIncidentId}`;
        } else {
            return `Incidents/events/${incidentId}/commands/set-master-representation-event`;
        }
    },
    GET_ROAD_OBJECT_CAMERA_CAPABILITIES: (roadObjectId: number) => `/RoadObjects/GetCameraCapabilities?roadObjectId=${roadObjectId}`,
    ROAD_OBJECTS_CAMERA_PTZ: (roadObjectId: number) => `/RoadObjects/CameraPtz?roadObjectId=${roadObjectId}`,
    NEARBY_INCIDENTS: (incidentId: string) => `/Incidents/events/${incidentId}/nearby-incidents`,
    ENVIRONMENT: "/incidents/environments/environment.json",
    JOURNAL: "/Incidents/events/comments",
    EFFICIENCY_JOURNAL: "Incidents/roadobjects",
    GET_ROAD_OBJECT_TYPES: (id?: number) => "/RoadObjects/GetRoadObjectTypes" + (id ? (`?objectSourceId=${id}`) : ""),
    GET_ROLES: "/Incidents/roles",
    GET_USERS: "/Incidents/users",
    GET_OPERATORS: (roleIds: number, zoneIds?: number | string) => {
        if (roleIds) {
            return `/Incidents/users?roleIds=${roleIds}${zoneIds ? "&zoneIds=" + zoneIds : ""}&pageSize=999`;
        } else {
            return `/Incidents/users?${zoneIds ? "zoneIds=" + zoneIds : ""}&pageSize=999`;
        }
    },
    CREATE_USER: "/Incidents/users/add",
    SESSION_INFO_DOWNLOAD: (id?: number) => `/Incidents/users/${id}/sessionInfo/download`,
    GET_USER: (id: string) => `/Incidents/users/${id}`,
    EDIT_USER: (id: number) => `/Incidents/users/${id}/update`,
    DELETE_USER: (id: string) => `/Incidents/users/${id}/delete`,
    UPDATE_USER_PASSWORD: (id: string) => `/Incidents/users/${id}/updatePassword`,
    GET_USER_SESSIONS: (id: string, count: number) => `/Incidents/users/${id}/sessionInfo?pageSize=${count}`,
    GET_USER_INDICATORS: (id: string, dateUnit: string) => `/Incidents/users/${id}/indicators?dateUnit=${dateUnit}`,
    GET_USER_COUNT_INCIDENTS: (id: string, dateUnit: string) => `/Incidents/users/${id}/countIncidents?dateUnit=${dateUnit}`,
    SELECTED_CAMERA_INFO: (roadObjectId: number) => `/Incidents/roadobject?roadObjectId=${roadObjectId}`,
    ROAD_OBJECT_INFO: (roadObjectId: number) => `/Incidents/roadobject?roadObjectId=${roadObjectId}`,
    TIME_INDICATORS: (days: string | null, time: string, onlyWithNested: boolean) => `/Incidents/events/indicators/mini?date.days=${days}&date.time=${time}&groupByRangeOfDays=true&onlyWithNested=${onlyWithNested}`,
    SERVICE_TICKET: (RoadObjects?: number[]) => "/Service/ticket" + (RoadObjects?.length ? `?RoadObjects=${RoadObjects.join(",")}` : ""),
    SERVICE_TICKET_DOWNLOAD: (RoadObjects?: number[]) => "/Service/ticket/download" + (RoadObjects?.length ? `?RoadObjects=${RoadObjects.join(",")}` : ""),
    CREATE_INCIDENT: "/Incidents/incident",
    ADDRESS_AUTOCOMPLETE: (searchText: string) => `/nom/search.php?q=${searchText}&polygon_geojson=1&format=jsonv2`,
    ORGANIZATIONS: "/Admin/organization",
    ORGANIZATION: (id: string | number) => `/Admin/organization/${id}`,
    EVENT_DELETE_ATTACHMENT: (incidentId: string, fileId: string) => `/Incidents/events/${incidentId}/attachments/${fileId}`,
    ROAD_OBJECT_ADD: (name: string, roadObjectTypeId: number) => `/Admin/RoadObjectAdd?name=${name}&roadObjectTypeId=${roadObjectTypeId}`,
    ROAD_OBJECT_UPDATE: "/RoadObject/Update",
    ROAD_OBJECT_ADMIN_DELETE: (id: number, objectSourceId: number) => `/Admin/RoadObjectDelete?id=${id}&objectSourceId=${objectSourceId}`,
    ROAD_OBJECT_ADMIN_UPDATE: "/Admin/RoadObjectUpdate",
    ROAD_OBJECT_ADMIN_UPDATE_PROPERTIES: "/Admin/forms/road_object/additional_properties",
    ADMIN_RESPONSIBILITY_ZONES: "/Admin/responsibility-zones",
    ADMIN_RESPONSIBILITY_ZONE: (id: number) => `/Admin/responsibility-zones/${id}`,
    RESPONSIBILITY_ZONES_ROAD_OBJECTS_ATTACHING: (id: number) => `/Admin/responsibility-zones/${id}/road-objects/attaching`,
    UPDATE_RESPONSIBILITY_ZONES: (mode?: string) => `/Incidents/users/updateResponsibilityZones?mode=${mode ?? ""}`,
    RESPONSIBILITY_ZONE_UPDATE_USERS: (zoneId: number) => `/Incidents/responsibility-zone/${zoneId}/update-users`,
    RESPONSIBILITY_ZONES_UPDATE_PRIORITIES: (zoneIds: number[]) => "/Incidents/responsibility-zones/update-priorities" + (zoneIds?.length ? `?ids=${zoneIds.join(",")}` : ""),
    INCIDENT_DICTIONARIES: (type?: string) => `/incidents/dictionaries?Type=${type ?? ""}`,
    INCIDENT_DICTIONARY: (id: number) => `/incidents/dictionaries/${id}`,
    TAGS: () => "/incidents/tags",
    TAG: (id: number) => `/incidents/tags/${id}`,
    UPDATE_INCIDENT_TAGS: (id: number) => `/incidents/events/${id}/tags`,
};

export const AppUrls = {
    HOME: "/",
    AUTH: "/auth",
    FORBIDDEN: "/forbidden",
    DASHBOARD: "/dashboard",
    DASHBOARD_SCHEME: "/dashboard/scheme",
    DEMONSTRATION: "/demonstration",
    EVENT_ARCHIVE: "/event-archive",
    EVENT: "/event-archive/:eventId",
    JOURNAL: "/journal",
    EFFICIENCY_JOURNAL: "/efficiency-journal",
    EFFICIENCY_JOURNAL_CAMERA_PAGE: "/efficiency-journal/:cameraId",
    EFFICIENCY_TICKETS: "/efficiency-tickets",
    SUPERADMIN: "/superadmin",
    OPERATOR_CARD: "/superadmin/operator/:operatorId",
    FIXATIONS_ARCHIVE: "/fixations-archive",
    FIXATION: "/fixations-archive/:fixationId",
    INCIDENTS_ON_CONTROL: "/incidents-on-control",
    SETTINGS_VIEW: "/settings/view",
    SETTINGS_ACCOUNTS: "/settings/accounts",
    SETTINGS_ACCOUNT: "/settings/accounts/:operatorId",
    SETTINGS_ZONES: "/settings/zones",
    SETTINGS_SYSTEM: "/settings/system",
    VIDEO_ARCHIVE: (roadObjectId: number|null) => `${process.env.REACT_APP_BASE_HOST}/video-archive?id=${roadObjectId ?? 0}&useToken=true`,
};

export const mapThemeUrls = {
    light: `${process.env.PUBLIC_URL}/style-light.json${window.location.port ? "?:" + window.location.port : ""}`,
    dark: `${process.env.PUBLIC_URL}/style-dark.json${window.location.port ? "?:" + window.location.port : ""}`,
};