import React, {useState, useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import {eventSelector} from "../../../redux/eventSlice";
import styles from "../Header.module.scss";
import TimerHeaderIcon from "../../Icons/TimerHeaderIcon";
import {IncidentEventStatus} from "../../../models/IncidentEventStatus";

const HeaderTimer = () => {
    const event = useSelector(eventSelector);

    const isIncidentProcessing = event?.status === IncidentEventStatus.Processing; // принят (в обработке)
    const isIncidentResolved = event?.status === IncidentEventStatus.Resolved; // закрыт (обработан)

    const regDate = event?.registrationDateTime ? new Date(event.registrationDateTime) : null;
    const openDate = event?.incidentOpenDateTime ? new Date(event.incidentOpenDateTime) : null;
    const closeDate = event?.incidentCloseDateTime ? new Date(event.incidentCloseDateTime) : null;

    const calcDifference = (startDate: Date | null, endDate: Date | null): number | null => {
        if (startDate && endDate) {
            const dateDifference = endDate.getTime() - startDate.getTime();
            return dateDifference;
        }
        return null;
    };

    const initialDifference = calcDifference(regDate, openDate);
    const resolvedDifference = calcDifference(regDate, closeDate);

    const [timeDifference, setTimeDifference] = useState<number>(() => {
        if (isIncidentProcessing && openDate && initialDifference !== null) {
            return Date.now() - openDate.getTime() + initialDifference;
        }
        return initialDifference ?? 0;
    });

    const startTimeRef = useRef<number>(Date.now());

    useEffect(() => {
        if (initialDifference === null || isIncidentResolved || (isIncidentProcessing && closeDate)) return;

        if (isIncidentProcessing && openDate && !closeDate) {
            startTimeRef.current = Date.now() - (Date.now() - openDate.getTime() + initialDifference);
        }

        const interval = setInterval(() => {
            setTimeDifference(Date.now() - startTimeRef.current);
        }, 1000);

        return () => clearInterval(interval);
    }, [initialDifference, isIncidentResolved, isIncidentProcessing, closeDate, openDate]);

    const formatTime = (milliseconds: number | null): string => {
        if (milliseconds === null) return "Нет данных";
        const hours = Math.floor(milliseconds / (1000 * 60 * 60));
        const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);
        // eslint-disable-next-line max-len
        return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    };

    const shouldDisplayResolvedTime = isIncidentResolved || (isIncidentProcessing && closeDate);

    const formattedDifference = shouldDisplayResolvedTime
        ? formatTime(resolvedDifference)
        : formatTime(timeDifference);

    return (
        <div className={styles["header__timer"]}>
            <TimerHeaderIcon/>
            {(isIncidentProcessing || isIncidentResolved) && formattedDifference}
        </div>
    );
};

export default React.memo(HeaderTimer);
