import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";

import createHttpRequest from "../../utils/http";
import ObjectWithUrl from "../../models/ObjectWithUrl";

import Image, {IImageProps, ImgElementStyle, StaticImageData} from "components/Icons/Image";
import Spinner from "../UI/Spinner/Spinner";

interface IImageWithTokenProps extends IImageProps {
    fallbackSrc?: string | StaticImageData;
    src: string,
    withLoader?: boolean,
    centerMode?: boolean,
    caching?: boolean,
    // eslint-disable-next-line no-unused-vars
    onClick?: (e: React.MouseEvent) => void,
    objectFit?: ImgElementStyle["objectFit"],
}

const ImageWithToken = forwardRef<ObjectWithUrl, IImageWithTokenProps>((props, ref) => {
    const {src, alt, fallbackSrc, withLoader = false, centerMode, caching = false, objectFit, ...rest} = props;

    // Получение данных из sessionStorage
    const getSessionStorageValue = (src: string) => {
        return sessionStorage.getItem(src);
    };

    // Запись данных в sessionStorage в виде base64
    const setSessionStorageValue = (src: string, blob: string | ArrayBuffer | null) => {
        return sessionStorage.setItem(src, String(blob));
    };

    const [url, setUrl] = useState<string | StaticImageData>();
    const [isFetching, setIsFetching] = useState(true);

    // Отмена запроса при unmount
    const controller = useRef(new AbortController()).current;
    const simulation = React.useRef(false);
    useEffect(() => {
        if (!simulation.current) {
            simulation.current = true;
        }
        Promise.resolve().then(() => (simulation.current = false));
        return () => {
            if (!simulation.current) {
                controller.abort();
            }
        };
    }, []);

    useEffect(() => {
        setIsFetching(true);
        const isCached = !!getSessionStorageValue(src);
        // Если уже есть в кэше
        if (src) {
            if (isCached) {
                setUrl(getSessionStorageValue(src) || fallbackSrc);
            } else {
                setTimeout(() => {
                    createHttpRequest({
                        method: "GET",
                        path: src,
                        responseType: "blob",
                        silent: true,
                        signal: controller.signal,
                    }).then(response => {
                        const imageUrl = URL.createObjectURL(response.data);

                        setUrl(imageUrl);
                        setIsFetching(false);
                        // Кэшировоание по требованию (blob -> base64)
                        if (caching && window.FileReader) {
                            const reader = new window.FileReader();
                            reader.readAsDataURL(response.data);
                            reader.onload = () => {
                                const imageDataUrl = reader.result;
                                setSessionStorageValue(src, imageDataUrl);
                            };
                        }
                    }).catch(() => {
                        setUrl(fallbackSrc);
                        setIsFetching(false);
                    });
                }, 0);
            }
        }
    }, [fallbackSrc, src]);

    useImperativeHandle(ref, () => ({
        getUrl: () => {
            return typeof url === "string" ? url : (url?.src ?? "");
        },
    }));

    return (
        (isFetching && withLoader)
            ? <Spinner
                size={32}
                centerMode={centerMode}
            />
            : url
                ? <Image
                    {...rest}
                    src={url}
                    alt={alt}
                    objectFit={objectFit}
                />
                : <></>
    );
});

ImageWithToken.displayName = "ImageWithToken";

export default React.memo(ImageWithToken);