/* eslint-disable max-len */
import React, {FC} from "react";

interface IEditIconProps {
    onClick?: any,
}

const EditIconFilled:FC<IEditIconProps> = ({onClick}) => {
    return (
        <svg onClick={onClick} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.82867 11.998H2V9.16935L9.62333 1.54602C9.74835 1.42104 9.91789 1.35083 10.0947 1.35083C10.2714 1.35083 10.441 1.42104 10.566 1.54602L12.452 3.43135C12.514 3.49327 12.5632 3.5668 12.5967 3.64773C12.6303 3.72866 12.6475 3.81541 12.6475 3.90302C12.6475 3.99063 12.6303 4.07738 12.5967 4.15831C12.5632 4.23925 12.514 4.31277 12.452 4.37469L4.82867 11.998ZM2 13.3314H14V14.6647H2V13.3314Z"
                fill="currentColor"/>
        </svg>
    );
};

export default EditIconFilled;