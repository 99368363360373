export default class Queue {

    private _queue: any[];

    constructor() {
        this._queue = [];
    }

    set(value: any) {
        this._queue.push(value);
        return this;
    }

    get() {
        return this._queue.shift();
    }

    size() {
        return this._queue.length;
    }

    empty() {
        this._queue = [];
    }

    forEach(callback: any) {
        this._queue.forEach(callback);
    }
}