/* eslint-disable max-len */
import React from "react";

import {ISvgProps} from "../../types/common";

const TimerHeaderIcon: React.FC<ISvgProps> = (
    {
        width = 16,
        height = 16,
    }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                d="M8.66699 4.66797V2.03792C11.667 2.36955 14.0003 4.91293 14.0003 8.0013C14.0003 11.315 11.314 14.0013 8.00033 14.0013C4.68662 14.0013 2.00033 11.315 2.00033 8.0013C2.00033 6.38382 2.64243 4.86975 3.76566 3.75071L2.82461 2.80614C1.45275 4.17289 0.666992 6.02569 0.666992 8.0013C0.666992 12.0514 3.95024 15.3346 8.00033 15.3346C12.0504 15.3346 15.3337 12.0514 15.3337 8.0013C15.3337 3.95121 12.0504 0.667969 8.00033 0.667969H7.33366V4.66797H8.66699ZM8.00033 6.66797C7.88089 6.66797 7.76511 6.68367 7.65496 6.71313L5.1384 4.19656L4.19559 5.13937L6.71215 7.65594C6.6827 7.76609 6.66699 7.88186 6.66699 8.0013C6.66699 8.73768 7.26395 9.33463 8.00033 9.33463C8.7367 9.33463 9.33366 8.73768 9.33366 8.0013C9.33366 7.26492 8.7367 6.66797 8.00033 6.66797Z"
                fill="#797EA0"/>
        </svg>
    );
};

export default TimerHeaderIcon;