/* eslint-disable max-len */
import React from "react";

import {ISvgProps} from "../../types/common";

// import icon from "../../images/icons/header-sidebar-arch.svg";

const HeaderSidebarArch: React.FC<ISvgProps> = (
    {
        width = 6,
        height = 6,
        className,
    }) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_b_9483_113573)">
                <path d="M6 0H0V6C0 2.68629 2.68629 0 6 0Z" fill="currentColor" />
            </g>
            <defs>
                <filter id="filter0_b_9483_113573" x="-22" y="-22" width="50" height="50" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="11"/>
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_9483_113573"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_9483_113573" result="shape"/>
                </filter>
            </defs>
        </svg>
    );
};

export default HeaderSidebarArch;