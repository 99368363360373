import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import createHttpRequest from "../utils/http";
import {ApiUrls} from "../constants/urls";
import {RootState} from "../store";
import JournalInfoModel from "../models/JournalInfoModel";
import JournalDataModelPagedList from "../models/JournalDataModelPagedList";
import {buildQuery} from "../utils/query";
import {DEFAULT_EVENTS_SORT, DEFAULT_PAGE_SIZE} from "../constants/common";
import {getFormattedDate} from "../utils/datetime";
import Pagination from "../models/Pagination";
import {IncidentEventStatus} from "../models/IncidentEventStatus";
import {IEventsFilters} from "./eventSlice";

export interface IJournalFilters {
    eventPriority: number[], //приоритет [0, 1, 2]
    status: IncidentEventStatus[], //статус
    sinceDay: string | null, //дата от
    untilDay: string | null, //дата до
    dateKind: string | null,
}

interface IJournalSort {
    field: "timeCreate" | "status",
    direction: "desc" | "asc"
}

export interface IInitialState {
    journalData: JournalInfoModel[],
    loading: boolean,
    pagination: Pagination,
    filters: IJournalFilters,
}

const initialState: IInitialState = {
    journalData: [],
    loading: false,
    pagination: {
        page: 1,
        firstPage: true,
        lastPage: true,
        pageSize: DEFAULT_PAGE_SIZE,
        totalElements: 0,
        totalPages: 1,
        sort: DEFAULT_EVENTS_SORT,
    },
    filters: {
        eventPriority: [],
        status: [],
        sinceDay: getFormattedDate(new Date(), {format: "YYYY-MM-DD"}) || null,
        untilDay: getFormattedDate(new Date(), {format: "YYYY-MM-DD"}) || null,
        dateKind: "custom",
    },
};

interface IJournalQueryParams extends IJournalFilters {
    page?: number | null,
    pageSize: number,
    sort: string,
}

//Получить данные для таблицы журнала
// eslint-disable-next-line max-len
export const fetchJournalData = createAsyncThunk<JournalDataModelPagedList>("journal/fetchJournalData", async(args, {getState}) => {
    const path = buildQuery(ApiUrls.JOURNAL, getQueryParams(getState() as RootState));
    const response = await createHttpRequest({
        method: "GET",
        path,
        errorMessage: "messages:fetch_last_events_error",
    });

    return response.data;
});

function getQueryParams(rootState: RootState): IJournalQueryParams {
    const state = rootState.incidentsJournalReducer;
    return {
        page: state.pagination.page,
        pageSize: state.pagination.pageSize ?? DEFAULT_PAGE_SIZE,
        sort: state.pagination.sort ?? "",
        ...state.filters,
    };
}

const journal = createSlice({
    reducers: {
        setPagination(state, {payload}) {
            state.pagination = {
                ...state.pagination,
                ...payload,
            };
        },
        setInitQueryParams(state, {payload: {stateUpdateFields, params}}) {
            stateUpdateFields?.forEach((field: keyof typeof state) => {
                const stateField = state[field] as keyof IInitialState;
                const newState: keyof IInitialState | {[key: string]: any} = {};
                // Перебираем переданные квери параметры и заменяем значения в существующих полях стейт
                Object.entries(params).forEach((([paramKey, paramValue]) => {
                    const key = paramKey as keyof IEventsFilters;
                    const value = paramValue as string;
                    if (Object.prototype.hasOwnProperty.call(stateField, key)) {
                        // @ts-ignore
                        stateField[key] && Array.isArray(stateField[key])
                            ? newState[key] = value.split(",")
                            : newState[key] = value;
                    }
                }));

                if (Object.keys(newState).length) {
                    (state[field] as IInitialState) = {
                        ...state[field] as IInitialState,
                        ...newState,
                    };
                }
            });
        },
        // Фильтры для архива инцидентов
        setFilters(state, {payload}) {
            const filtersItem = state.filters?.[payload.filterType as keyof typeof state.filters];
            const nextValue = typeof payload.selectedFilters === "string"
                ? payload.selectedFilters
                : JSON.stringify(payload.selectedFilters);
            const currentValue = typeof filtersItem === "string" ? filtersItem : JSON.stringify(filtersItem);

            if (nextValue !== currentValue) {
                state.filters = {
                    ...state.filters,
                    [payload.filterType]: payload.selectedFilters,
                };
            }
        },
        upsertJournal(state, {payload}) {
            // Обновление записей Журнала событий через SignalR
            if (payload.eventModelType === "CommentChangeModel") {
                const preparedData = {
                    id: payload.incidentId,
                    status: payload.status,
                    type: payload.incidentType,
                    responseServices: payload.responseServices,
                    comment: payload.comment,
                    commentOperator: payload.operator,
                    commentSource: payload.source,
                    commentDateTime: payload.dateTime,
                };
                state.journalData.unshift(preparedData);
            }
        },
    },
    name: "journal",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchJournalData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchJournalData.fulfilled, (state, {payload}) => {
            state.journalData = payload.data;
            state.pagination = payload.pagination;
            state.loading = false;
        });
        builder.addCase(fetchJournalData.rejected, (state) => {
            state.loading = false;
        });
    },
});

export default journal.reducer;

export const {
    setPagination,
    setInitQueryParams,
    setFilters,
    upsertJournal,
} = journal.actions;

const slice = ({incidentsJournalReducer}: RootState) => incidentsJournalReducer;

export const journalDataSelector = createSelector(
    slice,
    ({journalData}) => journalData,
);

export const loadingSelector = createSelector(
    slice,
    ({loading}) => loading,
);

export const sortSelector = createSelector(
    slice,
    ({pagination}):IJournalSort => {
        const [field, direction] = (pagination?.sort ?? DEFAULT_EVENTS_SORT).split(",");
        return {field, direction} as IJournalSort;
    },
);

export const filtersSelector = createSelector(
    slice,
    ({filters}) => filters,
);

export const journalPaginationSelector = createSelector(
    slice,
    ({pagination}) => ({
        ...pagination,
        page: Number(pagination.page),
        totalElements: Number(pagination.totalElements),
        totalPages: Number(pagination.totalPages),
    }),
);
