import {ACCESS_TOKEN_NAME, REFRESH_TOKEN_NAME} from "constants/common";

import Token from "models/Token";
import AuthTokens from "models/AuthTokens";

import {isBrowser} from "./evironment";
import {ApiUrls} from "../constants/urls";
import createHttpRequest from "./http";

export const setTokens = (tokens: AuthTokens) => {
    if (isBrowser()) {
        localStorage.setItem(ACCESS_TOKEN_NAME, JSON.stringify(tokens.accessToken));
        localStorage.setItem(REFRESH_TOKEN_NAME, JSON.stringify(tokens.refreshToken));
    }

};

export const clearTokens = () => {
    if (isBrowser()) {
        localStorage.removeItem(ACCESS_TOKEN_NAME);
        localStorage.removeItem(REFRESH_TOKEN_NAME);
    }
};

export const getAccessToken = (): string | null => {
    return getToken(ACCESS_TOKEN_NAME);
};

export const getRefreshToken = (): string | null => {
    return getToken(REFRESH_TOKEN_NAME);
};

const getToken = (tokenName: string): string | null => {
    const tokenData: string | null = localStorage.getItem(tokenName);
    if (!tokenData) {
        return null;
    }
    const token: Token | null = JSON.parse(tokenData);
    if (!token) {
        return null;
    }
    if (new Date() >= new Date(token.expires)) {
        return null;
    }

    return token.value;
};

export const getActualAccessToken = async(): Promise<string> => {
    const accessToken = getAccessToken();
    if (null !== accessToken) {
        return accessToken;
    }

    return await refreshAccessToken() ?? "";
};

export const refreshAccessToken = async():Promise<string | null> => {
    const refreshToken = getRefreshToken();
    if (null === refreshToken){
        return null;
    }
    const response = await createHttpRequest({
        method: "POST",
        path: ApiUrls.REFRESH_TOKEN,
        data: `"${refreshToken}"`,
        isAnonymous: true,
    });
    setTokens(response.data);
    return  getAccessToken();
};