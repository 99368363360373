/* eslint-disable max-len */
import React, {FC} from "react";

import {ISvgProps} from "../../types/common";

//import icon from "images/icons/chevron-bottom.svg";

const ChevronBottomIcon: FC<ISvgProps> = ({
    width = 16,
    height = 16,
    ...rest
}) => {
    return (
        <svg {...rest} width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.293 4.29297L14.7072 5.70718L8.00008 12.4143L1.29297 5.70718L2.70718 4.29297L8.00008 9.58586L13.293 4.29297Z" fill="currentColor"/>
        </svg>
    );
};

export default ChevronBottomIcon;