import React, {FC, useEffect, useRef, useState} from "react";
import cn from "classnames";
import {useDispatch} from "react-redux";

import {TypedDispatch} from "../../../../types";

import {ITagGroup} from "../SettingsTags";
import {ICommentGroup} from "../../SettingsComments/SettingsComments";
import TagGroupDropdown from "../TagGroupDropdown";
import AddInput from "../AddInput";
import SettingsDeleteModal from "../../SettingsDeleteModal";

import styles from "./TagGroup.module.scss";

interface ITagGroupProps {
    data: ITagGroup | ICommentGroup,
    isActive: boolean,
    onClick: any,
    editGroup: any,
    inputRef: any,
    editableGroup: any,
    setEditableGroup: any,
    requestSuccess: any,
    resetSuccess: any,
    deleteGroup: any,
    type: "tag" | "comment",
}

export const TagGroup:FC<ITagGroupProps> = ({data, isActive, onClick,
    editGroup, inputRef, setEditableGroup, editableGroup, requestSuccess, resetSuccess, type,
    deleteGroup}) => {

    const dispatch = useDispatch<TypedDispatch>();

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [isModalShow, setIsModalShow] = useState(false);

    const handleClose = () => {
        setIsModalShow(false);
    };

    const referenceElement = useRef<HTMLDivElement>(null);
    const popperElement = useRef<HTMLDivElement>(null);

    const handleClickOutside = ({target}: Event) => {
        if ((referenceElement.current && !referenceElement.current.contains(target as Node)) &&
            (popperElement.current && !popperElement.current.contains(target as Node))) {
            setIsPopoverOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mouseup", handleClickOutside, true);
        return () => {
            document.removeEventListener("mouseup", handleClickOutside, true);
        };
    }, []);

    const deleteHandler = () => {
        dispatch(deleteGroup(data.id));
    };

    useEffect(() => {
        if (requestSuccess) {
            dispatch(resetSuccess());
        }
    }, [requestSuccess]);

    const editHandler = () => {
        setEditableGroup(data.id);
        setIsPopoverOpen(false);
    };

    const groupMouseUpHandle = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.button === 0) {
            onClick();
        } else  {
            data.id !== "default" && setIsPopoverOpen(!isPopoverOpen);
        }
    };

    return (
        editableGroup === data.id
            ?  <AddInput
                defaultValue={data?.title}
                count={data?.count}
                id={data?.id}
                groupData={data?.data}
                placeholder="Введите название группы и нажмите Enter"
                ref={inputRef}
                editData={editGroup}
                type="group"
                hideInput={setEditableGroup}
                requestSuccess={requestSuccess}
                resetSuccess={resetSuccess}
                requestGroupSuccess={requestSuccess}
            />
            : <div
                className={cn(styles["group"], {[styles["group--active"]]: isActive})}
                onMouseUp={groupMouseUpHandle}
                onContextMenu={(e) => {
                    e.preventDefault();
                    return false;
                }}
                ref={referenceElement}
            >
                <span className={styles["group__title"]}>{data.title}</span>
                <span className={styles["group__count"]}>{data.count}</span>

                <TagGroupDropdown
                    className={cn(styles["dropdown"],
                        {[styles["dropdown--opened"]]: isPopoverOpen})}
                    ref={popperElement}
                    referenceElement={referenceElement}
                    popperElement={popperElement}
                    deleteHandler={() => {
                        setIsModalShow(true);
                        setIsPopoverOpen(false);
                    }}
                    editHandler={editHandler}
                />

                {isModalShow && (
                    <SettingsDeleteModal
                        handleClose={handleClose}
                        title={`Удаление группы ${type === "tag" ? "тегов" : "комментариев"} из системы`}
                        text={`Вы уверены, что хотите удалить группу ${type === "tag" ? "тегов" : "комментариев"}?`}
                        action={deleteHandler}
                    />
                )}
            </div>
    );
};