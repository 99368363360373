import styled from "styled-components";

import checkIcon from "../../../../images/icons/check-white.svg";

export const CheckboxContainer = styled.div<{$bgColor?: string, $bgCheckedColor?: string}>`
          span {
            &::before {
              border: 2px solid ${props => (props.$bgColor || "var(--text-color)")};
            }
          }
          
          input {
            display: none;

            &:not(:disabled):not(:checked) + span:hover::before {
              border-color: ${props => (props.$bgColor || "var(--color-primary)")};
            }

            &:disabled:checked + span::before {
              box-shadow: unset;
            }

            &:checked+span::before {
              border-color: ${props => (props.$bgCheckedColor || props.$bgColor || "var(--color-primary)")};
              background-color: ${props => (props.$bgCheckedColor || props.$bgColor || "var(--color-primary)")};
              background-image: url('${checkIcon}');
            }

            &:disabled + span::before {
              box-shadow: inset 0 0 0 50px #f6f8fb;
              cursor: auto;
            }
          }
        `;