import React from "react";
import cn from "classnames";
import {useSelector} from "react-redux";
import {lastNewEventsCountSelector} from "../../../redux/eventSlice";

import WarningIcon from "../../Icons/WarningIcon";

import LightTooltip from "../../UI/Tooltip/LightTooltip";

import stylesHeader from "../Header.module.scss";
import styles from "./HeaderIncidents.module.scss";

const HeaderIncidents = () => {
    const lastNewEventsCount = useSelector(lastNewEventsCountSelector);

    return <>
        {!!lastNewEventsCount && (
            <LightTooltip title="Необработанные инциденты за сутки" placement="bottomStart">
                <div className={cn(stylesHeader["header__incidents"], styles["header-incidents"])}>
                    <WarningIcon/>

                    <div className={styles["header-incidents__count"]}>
                        {lastNewEventsCount}
                    </div>
                </div>
            </LightTooltip>
        )}
    </>;
};

export default HeaderIncidents;