// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AssignButton_assign-button__D5AlI {\n  display: flex;\n  gap: 5px;\n  align-items: center;\n  width: -moz-fit-content;\n  width: fit-content;\n  padding: 1px 12px;\n  color: var(--color-primary);\n  border: 1px solid var(--color-primary);\n  border-radius: 4px;\n  font-size: 12px;\n  font-weight: bold;\n  cursor: pointer;\n  margin-bottom: 8px;\n}\n.AssignButton_assign-block__JuOvh {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 8px;\n}\n.AssignButton_assign-block__JuOvh > div:last-child {\n  padding: 6px;\n  margin-bottom: 0;\n}\n.AssignButton_assign-block__JuOvh > div:last-child svg {\n  width: 12px;\n  height: 12px;\n}", "",{"version":3,"sources":["webpack://./src/components/Settings/SettingsTags/AssignButton/AssignButton.module.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,QAAA;EACA,mBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,iBAAA;EACA,2BAAA;EACA,sCAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,eAAA;EACA,kBAAA;AAAJ;AAGE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,kBAAA;AADJ;AAGI;EACE,YAAA;EACA,gBAAA;AADN;AAGM;EACE,WAAA;EACA,YAAA;AADR","sourcesContent":[".assign {\r\n  &-button {\r\n    display: flex;\r\n    gap: 5px;\r\n    align-items: center;\r\n    width: fit-content;\r\n    padding: 1px 12px;\r\n    color: var(--color-primary);\r\n    border: 1px solid var(--color-primary);\r\n    border-radius: 4px;\r\n    font-size: 12px;\r\n    font-weight: bold;\r\n    cursor: pointer;\r\n    margin-bottom: 8px;\r\n  }\r\n\r\n  &-block {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n    margin-bottom: 8px;\r\n\r\n    & > div:last-child {\r\n      padding: 6px;\r\n      margin-bottom: 0;\r\n\r\n      svg {\r\n        width: 12px;\r\n        height: 12px;\r\n      }\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"assign-button": "AssignButton_assign-button__D5AlI",
	"assign-block": "AssignButton_assign-block__JuOvh"
};
export default ___CSS_LOADER_EXPORT___;
