/* eslint-disable no-unused-vars */
export enum CameraIcon {
    Static = "static",
    StaticSmall = "staticSmall",
    Sphere = "sphere",
    SphereSmall = "sphereSmall",
    Sphere3 = "sphere3",
}

export enum CameraIconColor {
    $0 = 0,
    $1 = 1,
    $2 = 2
}