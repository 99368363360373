import React from "react";
import {Routes} from "react-router-dom";

import {AppUrls} from "./constants/urls";
import AuthPage from "./components/AuthPage/AuthPage";
import {useDispatch, useSelector} from "react-redux";
import {fetchMe, isAuthenticatedSelector, isUserLoadingSelector, userSelector} from "./redux/authSlice";
import {Permission} from "./models/Permission";
import {TypedDispatch} from "./types";
import {getProtectedRoute, IProtectedRouteProps} from "./utils/permissionsHelper";

import HomeIcon from "./components/Icons/HomeIcon";
import ArchiveIcon from "./components/Icons/ArchiveIcon";
import LogIcon from "./components/Icons/LogIcon";
import ReportIcon from "./components/Icons/ReportIcon";
import {getAccessToken, getRefreshToken} from "./utils/auth";
import DashBoardRedirect from "./components/Security/DashBoardRedirect";
import NoAuth from "./components/pages/NoAuth";
import HeadUserIcon from "./components/Icons/HeadUserIcon";
import IncidentsOnControlIcon from "./components/Icons/IncidentsOnControlIcon";

// const FixationsArchive = React.lazy(() => import("./components/pages/fixations-archive"));
const OperatorCard = React.lazy(() => import("./components/pages/superadmin/operator"));
// const SuperAdmin = React.lazy(() => import("./components/pages/superadmin"));
const EfficiencyJournal = React.lazy(() => import("./components/pages/efficiency-journal"));
const Journal = React.lazy(() => import("./components/pages/journal"));
const Dashboard  = React.lazy(() => import("./components/pages/dashboard"));
const DashboardScheme = React.lazy(() => import("./components/Dashboard/DashboardScheme"));
const Archive = React.lazy(() => import("./components/pages/event-archive"));
const ArchiveIncident = React.lazy(() => import("./components/pages/event-archive/ArchiveIncident"));
// const Fixation = React.lazy(() => import("./components/pages/fixations-archive/fixation"));
const ViewSettings = React.lazy(() => import("./components/pages/settings/view"));
/*const OutsideDashboardMLMap = React.lazy(
    () => import("./components/Dashboard/DashboardMap/MapLibre/OutsideDashboardMLMap")
);*/
const DashboardDemonstrationScreen = React.lazy(() => import("./components/pages/dashboard-demo-screen"));
const IncidentsOnControl = React.lazy(() => import("./components/pages/incidents-on-control"));
const AccountsSettings = React.lazy(() => import("./components/pages/settings/accounts"));
const AccountSettings = React.lazy(() => import("./components/pages/settings/account"));
const ZonesSettings = React.lazy(() => import("./components/pages/settings/zones"));
const SystemSettings = React.lazy(() => import("./components/pages/settings/system"));
const EfficiencyJournalCameraPage = React.lazy(
    () => import("./components/pages/efficiency-journal/EfficiencyJournalCameraPage")
);
const EfficiencyTickets = React.lazy(() => import("./components/pages/efficiency-tickets"));

export const routes: IProtectedRouteProps[] = [
    {
        path: AppUrls.AUTH,
        element: <AuthPage />,
    },
    {
        path: AppUrls.FORBIDDEN,
        element: <NoAuth />,
    },
    {
        path: AppUrls.HOME,
        element: <DashBoardRedirect />,
        auth: [Permission.ADMIN, Permission.EVENT, Permission.FIXATION, Permission.SERVICE, Permission.MANAGER],
    },
    {
        path: AppUrls.DASHBOARD,
        element: <Dashboard isOutsideWindow={window.name === "outsideWindow" } />,
        auth: [Permission.ADMIN, Permission.EVENT, Permission.SERVICE, Permission.MANAGER],
        title: "menu.dashboard",
        icon: <HomeIcon />,
    },
    {
        path: AppUrls.DASHBOARD_SCHEME,
        element: <DashboardScheme />,
        auth: [Permission.ADMIN, Permission.EVENT, Permission.SERVICE, Permission.MANAGER],
    },
    {
        path: AppUrls.EVENT_ARCHIVE,
        element: <Archive />,
        auth: [Permission.ADMIN, Permission.EVENT, Permission.SERVICE, Permission.MANAGER],
        title: "menu.archive",
        icon: <ArchiveIcon />,
    },
    {
        path: AppUrls.EVENT,
        element: <ArchiveIncident />,
        auth: [Permission.ADMIN, Permission.EVENT, Permission.SERVICE, Permission.MANAGER],
    },
    {
        path: AppUrls.JOURNAL,
        element: <Journal />,
        auth: [Permission.ADMIN, Permission.EVENT, Permission.MANAGER],
        title: "menu.journal",
        icon: <LogIcon />,
    },
    {
        path: AppUrls.EFFICIENCY_JOURNAL,
        element: <EfficiencyJournal />,
        auth: [Permission.ADMIN, Permission.EVENT, Permission.MANAGER],
        title: "menu.efficiency_journal",
        icon: <ReportIcon />,
    },
    /*{
        path: AppUrls.SUPERADMIN,
        element: <SuperAdmin />,
        auth: [Permission.ADMIN],
        title: "menu.super_admin",
        icon: <SuperAdminIcon/>,
    },*/
    {
        path: AppUrls.OPERATOR_CARD,
        element: <OperatorCard />,
        auth: [Permission.ADMIN, Permission.MANAGER],
    },
    /*{
        path: AppUrls.FIXATIONS_ARCHIVE,
        element: <FixationsArchive />,
        auth: [Permission.ADMIN, Permission.FIXATION],
        title: "menu.fixations_archive",
        icon: <BusIcon />,
    },
    {
        path: AppUrls.FIXATION,
        element: <Fixation />,
        auth: [Permission.ADMIN, Permission.FIXATION],
    },*/
    {
        path: AppUrls.SETTINGS_VIEW,
        element: <ViewSettings />,
        auth: [Permission.ADMIN, Permission.EVENT, Permission.MANAGER],
    },
    {
        path: AppUrls.SETTINGS_ACCOUNTS,
        element: <AccountsSettings />,
        auth: [Permission.ADMIN, Permission.MANAGER],
    },
    {
        path: AppUrls.SETTINGS_ACCOUNT,
        element: <AccountSettings />,
        auth: [Permission.ADMIN, Permission.EVENT, Permission.MANAGER],
    },
    {
        path: AppUrls.SETTINGS_ZONES,
        element: <ZonesSettings />,
        auth: [Permission.ADMIN, Permission.MANAGER],
    },
    {
        path: AppUrls.SETTINGS_SYSTEM,
        element: <SystemSettings />,
        auth: [Permission.ADMIN, Permission.MANAGER],
    },
    {
        path: AppUrls.DEMONSTRATION,
        element: <DashboardDemonstrationScreen />,
        auth: [Permission.ADMIN, Permission.MANAGER],
        title: "menu.demonstration",
        icon: <HeadUserIcon />,
    },
    {
        path: AppUrls.INCIDENTS_ON_CONTROL,
        element: <IncidentsOnControl />,
        auth: [Permission.ADMIN, Permission.MANAGER],
        title: "menu.pro_tv",
        icon: <IncidentsOnControlIcon />,
    },
    {
        path: AppUrls.EFFICIENCY_JOURNAL_CAMERA_PAGE,
        element: <EfficiencyJournalCameraPage />,
    },
    {
        path: AppUrls.EFFICIENCY_TICKETS,
        element: <EfficiencyTickets />,
    },
];

const Router = () => {
    const user = useSelector(userSelector);
    const isUserLoading = useSelector(isUserLoadingSelector);
    const isAuthenticated = useSelector(isAuthenticatedSelector);
    const dispatch = useDispatch<TypedDispatch>();

    if (
        (isAuthenticated || getAccessToken() || getRefreshToken())
        && null === user
        && !isUserLoading
    ) {
        dispatch(fetchMe());
    }

    return (
        <Routes>
            {
                routes.map(routeProps => getProtectedRoute(routeProps))
            }
        </Routes>
    );
};

export default Router;