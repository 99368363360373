import React, {FC} from "react";

import {TicketModel} from "../../../models/TicketModel";

import DeviceIcon from "../../Icons/DeviceIcon";

import styles from "./HeaderTickets.module.scss";

interface IHeaderTicket {
    ticket: TicketModel
}

const HeaderTicket:FC<IHeaderTicket> = ({ticket}) => {
    return (
        <div className={styles["ticket__item"]}>
            <div className={styles["ticket__camera"]}>
                <div className={styles["ticket__camera-wrapper"]}>
                    <DeviceIcon
                        deviceType={"CAM"}
                        deviceSubType={"staticSmall"}
                        width={24}
                        height={24}
                    />
                </div>

                <div className={styles["ticket__camera-info"]}>
                    <span className={styles["ticket__camera-subtype"]}>Стационарная тип 2</span>
                    <span className={styles["ticket__camera-type"]}>Обычная</span>
                </div>
            </div>

            <div className={styles["ticket__comment"]}>
                {ticket.Subject}
            </div>
        </div>
    );
};

export default HeaderTicket;