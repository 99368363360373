import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";

import {RootState} from "store";

import createHttpRequest from "../utils/http";
import {ApiUrls} from "../constants/urls";
import ViolationModel from "../models/ViolationModel";

interface IInitialState {
  violations: ViolationModel[],
  isLoading: boolean,
}

const initialState: IInitialState = {
    violations: [],
    isLoading: false,
};

export const fetchViolations = createAsyncThunk<ViolationModel[]>("violation/fetchViolations", async() => {
    const response = await createHttpRequest({
        method: "GET",
        path: ApiUrls.VIOLATIONS,
        errorMessage: "messages:fetch_violations_error",
    });

    return response.data;
});

const violation = createSlice({
    reducers: {},
    name: "violation",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchViolations.pending, (state) => {
            state.violations = [];
            state.isLoading = true;
        });
        builder.addCase(fetchViolations.fulfilled, (state, {payload}) => {
            state.violations = payload;
            state.isLoading = false;
        });
        builder.addCase(fetchViolations.rejected, (state) => {
            state.violations = [];
            state.isLoading = false;
        });
    },
});


export default violation.reducer;

//Селекторы
const slice = ({violationReducer}: RootState) => violationReducer;

export const violationsSelector = createSelector(
    slice,
    ({violations}) => violations
);

export const isViolationsLoadingSelector = createSelector(
    slice,
    ({isLoading}) => isLoading
);

