import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isArray} from "lodash";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { transliterate as tr } from "transliteration";

import {TypedDispatch} from "../../../types";
import {
    createGroup, createTag,
    editGroup, editTag,
    fetchTagGroups,
    fetchTags, requestTagSuccessSelector,
    requestGroupSuccessSelector,
    tagGroupsSelector, tagsLoadingSelector,
    tagsSelector, resetSuccess, deleteGroup, deleteTag,
} from "../../../redux/tagsSlice";
import {incidentTypesSelector} from "../../../redux/incidentTypeSlice";

import {TagGroup} from "./TagGroup/TagGroup";
import {Tag} from "./Tag/Tag";
import AddButton from "./AddButton";
import AddInput from "./AddInput";
import Spinner from "../../UI/Spinner/Spinner";
import AssignTagGroupForm from "./AssignTagGroupForm";
import AssignButton from "./AssignButton";

import styles from "./SettingsTags.module.scss";

export interface ITag {
    id: number,
    groupId: number | "default",
    name: string,
}

export interface ITagGroup {
    id: number | "default",
    title: string,
    count: number,
    data: any,
    commonListTags?: ITag[],
    tags: ITag[]
}

const SettingsTags = () => {
    const dispatch = useDispatch<TypedDispatch>();
    const tagGroupsList = useSelector(tagGroupsSelector);
    const tagsList = useSelector(tagsSelector);
    const tagsLoading = useSelector(tagsLoadingSelector);
    const requestTagSuccess = useSelector(requestTagSuccessSelector);
    const requestGroupSuccess = useSelector(requestGroupSuccessSelector);
    const incidentTypes = useSelector(incidentTypesSelector);

    const [groups, setGroups] = useState<ITagGroup[] | []>([]);
    const [defaultGroups, setDefaultGroups] = useState<ITagGroup[] | []>([]);

    const [activeGroup, setActiveGroup] = useState<null | number | "default">("default");
    const [activeGroupTags, setActiveGroupTags] = useState<ITag[] | null>();

    const [groupInputVisible, setGroupInputVisible] = useState<boolean>(false);
    const [tagInputVisible, setTagInputVisible] = useState<boolean>(false);

    const [editableGroup, setEditableGroup] = useState<false | number>(false);
    const [editableTag, setEditableTag] = useState<false | number>(false);
    
    const [assignTagGroupId, setAssignTagGroupId] = useState<number | null>(null);

    const inputRef = useRef(null);

    // Получение данных по тегам
    useEffect(() => {
        dispatch(fetchTagGroups());
        dispatch(fetchTags());
    }, []);

    // Обновление тегов после crud-операций
    useEffect(() => {
        if (requestTagSuccess) dispatch(fetchTags());
    }, [requestTagSuccess]);

    // Обновление групп тегов после crud-операций
    useEffect(() => {
        if (requestGroupSuccess) {
            dispatch(fetchTagGroups());
            if (assignTagGroupId) setAssignTagGroupId(null);
        }
    }, [requestGroupSuccess]);

    useEffect(() => {
        if (!tagGroupsList?.find((group: any) => group.Id === activeGroup)) {
            setActiveGroup("default");
        }
    }, [tagGroupsList]);

    // Формирование данных групп тегов
    const prepareData = (groups: any, tags: any) => {
        // eslint-disable-next-line max-len
        const preparedGroups = groups.map((group: any) => ({id: group.Id, data: group.Data, title: group.Name, count: 0, tags: []}));
        const preparedTags = tags.map((tag: any) => ({id: tag.id, groupId: tag?.viewJson?.groupId, name: tag.name}));
        const commonListTags = []; // Теги, созданные вне группы (для вывода в общий список)
        let commonListTagsCount = 0; // Кол-во всех тегов
        for (let i = 0; i < preparedTags.length; i++) {
            const selectedGroup = preparedGroups.find((group: any) => group.id === preparedTags[i]?.groupId);
            if (selectedGroup) {
                selectedGroup.tags.push(preparedTags[i]);
                selectedGroup.count += 1;
            } else {
                commonListTags.push(preparedTags[i]);
            }
            commonListTagsCount +=1;
        }

        // eslint-disable-next-line max-len
        setGroups([{id: "default", title: "Все теги", count: commonListTagsCount, data: {}, commonListTags, tags: []}, ...preparedGroups]);
        setDefaultGroups(preparedGroups);
    };

    useEffect(() => {
        if (isArray(tagGroupsList) && isArray(tagsList)) {
            prepareData(tagGroupsList, tagsList);
        }
    }, [tagGroupsList, tagsList]);

    useEffect(() => {
        setActiveGroupTags(groups?.find(item => item.id === activeGroup)?.tags);
    }, [activeGroup, groups]);

    const addGroupHandler = (value: string) => {
        dispatch(createGroup({data: {
            data: {
                group: tr(value),
            },
            name: value,
            typeId: "INCIDENTS_TAGGROUP.1",
        }}));
    };

    const addTagHandler = (value: string) => {
        dispatch(createTag({data: {
            name: value,
            description: value,
            viewJson: {groupId: activeGroup},
        }}));
    };

    const editGroupHandler = (id: number, value: string) => {
        const currentGroup = groups.find((group: any) => group.id === activeGroup);

        const preparedData = {
            data: currentGroup?.data,
            name: value,
            typeId: "INCIDENTS_TAGGROUP.1",
        };
        dispatch(editGroup({id, data: preparedData}));
    };

    const editTagHandler = (id: number, value: string, groupId: number) => {
        dispatch(editTag({id, data: {
            name: value,
            description: value,
            viewJson: {groupId: groupId},
        }}));
    };

    return (
        <div className={styles["tags"]}>
            {tagsLoading ? <Spinner size={32} className="mt-4"/> : (
                assignTagGroupId
                    ? <AssignTagGroupForm
                        incidentTypes={incidentTypes}
                        setAssignGroupId={setAssignTagGroupId}
                        editGroup={editGroup}
                        groups={tagGroupsList}
                        activeGroup={assignTagGroupId}
                    />
                    : <>
                        <div className={styles["tags-groups"]}>
                            <SimpleBar style={{maxHeight: "500px", height: "500px", paddingRight: "20px"}}>
                                <div>
                                    {groups && groups?.length > 0 ? groups.map(group => (
                                        <TagGroup
                                            key={group.id}
                                            data={group}
                                            isActive={activeGroup === group?.id}
                                            onClick={() => setActiveGroup(group?.id)}
                                            editGroup={editGroupHandler}
                                            inputRef={inputRef}
                                            setEditableGroup={setEditableGroup}
                                            editableGroup={editableGroup}
                                            requestSuccess={requestGroupSuccess}
                                            resetSuccess={resetSuccess}
                                            deleteGroup={deleteGroup}
                                            type={"tag"}
                                        />
                                    )) : <div className={styles["no-data"]}>Нет групп</div>}
                                    {groupInputVisible && (
                                        <AddInput
                                            placeholder="Введите название группы и нажмите Enter"
                                            ref={inputRef}
                                            addData={addGroupHandler}
                                            type="group"
                                            hideInput={setGroupInputVisible}
                                            requestSuccess={requestTagSuccess}
                                            requestGroupSuccess={requestGroupSuccess}
                                            resetSuccess={resetSuccess}
                                        />
                                    )}
                                </div>
                            </SimpleBar>
                            <AddButton text={"Добавить группу"} onClick={() => setGroupInputVisible(true)}/>
                        </div>
                        <div className={styles["tags-items"]}>
                            <SimpleBar style={{maxHeight: "500px", height: "500px", paddingRight: "20px"}}>
                                <div>
                                    {groups?.length > 0
                                        ? activeGroup === "default"
                                            ? <>
                                                {(groups[0].commonListTags && groups[0].commonListTags?.length > 0)
                                    && groups[0].commonListTags.map(tag => (
                                        <Tag
                                            key={tag.id}
                                            data={tag}
                                            editTag={editTagHandler}
                                            inputRef={inputRef}
                                            setEditableTag={setEditableTag}
                                            editableTag={editableTag}
                                            placeholder={"Введите название тега и нажмите Enter"}
                                            deleteTag={deleteTag}
                                            resetSuccess={resetSuccess}
                                            requestSuccess={requestTagSuccess}
                                        />
                                    ))}
                                                {defaultGroups.map(group => (
                                                    <div key={group.id}>
                                                        <div className={styles["tags-items__title"]}>
                                                            {group.title}
                                                        </div>
                                                        {group.id !== "default" && (
                                                            <AssignButton
                                                                data={group}
                                                                onClick={() =>
                                                                    setAssignTagGroupId(Number(group?.id) ?? 0)}
                                                                className="mt-2"
                                                            />
                                                        )}
                                                        {group?.tags?.length > 0 ? group.tags.map(tag => (
                                                            <Tag
                                                                key={tag.id}
                                                                data={tag}
                                                                editTag={editTagHandler}
                                                                inputRef={inputRef}
                                                                setEditableTag={setEditableTag}
                                                                editableTag={editableTag}
                                                                placeholder={"Введите название тега и нажмите Enter"}
                                                                deleteTag={deleteTag}
                                                                resetSuccess={resetSuccess}
                                                                requestSuccess={requestTagSuccess}
                                                            />
                                                        )) : <div className={styles["no-data"]}>Нет тегов</div>}
                                                    </div>
                                                ))}
                                            </>
                                            : activeGroupTags && activeGroupTags?.length > 0
                                                ? <div>
                                                    <AssignButton
                                                        data={groups?.find(item => item.id === activeGroup)}
                                                        onClick={() => setAssignTagGroupId(activeGroup)}
                                                    />
                                                    {activeGroupTags.map(tag => (
                                                        <Tag
                                                            key={tag.id}
                                                            data={tag}
                                                            editTag={editTagHandler}
                                                            inputRef={inputRef}
                                                            setEditableTag={setEditableTag}
                                                            editableTag={editableTag}
                                                            placeholder={"Введите название тега и нажмите Enter"}
                                                            deleteTag={deleteTag}
                                                            resetSuccess={resetSuccess}
                                                            requestSuccess={requestTagSuccess}
                                                        />
                                                    ))}
                                                </div> : <>
                                                    <AssignButton
                                                        data={groups?.find(item => item.id === activeGroup)}
                                                        onClick={() => setAssignTagGroupId(activeGroup)}
                                                    />
                                                    <div className={styles["no-data"]}>Нет тегов</div>
                                                </>
                                        : <div className={styles["no-data"]}>Нет тегов</div>}
                                    {tagInputVisible && (
                                        <AddInput
                                            placeholder="Введите название тега и нажмите Enter"
                                            ref={inputRef}
                                            addData={addTagHandler}
                                            type="tag"
                                            hideInput={setTagInputVisible}
                                            requestSuccess={requestTagSuccess}
                                            requestGroupSuccess={requestGroupSuccess}
                                            resetSuccess={resetSuccess}
                                        />
                                    )}
                                </div>
                            </SimpleBar>
                            <AddButton text={"Добавить тег"} onClick={() => setTagInputVisible(true)}/>
                        </div>
                    </>
            )}
        </div>
    );
};

export default SettingsTags;