import React, {FC, ReactNode} from "react";
import cn from "classnames";

import BaseButton from "./BaseButton";

interface IButtonProps {
    type?: "button" | "submit" | "reset" | undefined,
    tag?: "button" | "a",
    href?: string,
    className?: string,
    onClick?: () => void,
    children?: ReactNode,
    disabled?: boolean,
}

const PrimaryButton: FC<IButtonProps> =
    React.forwardRef<HTMLButtonElement, React.PropsWithChildren<IButtonProps>>((props, ref) => {
        const {type, children, className, onClick, tag, href, disabled} = props;

        return (
            <BaseButton
                className={cn("btn--primary", className)}
                onClick={onClick}
                tag={tag}
                href={href}
                type={type}
                disabled={disabled}
                // @ts-ignore
                ref={ref}
            >
                {children}
            </BaseButton>
        );
    });

PrimaryButton.defaultProps = {
    tag: "button",
    type: "button",
    href: "",
    className: "",
};

PrimaryButton.displayName = "PrimaryButton";

export default React.memo(PrimaryButton);