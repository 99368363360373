import React, {FC} from "react";

import styles from "./Operator.module.scss";

interface IOperatorProps {
    name: string,
}

const Operator:FC<IOperatorProps> = ({name}) => (
    <div className={styles["operator"]}>{name}</div>
);

export default Operator;