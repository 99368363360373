import React, {forwardRef, useImperativeHandle} from "react";
import ObjectWithUrl from "../../models/ObjectWithUrl";

declare const VALID_LOADING_VALUES: readonly ["lazy", "eager", undefined];
declare type LoadingValue = typeof VALID_LOADING_VALUES[number];
export type ImgElementStyle = NonNullable<JSX.IntrinsicElements["img"]["style"]>;

export interface StaticImageData {
    src: string;
    height: number;
    width: number;
}

export interface IImageProps {
    className?: string,
    src: string | StaticImageData;
    width?: number | string;
    height?: number | string;
    style?: ImgElementStyle;
    alt: string;
    loading?: LoadingValue;
    objectFit?: ImgElementStyle["objectFit"];
    // eslint-disable-next-line no-unused-vars
    onClick?: (e: React.MouseEvent) => void;
    ref?: any,
}

const Image = forwardRef<ObjectWithUrl, IImageProps>(({src, alt, loading, objectFit, style, ...rest}, ref) => {
    const srcData = typeof src === "string" ? src : src.src;

    let newStyle = style;
    if (objectFit) {
        newStyle = {...newStyle, ...{
            objectFit,
            maxWidth: "100%",
            maxHeight: "100%",
            minHeight: "100%",
            minWidth: "100%",
        }};
    }

    useImperativeHandle(ref, () => ({
        getUrl: () => {
            return srcData ?? "";
        },
    }));

    return <img
        {...rest}
        src={srcData}
        alt={alt}
        loading={loading ?? "lazy"}
        style={newStyle}
    />;
});

Image.displayName = "Image";

export default Image;