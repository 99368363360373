import React, {FC, useRef} from "react";
import cn from "classnames";

import AlertFillCircleIcon from "../../Icons/Notifications/AlertFillCircleIcon";
import BaseButton from "../../UI/Button/BaseButton";
import CrossIcon from "../../Icons/CrossIcon";

import styles from "./SettingsDeleteModal.module.scss";

interface ISettingsDeleteModalProps {
    handleClose: () => void,
    title: string,
    text: string,
    action: any,
}

const SettingsDeleteModal:FC<ISettingsDeleteModalProps> = ({
    handleClose,
    title,
    text,
    action,
}) => {
    const rootEl = useRef(null);
    
    return (
        <div className={styles["wrapper"]}>
            <div
                className={cn(styles["modal"], styles["modal--danger"])}
                ref={rootEl}
            >
                <span className={styles["modal__alert"]}>
                    <div>
                        <AlertFillCircleIcon/>
                        {title}
                    </div>
                    <CrossIcon
                        className={styles["close"]}
                        onClick={handleClose}
                        width={20}
                        height={20}
                    />
                </span>
                <div/>
                <div className={styles["modal__body"]}>
                    <div className={styles["text"]}>
                        {text}
                    </div>

                    <div className={styles["actions"]}>
                        <BaseButton
                            tag={"button"}
                            //@ts-ignore
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleClose();
                            }}
                            className={cn(styles["actions__btn"], "btn--secondary", "btn--outline", "btn--md")}
                        >
                            Отменить
                        </BaseButton>
                        <BaseButton
                            tag={"button"}
                            onClick={() => {
                                action();
                                handleClose();
                            }}
                            className={cn(styles["actions__btn"], "btn--danger", "btn--md")}
                        >
                            Да, удалить
                        </BaseButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SettingsDeleteModal;