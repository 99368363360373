import {createSelector, createSlice} from "@reduxjs/toolkit";

import {DEFAULT_LOCALE} from "constants/common";

import {RootState} from "store";

const initialState = {
    locale: DEFAULT_LOCALE,
};

const locale = createSlice({
    name: "locale",
    initialState,
    reducers: {
        setLocale(state, action){
            state.locale = action.payload;
        },
    },
});

export const {setLocale} = locale.actions;

export default locale.reducer;


//Селекторы
const slice = ({localeReducer}: RootState) => localeReducer;

export const localeSelector = createSelector(
    slice,
    ({locale}) => locale
);