import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import {toast} from "react-toastify";

import CollectionWithPagination from "../models/CollectionWithPagination";
import {buildQuery} from "../utils/query";
import {ApiUrls} from "../constants/urls";
import {RootState} from "../store";
import {DEFAULT_PAGE_SIZE, DEFAULT_USERS_SORT} from "../constants/common";
import createHttpRequest from "../utils/http";
import Organization from "../models/Organization";

export interface IUsersFilters {
    ZoneIds: number[],
    roleIds: number[],
    UserFullName: string | null,
}

interface IUsersQueryParams extends IUsersFilters {
    page?: number | null,
    pageSize: number,
    sort: string,
}

interface IInitialState {
    organizations: Organization[],
    organization: Organization | null,
    loading: boolean,
    requestLoading: boolean,
    success: any,
    requestError: any,
}

const initialState: IInitialState = {
    organizations: [],
    organization: null,
    loading: false,
    requestLoading: false,
    success: null,
    requestError: null,
};

// function getQueryParams(rootState: RootState): IUsersQueryParams {
//     const state = rootState.organizationsSettingsReducer;
//     return {
//         page: state.pagination.page,
//         pageSize: state.pagination.pageSize ?? DEFAULT_PAGE_SIZE,
//         sort: state.pagination.sort ?? "",
//         ...state.filters,
//     };
// }

// Получение списка организаций
export const fetchOrganizations
    = createAsyncThunk<CollectionWithPagination<Organization>>("organizationsSettings/fetchOrganizations",
        async(_,  {getState}) => {
            // const path = buildQuery(ApiUrls.ORGANIZATIONS, getQueryParams(getState() as RootState));
            const response = await createHttpRequest({
                method: "GET",
                path: ApiUrls.ORGANIZATIONS,
                errorMessage: "messages:fetch_organizations_error",
            });

            return response.data;
        });

// Создание организации
// eslint-disable-next-line max-len
export const createOrganization = createAsyncThunk<void, Partial<Organization>>("organizationsSettings/createOrganization", async(data) => {
    const response = await createHttpRequest({
        method: "POST",
        path: ApiUrls.ORGANIZATIONS,
        data,
        errorMessage: "messages:create_organization_error",
    });

    return response.data;
});

// Удаление организации
// eslint-disable-next-line max-len
export const deleteOrganization = createAsyncThunk<any, string>("organizationsSettings/deleteOrganization", async(id) => {
    const response = await createHttpRequest({
        method: "DELETE",
        path: ApiUrls.ORGANIZATION(id),
        errorMessage: "messages:delete_organization_error",
    });

    return response.data;
});

// Редактирование организации
export const editOrganization = createAsyncThunk<void, {
    id: number,
    data: Partial<Organization>
}>("organizationsSettings/editOrganization", async({id, data}) => {
    const response = await createHttpRequest({
        method: "PUT",
        path: ApiUrls.ORGANIZATION(id),
        data: {...data},
        errorMessage: "messages:edit_organization_error",
    });

    return response.data;
});

// Получение данных организации
export const fetchOrganization
    = createAsyncThunk<void, any>("organizationsSettings/fetchOrganization",
        async({id}) => {
            const response = await createHttpRequest({
                method: "GET",
                path: ApiUrls.ORGANIZATION(id),
                errorMessage: "messages:fetch_organization_error",
            });

            return response.data;
        });

const organizationsSettings = createSlice({
    reducers: {},
    name: "organizationsSettings",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchOrganizations.pending, (state) => {
            state.success = null;
            state.loading = true;
        });
        builder.addCase(fetchOrganizations.fulfilled, (state, {payload}) => {
            state.organizations = payload?.data ?? payload;
            // state.pagination = payload.pagination;
            state.loading = false;
        });
        builder.addCase(fetchOrganizations.rejected, (state) => {
            state.loading = false;
            state.success = null;
        });

        // Получение организации
        builder.addCase(fetchOrganization.pending, (state) => {
            state.success = null;
            state.loading = true;
        });
        builder.addCase(fetchOrganization.fulfilled, (state, {payload}) => {
            // @ts-ignore
            state.organization = payload;
            // state.pagination = payload.pagination;
            state.loading = false;
        });
        builder.addCase(fetchOrganization.rejected, (state) => {
            state.loading = false;
            state.success = null;
        });

        // Удаление организации
        builder.addCase(deleteOrganization.pending, (state) => {
            state.requestLoading = true;
            state.success = null;
        });
        builder.addCase(deleteOrganization.fulfilled, (state) => {
            state.success = true;
            state.requestLoading = false;
            toast.success("Запись успешно удалена");
        });
        builder.addCase(deleteOrganization.rejected, (state) => {
            state.success = null;
            state.requestLoading = false;
        });

        // Создание организации
        builder.addCase(createOrganization.pending, (state) => {
            state.requestError = null;
            state.success = null;
            state.requestLoading = true;
        });
        builder.addCase(createOrganization.fulfilled, (state) => {
            state.success = true;
            state.requestLoading = false;
            toast.success("Организация успешно создана");
        });
        builder.addCase(createOrganization.rejected, (state) => {
            state.success = null;
            state.requestError = true;
            state.requestLoading = false;
        });

        //Редактирование организации
        builder.addCase(editOrganization.pending, (state) => {
            state.requestLoading = true;
            state.success = null;
        });
        builder.addCase(editOrganization.fulfilled, (state) => {
            state.success = true;
            state.requestLoading = false;
            toast.success("Организация успешно изменена");
        });
        builder.addCase(editOrganization.rejected, (state) => {
            state.success = null;
            state.requestLoading = false;
        });
    },
});

export default organizationsSettings.reducer;

const slice = ({organizationsSettingsReducer}: RootState) => organizationsSettingsReducer;

export const organizationsSelector = createSelector(
    slice,
    ({organizations}) => organizations
);

export const organizationSelector = createSelector(
    slice,
    ({organization}) => organization
);

export const loadingSelector = createSelector(
    slice,
    ({loading}) => loading
);

export const orgSuccessSelector = createSelector(
    slice,
    ({success}) => success
);

export const orgRequestErrorSelector = createSelector(
    slice,
    ({requestError}) => requestError
);
