import React, {FC, useEffect} from "react";
import cn from "classnames";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {SignalRService} from "../../utils/signalr/SignalRService";
import {getLocalStorageValue, setLocalStorageValue} from "../../utils/common";
import {signalRSettingsSelector, themeSelector} from "../../redux/commonSlice";

import LayoutMain from "./LayoutMain";
import LayoutAuthorization from "./LayoutAuthorization";
import LayoutDemonstration from "./LayoutDemonstration";
import SpinnerWithOverlay from "../UI/SpinnerWithOverlay";

import stylesCommon from "../../styles/Common.module.scss";

interface ILayoutProps {
    type: "authorization" | "main" | "demonstration" | "empty",
    headerFilters?: React.ReactNode,
    children: string | React.ReactNode,
    settingsMode?: boolean,
}

const Layout: FC<ILayoutProps> = ({type, headerFilters, children, settingsMode, ...props}) => {
    const {t} = useTranslation("common");
    const signalRSettings = useSelector(signalRSettingsSelector);
    const theme = useSelector(themeSelector);

    // Меняем метку если у пользователей принудительно нужно очистить local и session storage
    useEffect(() => {
        const currentTag = "2024-05-30-18-51";
        const savedTag = getLocalStorageValue("versionTag");

        if (!savedTag || !(savedTag && savedTag === currentTag)) {
            window.localStorage.clear();
            window.sessionStorage.clear();
            setLocalStorageValue("versionTag", currentTag);
            window.location.reload();
        }
    }, []);

    useEffect(() => {
        if (!signalRSettings.connectionSuccess || signalRSettings.isDisconnected) {
            const signalRService = SignalRService.getInstance();
            signalRService.send("UnsubscribeFromIncidentEvents");
            signalRService.send("SubscribeToIncidentEvents");
        }
    }, [signalRSettings.isDisconnected]);

    // Меняем класс у body при изменении темы
    useEffect(() => {
        if (typeof document !== "undefined") {
            document.querySelector("body")?.classList.remove(theme === "light" ? "dark" : "light");
            document.querySelector("body")?.classList.add(theme);
        }
    }, [theme]);

    const isDemonstrationDashboard = type === "demonstration";

    return (
        <div className={cn(
            stylesCommon["main"],
            isDemonstrationDashboard && stylesCommon["main--demonstration"],
            theme,
            {[stylesCommon["empty"]]: type === "empty"}
        )}>
            {type === "authorization" &&
            <LayoutAuthorization {...props}>
                {children}
            </LayoutAuthorization>}

            {type === "main" &&
            <LayoutMain
                {...props}
                theme={theme}
                headerFilters={headerFilters}
                settingsMode={settingsMode}
            >
                {children}
            </LayoutMain>}

            {type === "demonstration" &&
                <LayoutDemonstration
                    isDemonstrationDashboard={isDemonstrationDashboard}
                    {...props}
                >
                    {children}
                </LayoutDemonstration>}

            {type === "empty" &&
                <>{children}</>
            }

            {/*Нет подключения к серверу*/}
            {signalRSettings.isDisconnected && (
                <SpinnerWithOverlay>
                    {t("common:no_server_connection")}
                </SpinnerWithOverlay>
            )}
        </div>
    );
};

export default React.memo(Layout);