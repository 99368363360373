import React, {FC, useEffect} from "react";
import cn from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

import {
    availableOperatorsSelector,
    fetchAvailableZoneOperators,
    requestLoadingSelector, requestSuccessSelector, resetSuccess,
    updateResponsibilityZones,
} from "../../../../redux/zoneOperatorsSlice";
import {TypedDispatch} from "../../../../types";

import ControlledCheckbox from "../../../UI/Checkbox/ControlledCheckbox";
import BaseButton from "../../../UI/Button/BaseButton";
import Spinner from "../../../UI/Spinner/Spinner";
import CrossIcon from "../../../Icons/CrossIcon";

import modalStyles from "../../../ArchiveIncidentPage/IncidentInfo/IncidentInfoModals/IncidentInfoModals.module.scss";
import styles from "./AddOperatorsForm.module.scss";

interface IAddOperatorsFormProps {
    onClose: () => void,
    operatorRole: number | null,
    activeZone: number | null | undefined,
}
const AddOperatorsForm:FC<IAddOperatorsFormProps> = ({onClose, operatorRole, activeZone}) => {
    const dispatch = useDispatch<TypedDispatch>();
    const operators = useSelector(availableOperatorsSelector);
    const loading = useSelector(requestLoadingSelector);
    const success = useSelector(requestSuccessSelector);

    const {setValue, clearErrors, handleSubmit, formState: {errors}, setError} = useForm({mode: "onChange"});

    useEffect(() => {
        if (!operators?.length && operatorRole) {
            dispatch(fetchAvailableZoneOperators({roleId: operatorRole}));
        }
    }, []);

    useEffect(() => {
        if (success) {
            dispatch(resetSuccess());
            onClose();
        }
    }, [success]);

    const findTruthyKeys = (obj: any) => {
        const allKeys = Object.keys(obj);
        return allKeys.filter(key => obj[key]);
    };

    const onSubmit = (data: any) => {
        const operators = findTruthyKeys(data);
        if (!operators.length) {
            return setError("operator", {message: "Выберите хотя бы одного оператора"});
        }
        const preparedData = {
            responsibilityZoneIds: [activeZone],
            userIds: operators,
        };
        dispatch(updateResponsibilityZones({data: preparedData, mode: "add"}));
    };

    return (
        <form className={styles["form"]}  onSubmit={handleSubmit(onSubmit)}>
            <div className={styles["form-title"]}>
                <div>Выбор операторов</div>
                <div onClick={onClose} className={styles["form-title__icon"]}>
                    <CrossIcon width={16} height={16}/>
                </div>
            </div>
            <SimpleBar style={{maxHeight: "400px", height: "100%", paddingRight: "20px"}}>
                <div>
                    {operators?.map(item => (
                        <div key={item.id} className={styles["form__item"]}>
                            <ControlledCheckbox
                                name={`${item.id}`}
                                onChange={value => {
                                    if (errors.operator) clearErrors();
                                    setValue(`${item.id}`, value);
                                }}
                            >
                                {item?.fullName}
                            </ControlledCheckbox>
                        </div>
                    ))}
                </div>
            </SimpleBar>
            <div className={styles["actions"]}>
                {/*@ts-ignore*/}
                {errors?.operator && <div className={styles["text-error"]}>{errors.operator?.message}</div>}
                <BaseButton
                    tag={"button"}
                    className={cn(modalStyles["actions__btn"], "btn--secondary", "btn--md", "w-100", "mt-4")}
                    type={"submit"}
                >
                    {loading ? <Spinner/> : "Назначить"}
                </BaseButton>
            </div>
        </form>
    );
};

export default AddOperatorsForm;