import React, {useRef, useState} from "react";
import {Popover, Whisper, WhisperInstance} from "rsuite";

import useHasPermission from "../../../hook/useHasPermission";
import {Permission} from "../../../models/Permission";

import {ICard} from "../../Settings/SettingsViewPage/SettingsViewPage";
import {DEFAULT_CARD_LIST} from "../../Settings/constants";
import ViewSettingsBlock from "../../Settings/ViewSettingsBlock";
import SettingsIcon from "../../Icons/SettingsIcon";
import QuickSettingsTabs from "../../Settings/QuickSettingsTabs";

import styles from "./QuickSettings.module.scss";

const QuickSettings = () => {
    const isAdmin = useHasPermission(Permission.ADMIN);
    const isManager = useHasPermission(Permission.MANAGER);
    const whisperRef = useRef<WhisperInstance>();

    // Список превью карточек
    const [cardList, setCardList] = useState<ICard[]>(DEFAULT_CARD_LIST);

    return (
        <>
            <Whisper
                //@ts-ignore
                ref={whisperRef}
                placement="bottomEnd"
                trigger="click"
                speaker={<Popover
                    arrow={false}
                    className={styles["dropdown-cnt"]}
                >
                    {(isAdmin || isManager) ?
                        <QuickSettingsTabs
                            setCardList={setCardList}
                            cardList={cardList}
                            isAdmin={isAdmin}
                            onClose={() => whisperRef.current?.close()}
                        />
                        : <ViewSettingsBlock
                            previewMode={true}
                            setCardList={setCardList}
                            cardList={cardList}
                            isAdmin={isAdmin}
                        />
                    }
                </Popover>
                }
            >
                <div className={styles["header-settings"]}>
                    <SettingsIcon className={styles["header-settings__icon"]} />
                </div>
            </Whisper>
        </>
    );
};

export default QuickSettings;