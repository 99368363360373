import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {toast} from "react-toastify";

import createHttpRequest from "../utils/http";
import {ApiUrls} from "../constants/urls";
import {ICard} from "../components/Settings/SettingsViewPage/SettingsViewPage";

export interface IPreparedOptionsRequest {
    [key: number]: ICard
}

interface IInitialState {
    settings: any,
    isSettingsLoading: boolean,
    saveLoading: boolean,
    saveSuccess: boolean,
    hasChanged: boolean,
    settingsBackUrl: string | null
}

const initialState: IInitialState = {
    settings: null,
    isSettingsLoading: false,
    saveLoading: false,
    saveSuccess: false,
    hasChanged: false,
    settingsBackUrl: null,
};

// Получение настроек вида главного экрана
export const fetchUserSettings = createAsyncThunk("userSettings/fetchUserSettings", async() => {
    const response = await createHttpRequest({
        method: "GET",
        path: ApiUrls.USER_SETTINGS_VIEW,
        errorMessage: "messages:fetch_settings_error",
    });

    return response.data;
});

// Обновление настроек вида главного экрана
export const saveUserSettings =
    createAsyncThunk<IPreparedOptionsRequest, IPreparedOptionsRequest>("userSettings/saveUserSettings", async(data) => {
        const response = await createHttpRequest({
            method: "PUT",
            path: ApiUrls.USER_SETTINGS_VIEW,
            data,
            errorMessage: "messages:add_media_error",
        });

        return response.data;
    });

const userSettings = createSlice({
    reducers: {
        setHasChanged(state, {payload}) {
            state.hasChanged = payload;
        },
        setSettingsBackUrl(state, {payload}) {
            state.settingsBackUrl = payload;
        },
    },
    name: "userSettings",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchUserSettings.pending, (state) => {
            state.isSettingsLoading = true;
        });
        builder.addCase(fetchUserSettings.fulfilled, (state, {payload}) => {
            state.settings = payload;
            state.isSettingsLoading = false;
        });
        builder.addCase(fetchUserSettings.rejected, (state) => {
            state.isSettingsLoading = false;
        });

        builder.addCase(saveUserSettings.pending, (state) => {
            state.saveSuccess = false;
            state.saveLoading = true;
        });
        builder.addCase(saveUserSettings.fulfilled, (state) => {
            toast.success("Внесенные изменения сохранены", {
                position: "bottom-right",
            });
            state.saveLoading = false;
            state.saveSuccess = true;
        });
        builder.addCase(saveUserSettings.rejected, (state) => {
            toast.success("Внесенные изменения не сохранены", {
                position: "bottom-right",
            });
            state.saveLoading = false;
        });
    },
});

export const {
    setHasChanged,
    setSettingsBackUrl,
} = userSettings.actions;

export default userSettings.reducer;

//Селекторы
const slice = ({userSettingsReducer}: RootState) => userSettingsReducer;

export const userSettingsSelector = createSelector(
    slice,
    ({settings}) => settings
);

export const isSettingsLoadingSelector = createSelector(
    slice,
    ({isSettingsLoading}) => isSettingsLoading
);

export const saveSuccessSelector = createSelector(
    slice,
    ({saveSuccess}) => saveSuccess
);

export const hasChangedSelector = createSelector(
    slice,
    ({hasChanged}) => hasChanged
);

export const saveLoadingSelector = createSelector(
    slice,
    ({saveLoading}) => saveLoading
);

export const settingsBackUrlSelector = createSelector(
    slice,
    ({settingsBackUrl}) => settingsBackUrl
);