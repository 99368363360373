import React, {FC} from "react";
import {connect, useDispatch} from "react-redux";
import {createStructuredSelector} from "reselect";
import {SubmitHandler, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useNavigate, useSearchParams} from "react-router-dom";

import {authLoadingSelector, loginUser} from "../../redux/authSlice";

import Layout from "components/Layout";

import TextFormInput from "../UI/Input/TextFormInput";
import PasswordFormInput from "../UI/Input/PasswordFormInput";
import PrimaryButton from "../UI/Button/PrimaryButton";
import Spinner from "../UI/Spinner/Spinner";

import IAuthForm from "../../models/form/IAuthForm";

import {TypedDispatch} from "../../types";

import {redirectToDashboard, redirectToReturnUrl} from "../../utils/redirects";

import styles from "./AuthPage.module.scss";

interface IAuthPageProps {
    loginLoading: boolean,
}

const AuthPage: FC<IAuthPageProps> = ({loginLoading}) => {
    const dispatch = useDispatch<TypedDispatch>();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const {handleSubmit, formState, control} = useForm<IAuthForm>({mode: "onChange"});
    const {t} = useTranslation(["auth", "warnings"]);

    const {isValid, isDirty} = formState;

    const onSubmit: SubmitHandler<IAuthForm> = async(data) => {
        await dispatch(loginUser(data));
        redirectToReturnUrl(navigate, searchParams) || redirectToDashboard(navigate);
    };

    return (
        <Layout type="authorization">
            <div className={styles["login"]}>
                <div className={styles["login__inner"]}>
                    <div className={styles["login__title"]}>
                        {t("title")}
                    </div>

                    <div className={styles["login__form-container"]}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <TextFormInput
                                name={"Login"}
                                placeholder=" "
                                control={control}
                                label={t("login")}
                                rules={{
                                    required: {
                                        value: true,
                                        message: t("warnings:field_req"),
                                    },
                                }}
                            />

                            <PasswordFormInput
                                name={"Password"}
                                placeholder=" "
                                control={control}
                                label={t("password")}
                                rules={{
                                    required: {
                                        value: true,
                                        message: t("warnings:field_req"),
                                    },
                                }}
                            />

                            <PrimaryButton
                                type={"submit"}
                                className={"btn--lg"}
                                disabled={(!isDirty || !isValid)}
                            >
                                {loginLoading
                                    ? <Spinner />
                                    : t("sign_in")
                                }
                            </PrimaryButton>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

const mapStateToProps = createStructuredSelector({
    loginLoading: authLoadingSelector,
});

export default connect(mapStateToProps)(React.memo(AuthPage));