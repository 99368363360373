/* eslint-disable max-len */
import React from "react";

import {SvgProps} from "../../models/SvgProps";

// import icon from "images/icons/arrow-right.svg";

const ArrowRightSmallIcon: React.FC<SvgProps> = (
    {
        width = 16,
        height = 16,
    }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.05719 8L6.19526 5.13807C5.93491 4.87772 5.93491 4.45561 6.19526 4.19526C6.45561 3.93491 6.87772 3.93491 7.13807 4.19526L10.4714 7.5286C10.7318 7.78895 10.7318 8.21105 10.4714 8.4714L7.13807 11.8047C6.87772 12.0651 6.45561 12.0651 6.19526 11.8047C5.93491 11.5444 5.93491 11.1223 6.19526 10.8619L9.05719 8Z" fill="currentColor"/>
        </svg>
    );
};

export default ArrowRightSmallIcon;