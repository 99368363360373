import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";

import {RootState} from "store";

import createHttpRequest from "../utils/http";
import {ApiUrls} from "../constants/urls";
import IncidentEventsResponseServiceModel from "../models/IncidentEventsResponseServiceModel";

interface IInitialState {
  responseServices: IncidentEventsResponseServiceModel[],
  isLoading: boolean,
}

const initialState: IInitialState = {
    responseServices: [],
    isLoading: false,
};

export const fetchResponseServices =
  createAsyncThunk<IncidentEventsResponseServiceModel[]>("responseService/collection", async() => {
      const response = await createHttpRequest({
          method: "GET",
          path: ApiUrls.RESPONSE_SERVICES,
          errorMessage: "messages:fetch_response_services_error",
      });

      return response.data;
  });

const responseService = createSlice({
    reducers: {},
    name: "responseService",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchResponseServices.pending, (state) => {
            state.responseServices = [];
            state.isLoading = true;
        });
        builder.addCase(fetchResponseServices.fulfilled, (state, {payload}) => {
            state.responseServices = payload;
            state.isLoading = false;
        });
        builder.addCase(fetchResponseServices.rejected, (state) => {
            state.responseServices = [];
            state.isLoading = false;
        });
    },
});


export default responseService.reducer;

//Селекторы
const slice = ({responseServiceReducer}: RootState) => responseServiceReducer;

export const responseServicesSelector = createSelector(
    slice,
    ({responseServices}) => responseServices
);

export const isResponseServicesLoadingSelector = createSelector(
    slice,
    ({isLoading}) => isLoading
);

