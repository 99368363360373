// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AuthPage_login__yfyxB {\n  display: flex;\n  height: 100%;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n  padding-left: 15px;\n  padding-right: 15px;\n}\n.AuthPage_login__inner__CMYC- {\n  width: 100%;\n  max-width: 560px;\n}\n.AuthPage_login__title__hMcMf {\n  font-size: 32px;\n  font-weight: 700;\n  text-align: center;\n  color: var(--text-color);\n  margin-bottom: 32px;\n}\n.AuthPage_login__form-container__YeoO- {\n  background-color: var(--bg-secondary);\n  padding: 40px 30px 30px;\n  border-radius: 10px;\n  box-shadow: 3px -5px 40px 0px #CDCDD41A;\n}", "",{"version":3,"sources":["webpack://./src/components/AuthPage/AuthPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,WAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,mBAAA;AACF;AACE;EACE,WAAA;EACA,gBAAA;AACJ;AAEE;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,wBAAA;EACA,mBAAA;AAAJ;AAGE;EACE,qCAAA;EACA,uBAAA;EACA,mBAAA;EACA,uCAAA;AADJ","sourcesContent":[".login {\r\n  display: flex;\r\n  height: 100%;\r\n  width: 100%;\r\n  align-items: center;\r\n  justify-content: center;\r\n  padding-left: 15px;\r\n  padding-right: 15px;\r\n\r\n  &__inner {\r\n    width: 100%;\r\n    max-width: 560px;\r\n  }\r\n\r\n  &__title {\r\n    font-size: 32px;\r\n    font-weight: 700;\r\n    text-align: center;\r\n    color: var(--text-color);\r\n    margin-bottom: 32px;\r\n  }\r\n\r\n  &__form-container {\r\n    background-color: var(--bg-secondary);\r\n    padding: 40px 30px 30px;\r\n    border-radius: 10px;\r\n    box-shadow: 3px -5px 40px 0px #CDCDD41A;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login": "AuthPage_login__yfyxB",
	"login__inner": "AuthPage_login__inner__CMYC-",
	"login__title": "AuthPage_login__title__hMcMf",
	"login__form-container": "AuthPage_login__form-container__YeoO-"
};
export default ___CSS_LOADER_EXPORT___;
