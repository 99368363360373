import axios, {AxiosRequestConfig, AxiosRequestHeaders, ResponseType} from "axios";
import {setupCache} from "axios-cache-interceptor";
import {apiErrorHandler} from "utils/errorHandler";

import {isBrowser} from "utils/evironment";
import {ApiUrls, AppUrls} from "../constants/urls";
import {DEFAULT_LOCALE} from "../constants/common";

import Queue from "./queue";
import {clearTokens, getAccessToken, getRefreshToken, refreshAccessToken} from "./auth";

export type HttpRequestType = {
    method?: "GET" | "POST" | "PUT" | "DELETE",
    path: string,
    baseURL?: string,
    data?: object | string,
    headers?: AxiosRequestHeaders | { "Content-Type": string; },
    errorMessage?: string,
    locale?: string,
    isAnonymous?: boolean,
    silent?: boolean,
    responseType?: ResponseType,
    signal?: AbortSignal;
};

// eslint-disable-next-line camelcase
interface IRequestOptions extends AxiosRequestConfig{
    errorMessage?: string | null,
    silent?: boolean,
    signal?: AbortSignal,
}

let isTokenRefreshing = false;
const failedRequestsQueue = new Queue();

const processFailedRequests = (error: Error | null, token: string|null = null) => {
    failedRequestsQueue.forEach((prom: any) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedRequestsQueue.empty();
};

const createHttpRequest = async(
    {
        headers,
        method,
        path,
        baseURL,
        data,
        errorMessage,
        responseType,
        locale = DEFAULT_LOCALE,
        isAnonymous = false,
        silent = false,
        signal,
    }: HttpRequestType) => {

    const options: IRequestOptions = {headers: {"X-Locale": locale, ...headers}};

    if (!isAnonymous && isBrowser()) {
        let token = getAccessToken();
        if (!token) {
            token = await getNewAccessToken();
        }
        if (token) {
            options.headers  = {
                ...http.defaults.headers,
                ...options.headers,
                ...{Authorization: "Bearer " + token},
            } as unknown as AxiosRequestHeaders;
        }
    }

    if (baseURL) {
        options.baseURL = baseURL;
    } else if (!baseURL && isBrowser()) {
        options.baseURL = http.defaults.baseURL;
    }

    if (responseType) {
        options.responseType = responseType;
    }

    if (signal) {
        options.signal = signal;
    }

    options.errorMessage = errorMessage || null;
    options.silent = silent;
    options.withCredentials = !isAnonymous;
    return http({
        method,
        url: path,
        data,
        ...options,
    });
};

function redirectToLogin() {
    if (location.pathname !== `${process.env.PUBLIC_URL}${AppUrls.AUTH}`){
        return window.location.href = `${process.env.PUBLIC_URL}${AppUrls.AUTH}?return=${location.pathname}`;
    }

    return false;
}

function refreshTokenAndRetry(originalRequest: any) {
    const refreshToken = getRefreshToken();

    if (!refreshToken) {
        return redirectToLogin();
    }

    if (isTokenRefreshing) {
        return new Promise(function(resolve, reject) {
            failedRequestsQueue.set({resolve, reject});
        }).then(token => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return http.request(originalRequest);
        }).catch(err => {
            return Promise.reject(err);
        });
    }

    originalRequest.retry = true;

    // eslint-disable-next-line no-undef
    return new Promise((resolve, reject) => {
        getNewAccessToken()
            .then(token => {
                originalRequest.headers["Authorization"] = "Bearer " + token;
                resolve(http(originalRequest));
            })
            .catch(error => {
                reject(error);
            });
    });
}

async function getNewAccessToken(): Promise<string|null> {
    const refreshToken = getRefreshToken();
    if (!refreshToken) {
        redirectToLogin();
        throw Error("Access and Refresh tokens expired");
    }

    if (isTokenRefreshing) {
        await new Promise(r => setTimeout(r, 5000));
        return getNewAccessToken();
    }

    isTokenRefreshing = true;
    try {
        const token = await refreshAccessToken();
        processFailedRequests(null, token);
        return token;
    }catch(error){
        processFailedRequests(error as Error, null);
        throw error;
    }finally{
        isTokenRefreshing = false;
    }

    return null;
}

export const http = setupCache(axios.create({
    baseURL: `${process.env.REACT_APP_BASE_HOST}${process.env.REACT_APP_BASE_API_URL}`,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
}));

// Сохраняем первоначальные настройки кэширования
const savedCacheSettings = http.defaults.cache;
// Отключаем кэширование (включать будем отдельно только там, где нужно)
// @ts-ignore
http.defaults.cache = false;
export const cacheSettings = savedCacheSettings;

http.interceptors.response.use((response) => {
    return response;
}, error => {
    const originalRequest = error.config;
    if (error.response?.status === 403) {
        return window.location.href = AppUrls.FORBIDDEN;
    }

    if (
        error.response.status === 401
        && !originalRequest.retry
        && !originalRequest.isAnonymous
    ) {
        if (originalRequest.url === ApiUrls.REFRESH_TOKEN) {
            //Ошибка возникла при обновлении access-токена
            processFailedRequests(error as Error, null);
            //Предположительно refresh-токен некорректный, поэтому очищаем все токены и переадресовываем на логин
            clearTokens();
            return redirectToLogin();
        }
        return refreshTokenAndRetry(originalRequest);
    }

    return apiErrorHandler(error, originalRequest.errorMessage, originalRequest.silent);
});

export default createHttpRequest;